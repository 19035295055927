<template>
  <v-container>
    <v-row justify-md="space-between" justify="center" v-if="page==0">
      <v-col xl="5" lg="5" md="6" sm="8">

        <div class="title">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">New Plan</h5>
          <h2 class="text-h2 font-weight-regular">
            <v-skeleton-loader class="skeleton_loader" height="60px" width="400px" type="image" v-if="!client"></v-skeleton-loader>
            {{client.first_name}} {{client.last_name}}
          </h2>
        </div>

        <PlanNewForm
          :plan="plan"
          @cancel="$router.push({name:'ClientProfilePage',params:{clientId:client.client_id}})"
          @next="page++"
        />

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex">

        <div id="bg-block"></div>
        <div id="bg-image"><InlineSvg src="/images/svgs/undraw_getting_coffee.svg" /></div>
        
      </v-col>
    </v-row>
    <v-row justify-md="space-between" justify="center" v-if="page==1">
      <v-col sm="12">

        <div class="title">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">New Plan</h5>
          <h2 class="text-h2 font-weight-regular">
            <v-skeleton-loader class="skeleton_loader" height="60px" width="400px" type="image" v-if="!client"></v-skeleton-loader>
            {{client.first_name}} {{client.last_name}}
          </h2>
        </div>

        <BarrierColumns
          :columnOne="allBarriers ? allBarriers.filter(function(e){return plan.barriers.indexOf(e)<0;}) : allBarriers"
          :columnTwo="plan.barriers"
          @update="updateBarriers"
          
          @back="page--"
          @save="saveNewPlan"
          :loading="loading"
        />

        <!-- <v-container>
          <v-row>
            <v-col cols="12">
              <FormActionButtons
                :buttons="[
                  'back',
                  'save'
                ]" 
                @back="page--"
                @save="saveNewPlan"
              />
            </v-col>
          </v-row>
        </v-container> -->

      </v-col>
    </v-row>

    <DeleteModal
      v-if="showDialogue"
      title="Cancel New Plan?"
      :text="`Are you sure you wish to cancel the new plan? All unsaved data will be lost.`"
      type="leave"
      @confirm="confirmLeavePage"
      @cancel="cancelLeavePage" 
    />

  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import PlanNewForm from '@/components/Plans/PlanNewForm.vue'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
import DeleteModal from '@/components/elements/DeleteModal.vue'
import BarrierColumns from '@/components/Plans/elements/BarrierColumns.vue'
var moment = require('moment')

export default {
  name: 'PlanProfileNewPage',
  components: {
    PlanNewForm,
    FormActionButtons,
    DeleteModal,
    BarrierColumns
  },
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      page: 0,
      client: false,
      plan: {
        date: '',
        current_employment: '',
        employment_type: '',
        apprentice: '',
        accessing_supports: '',
        relocate: '',
        transportation: '',
        barriers: []
      },
      allBarriers: false,
      showDialogue: false,
      checkBeforeLeaving: true,
      loading: false,
    }
  },

  async created () {
    this.plan.date = this.moment().format('YYYY-MM-DD')
    this.client = await Api.Clients.getSingle(this.clientId)
    this.allBarriers = await Api.Barriers.getAll()
  },

  methods: {
    cancelLeavePage () {
      this.to = null
      this.showDialogue = false
    },
    confirmLeavePage () {
      this.$router.push(this.to)
    },

    updateBarriers (arrayOne, arrayTwo) {
      this.plan.barriers = arrayTwo
    },
    async saveNewPlan () {

      this.loading = true
      this.plan.client_id = this.clientId

      const response_plan = await Api.Plans.create(this.plan)
      if (response_plan) {
        const response_barriers = await Api.Barriers.updateToPlan(response_plan.id, this.plan.barriers)
        if (response_barriers) {
          this.loading = false
          this.checkBeforeLeaving = false
          this.$toast.success('The plan was successfully created.')
          // send to newly created plan
          this.$router.push({name:'PlanProfilePage',params:{clientId:this.clientId,planId:response_plan.id}})
          return
        }
      } else {
        this.loading = false
        this.$toast.error('Something went wrong. Please try again.')
      }
    }
  },

  beforeRouteLeave (to, from, next) {
    if (this.to || to.name==='LoginPage' || !this.checkBeforeLeaving){
      next()
    }
    this.to = to
    this.showDialogue = true
  },

  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 2.188rem;
}
</style>
