<template>
  <div>
      
    <div class="skeleton_loader" v-if="!allClients || !rules || !interactions">
      <v-card-text class="text-h6 font-weight-light">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader height="56px" width="100%" type="image" style="margin-bottom:22px;"></v-skeleton-loader>
              <v-skeleton-loader height="56px" width="100%" type="image" style="margin-bottom:22px;"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-for="i in 6" :key="i">
            <v-col cols="6"><v-skeleton-loader height="36px" width="150px" type="image"></v-skeleton-loader></v-col>
            <v-col cols="6"><v-skeleton-loader height="36px" width="161px" type="image" style="float:right"></v-skeleton-loader></v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-skeleton-loader height="36px" width="80px" type="image" style="margin-right:8px;"></v-skeleton-loader>
        <v-skeleton-loader height="36px" width="160px" type="image"></v-skeleton-loader>
      </v-card-actions>
    </div>

    <div v-if="allClients && rules && interactions">
      <v-card-text class="text-h6 font-weight-light">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-form 
                ref="form"
                @submit.prevent="savePlan" 
                novalidate
              >
                <v-autocomplete
                  v-model="client_id"
                  :items="allClients"
                  item-value="client_id"
                  item-text="title"
                  outlined
                  label="Client"
                  v-if="allClients && client===-1"
                  :rules="[
                    rules.required
                  ]"
                ></v-autocomplete>

                <v-text-field
                  type="date"
                  v-model="date"
                  label="Date"
                  outlined
                  :rules="[
                    rules.required,
                    rules.noFutureDate
                  ]"
                />
              </v-form>
            </v-col>
          </v-row>
          <v-row
            v-for="item in interactions"
            :key="item.interaction_id"
          > 
            <v-col cols="6">{{item.name}}</v-col>
            <v-col cols="6" style="text-align:right">
              <div class="button-group">
                <v-text-field
                  type="text"
                  v-model="item.count"
                  solo
                  hide-details
                  hide-spin-buttons
                  flat
                  aria-label="Count"
                  @update="cleanInput(item)"
                >
                  <template v-slot:prepend>
                    <v-btn 
                      class="left-button"
                      color="primary" 
                      elevation="0" 
                      min-width="40" 
                      @click="changeValue(item, -1)"
                    ><v-icon small>mdi-minus</v-icon></v-btn>
                  </template>
                  <template v-slot:append-outer>
                    <v-btn 
                      class="right-button"
                      color="primary" 
                      elevation="0" 
                      min-width="40" 
                      @click="changeValue(item, 1)"
                    ><v-icon small>mdi-plus</v-icon></v-btn>
                  </template>
                </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="cancel"
          v-if="cancelButton"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-2"
          text
          :loading="loading"
          :disabled="loading"
          @click="confirm"
        >
          Add Interaction
        </v-btn>
      </v-card-actions>
    </div>

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'
var moment = require('moment')

export default {
  props: {
    client: {
      type: Number,
      required: false,
      default: -1
    },
    cancelButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      moment: moment,
      loading: false,
      rules: false,
      client_id: '',
      date: '',
      allClients: false,
      interactions: false,
    }
  },
  async created () {
    this.date = this.moment().format('YYYY-MM-DD')
    this.rules = await validationRules

    this.allClients = await Api.Clients.getAllMinimal()
    this.interactions = await Api.Interactions.getAll()
    this.interactions = this.interactions.map((row) => {
      return {
        interaction_id: row.interaction_id,
        name: row.name,
        count: 0
      }
    })
  },

  methods: {
    cancel () {
      this.$emit('close')
    },
    async confirm () {
      if( !this.$refs.form.validate() ) return
      this.loading = true

      if (this.client !== -1) {
        this.client_id = this.client
      }

      let cleaned = []
      this.interactions.forEach((item, i) => {
        if (item.count > 0) {
          cleaned.push({
            interaction_id: item.interaction_id,
            date: this.date,
            count: item.count
          })
        }
      })

      const response = await Api.Interactions.updateToClient(this.client_id, cleaned)
      if (response) {
        this.loading = false
        this.$toast.success('The client interaction was successfully recorded.')
        this.$emit('close')
        return
      }
      this.$toast.error('Something went wrong. Please try again.')
    },

    changeValue (item, amount) {
      this.cleanInput(item)
      item.count = parseInt(item.count) + amount

      // set limits
      if (item.count<0) item.count = 0
      if (item.count>99) item.count = 99
    },
    cleanInput (item) {
      // if (this.date==='') this.date = this.moment().format('YYYY-MM-DD')
      this.interactions.forEach((item,i) => {
        if ((/\D/).test(item.count)) {
          item.count = item.count.replace(/\D/g,'')
        }
        if (item.count<0) item.count = 0
        if (item.count>99) item.count = 99
        if (item.count==='') item.count = 0
      })
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.v-card__title span {
  margin-left: 5px;
}
</style>
<style lang="scss">
.button-group {
  display: inline-block;

  .v-input {

    .v-input__control {
      min-height: 36px;
      max-width: 65px;
      border-radius: 0;
      .v-input__slot {
        background-color: #ededed;
        input {
          text-align: center;
        }
      }
    }

    .v-input__prepend-outer {
      margin: 0!important;
      .left-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    
    .v-input__append-outer {
      margin: 0!important;
      .right-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
</style>
