<template>
  <div>
    <div class="skeleton_loader" v-if="!clients">
      <v-skeleton-loader 
        type="table-thead, table-tbody, table-tfoot">
      </v-skeleton-loader>
    </div>

    <div v-if="clients">
      <v-data-table
        :headers="headers"
        :items="clients"
        sort-by="updated_at"
        sort-desc
      >
        <template v-slot:[`item._missing`]="{ item }">
          <v-tooltip top v-if="item._missing">
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
                class="help-icon"
                color="red"
                v-bind="attrs"
                v-on="on"
                style="font-size:21px;"
              >mdi-alert-octagon-outline</v-icon>
            </template>
            <span>This client is missing required information.</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.full_name`]="{ item }">
          <router-link :to="{name:'ClientProfilePage',params:{clientId:item.client_id}}">{{item.full_name}}</router-link>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <a :href="`mailto:${item.email}`" v-if="item.email"><v-icon color="primary" small>mdi-email-outline</v-icon> {{item.email}}</a>
          <span v-if="!item.email">-</span>
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          {{item.phone || '-'}}
        </template>
        <template v-slot:[`item.recent_plan_status`]="{ item }">
          <span class="">{{item.recent_plan_status || '-'}}</span>
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          {{ moment(item.updated_at).format('YYYY-MM-DD hh:mma') }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
var moment = require('moment')

export default {
  name: 'ClientsTable',
  props: {
    clients: {
      required: true
    }
  },
  data() {
    return {
      moment: moment,
      ownUser: null
    }
  },
  async created () {
    this.ownUser = this.$store.getters.getUser
  },
  methods: {
  },

  computed: {
    headers () {
      switch (this.ownUser.roles[0].role_id) {
        case 1: // Admin
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
            case 'sm':
              return [
                { text: '', value: '_missing', sortable: false, width: '53' },
                { text: 'Name', value: 'full_name' },
                { text: 'Centre', value: 'centre_name' },
                { text: 'Case Status', value: 'recent_plan_status' },
              ]
            case 'md': 
              return [
                { text: '', value: '_missing', sortable: false, width: '53' },
                { text: 'Name', value: 'full_name' },
                { text: 'Centre', value: 'centre_name' },
                { text: 'Case Status', value: 'recent_plan_status' },
                { text: 'Last Updated', value: 'updated_at' },
              ]
            case 'lg':
            case 'xl': 
              return [
                { text: '', value: '_missing', sortable: false, width: '53' },
                { text: 'Name', value: 'full_name' },
                { text: 'Centre', value: 'centre_name' },
                { text: 'Case Status', value: 'recent_plan_status' },
                { text: 'Last Updated', value: 'updated_at' },
              ]
          }
        case 2: //Coordinator
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
            case 'sm':
              return [
                { text: '', value: '_missing', sortable: false, width: '53' },
                { text: 'Name', value: 'full_name' },
                { text: 'Case Status', value: 'recent_plan_status' },
                { text: 'Last Updated', value: 'updated_at' },
              ]
            case 'md': 
            case 'lg':
              return [
                { text: '', value: '_missing', sortable: false, width: '53' },
                { text: 'Name', value: 'full_name' },
                { text: 'Phone', value: 'phone' },
                { text: 'Case Status', value: 'recent_plan_status' },
                { text: 'Last Updated', value: 'updated_at' },
              ]
            case 'xl': 
              return [
                { text: '', value: '_missing', sortable: false, width: '53' },
                { text: 'Name', value: 'full_name' },
                { text: 'Email', value: 'email' },
                { text: 'Phone', value: 'phone' },
                { text: 'Case Status', value: 'recent_plan_status' },
                { text: 'Last Updated', value: 'updated_at' },
              ]
          }
      }
    }
  }
}
</script>

<style lang="scss">
</style>
