<template>
  <v-dialog
    v-model="show"
    width="500"
    @click:outside="modalCancel"
    v-if="rules"
  >
    <v-card>
      <v-card-title class="text-h5 font-weight-medium">
        <v-icon color="blue darken-2">mdi-information-outline</v-icon>
        Information Required
      </v-card-title>

      <v-form ref="form1" novalidate v-if="step===1">
        <v-card-text class="text-h6 font-weight-light">
          <v-text-field
            label="Name *"
            type="text"
            outlined
            v-model="otherString"
            :rules="[
              rules.required
            ]"
            v-if="trainingItem.name==='Other'"
          />
          <v-text-field
            label="Date *"
            type="date"
            prepend-inner-icon="mdi-calendar"
            outlined
            v-model="date"
            :rules="[
              rules.required,
              rules.noFutureDate
            ]"
          />
        </v-card-text>
      </v-form>

      <v-form ref="form2" novalidate v-if="step===2">
        <v-card-text class="text-h6 font-weight-light">
          <p>You can add a NOC to this training. You can enter it here, or search for it below.</p>
          <v-text-field
            label="NOC"
            type="text"
            outlined
            v-model="noc"
            :rules="[
              rules.isNOC
            ]"
          />
          <v-text-field
            class="hidden"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="text-h6 font-weight-light">
          <p class="subtitle-1 pb-0 mb-2 primary--text text-uppercase">Search</p>
          <v-form novalidate @submit.prevent="searchNocApi" style="display:flex;">
            <v-text-field
              label="Job title"
              type="text"
              outlined
              hint="Ex. chef"
              v-model="noc_search"
              style=""
            /> <!-- /*border-top-right-radius:0;border-bottom-right-radius:0;*/ -->
            <v-btn 
              color="primary" 
              x-large 
              @click="searchNocApi"
              elevation="0"
              style="height:56px;min-width:56px;margin-left:10px;"
            ><v-icon>mdi-magnify</v-icon></v-btn> <!--*border-top-left-radius:0;border-bottom-left-radius:0;*/-->
          </v-form>
          <div class="noc_results" v-if="noc_search_results">
            <p class="overline pb-0 mb-0 text-center" v-if="noc_search_results==='load'">Loading...</p>
            <div v-if="noc_search_results!=='load'">
              <p class="overline pb-0 mb-0">Results</p>
              <div
                :class="'noc_results_item pa-3 mb-2 ' + (item.noc===noc?'active':'')"
                v-for="item in noc_search_results"
                :key="item.noc"
                @click="noc=item.noc"
              >
                <span class="label">{{item.label}}</span>
                <span class="noc">NOC: {{item.noc}}</span>
              </div>
            </div>
          </div>

        </v-card-text>
      </v-form>

      <v-divider></v-divider>

      <v-card-actions v-if="!noc_show">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="modalCancel"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="modalConfirm"
          v-if="!noc_show"
        >
          Add Training
        </v-btn>
      </v-card-actions>
      <v-card-actions v-if="noc_show">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="modalCancel"
          v-if="step===1"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="modalNextStep"
          v-if="step===1"
        >
          Next
        </v-btn>
        <v-btn
          color="secondary"
          text
          @click="step--"
          v-if="step===2"
        >
          Back
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="false && noc.length!==4?true:false"
          @click="modalConfirm"
          v-if="step===2"
        >
          Add Training
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'
var moment = require('moment')

export default {
  components: {
  },
  props: {
    trainingItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      rules: false,
      show: true,
      newIndex: false,
      date: false,
      noc: '',
      noc_show: true,
      noc_search: '',
      noc_search_results: '',
      step: 1,
      otherString: '',
    }
  },
  async created () {
    this.rules = await validationRules
    this.date = this.moment().format('YYYY-MM-DD')
    // this.noc_show = this.trainingItem.noc
    this.noc_show = true
  },
  methods: {
    async modalCancel () {
      this.show = false
      this.$emit('close')
    },
    modalNextStep () {
      if( !this.$refs.form1.validate() ) return
      this.$refs.form1.resetValidation()
      this.step++
    },
    modalConfirm () {
      // check values
      if (!this.noc_show) {
        if( !this.$refs.form1.validate() ) return
      } else {
      if( !this.$refs.form2.validate() ) return
      }

      const results = {
        ...this.trainingItem,
        date: this.date,
        noc: this.noc,
        other_name: this.otherString,
      }
      this.$emit('update', results)
    },

    async searchNocApi () {
      this.noc_search_results = 'load'
      const results = await Api.Nocs.searchNoc(this.noc_search)
      this.noc_search_results = results
    },
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.noc_results_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 0.25rem;
  border: 1px solid #9E9E9E;
  color: #424242;
  font-size: 1rem;

  .noc {
    min-width: 120px;
    text-align: right;
  }

  &:hover, &.active {
    background-color: #1976D2;
    color: white;
    cursor: pointer;
  }
}
</style>
