import connection from '../connection'
// import store from '../../store'

// get all plans for a client
// get one plan
// create a new plan
// update a plan
// delete a plan

async function returnCleanPlans (plans) {
  if (Object.prototype.toString.call(plans) === '[object Object]') {
    plans = [plans]
  }

  const cleaned = plans.map((row) => {
    return {
      ...row,
      _missing: (
        // system with throw an alert
        (row.current_employment === null || row.current_employment === '') ||
        (row.current_employment === ('Employed' || 'Self-employed') ? (row.employment_type === null || row.employment_type === '') : '') ||
        (row.apprentice === null || row.apprentice === '')
      )
    }
  })
  return cleaned
}

async function sendCleanPlans (plan) {
  delete plan._missing
  plan = Object.keys(plan).reduce((acc, key) => { acc[key] = plan[key] === '' ? null : plan[key]; return acc }, {})
  return plan
}

export default {

  async getAllForClient (clientId) {
    try {
      const response = await connection.get(`/clients/${clientId}/plans`)
      if (!response) return false
      const sorted = response.data.sort((a, b) => (a.date < b.date) ? 1 : ((a.date > b.date) ? -1 : 0))
      return await returnCleanPlans(sorted)
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getSingle (planId) {
    try {
      const response = await connection.get(`/plans/${planId}`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (planId, planData) {
    try {
      planData = await sendCleanPlans(planData)
      const response = await connection.put(`/plans/${planId}`, planData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (planData) {
    try {
      planData = await sendCleanPlans(planData)
      const response = await connection.post('/plans', planData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (planId) {
    try {
      const response = await connection.delete(`/plans/${planId}`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

