var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[(_vm.rules)?_c('v-col',{attrs:{"sm":"12","md":"6","lg":"5"}},[_c('v-form',{ref:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.saveSupport.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"type":"text","label":"Name *","outlined":"","rules":[
            _vm.rules.required, 
            _vm.rules.maxLength(_vm.item.name,64)
          ]},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_c('v-select',{attrs:{"items":['Cultural', 'Employment', 'Life Skills', 'Misc.'],"label":"Category *","outlined":"","rules":[
            _vm.rules.required
          ]},model:{value:(_vm.item.category),callback:function ($$v) {_vm.$set(_vm.item, "category", $$v)},expression:"item.category"}}),_c('v-select',{attrs:{"items":_vm.mappings,"item-text":"type","item-value":"reporting_type_id","label":"Mapping *","outlined":"","rules":[
            _vm.rules.required
          ]},model:{value:(_vm.item.reporting_type),callback:function ($$v) {_vm.$set(_vm.item, "reporting_type", $$v)},expression:"item.reporting_type"}}),_c('v-textarea',{attrs:{"type":"text","label":"Description","outlined":"","rows":"5","counter":"350","rules":[
            _vm.rules.maxLength(_vm.item.description,350)
          ]},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}}),_c('FormActionButtons',{attrs:{"buttons":[
            'cancel',
            'save'
          ],"loading":_vm.loading},on:{"cancel":function($event){return _vm.goBack()},"save":_vm.saveSupport}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }