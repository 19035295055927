<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="8">
        <span v-html="page.data"></span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },

  async created () {
  },

  methods: {
  }
}
</script>

<style lang="scss">
</style>
