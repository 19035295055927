<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="6">
          <v-form 
            novalidate 
            @submit.prevent="searchHelp" 
            style="display:flex;max-width:100%;"
          >
            <v-text-field
              label="Search Help"
              type="text"
              outlined
              hide-details
              v-model="live_search"
              style=""
            />
            <v-btn 
              color="primary" 
              x-large 
              @click="searchHelp"
              elevation="0"
              style="height:56px;min-width:56px;max-width:56px;margin-left:10px;"
            ><v-icon>mdi-magnify</v-icon></v-btn>
          </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="allPages.length===0">
        <p class="placeholder text-center text-body-1 mb-0 blue-grey--text text--darken-2">
          There are no search results.
        </p>
      </v-col>
      <v-col 
        cols="6"
        v-for="(page,i) in allPages"
        :key="i"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            class="py-3 px-6 mb-0"
            elevation="0"
            :to="{name:'HelpPage.Page',params:{helpCategory:page.category_link,helpPage:page.link}}"
            :color="'blue ' + (hover?'lighten-4':'lighten-5')"
            style="transition:all 0.3s ease;"
          >
            <span class="text-subtitle-1 pa-0">{{page.title}}</span>
            <v-container class="pa-0">
              <v-row>
                <v-col cols="6">
                  <span class="text-caption grey--text text--darken-1">Last Updated <b>{{moment(page.updated).format('MMMM D, YYYY')}}</b></span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
var moment = require('moment')

export default {
  components: {
  },
  props: {
    search: {
      type: String,
      required: true
    },
    helpFiles: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      live_search: '',
      ownUser: false
    }
  },

  created () {
    this.ownUser = this.$store.getters.getUser
  },

  mounted () {
    this.search = this.$route.query.q || ''
    this.live_search = this.search
  },

  methods: {
    searchHelp () {
      this.$router.push({name:'HelpPage.Search',query:{q:this.live_search}})
    }
  },

  computed: {
    allPages () {
      // CONSOLE
      // console.time('searching_pages')
      let searched = []
      this.helpFiles.forEach((category,i) => {
        if (!category.access.includes(this.ownUser.roles[0].title)) return
        category.pages.forEach((page,j) => {
          let tmp = {
            category: category,
            category_link: category.link,
            title: page.title,
            link: page.link,
            updated: page.updated,
            data: page.data
          }
          if (tmp.title.toLowerCase().includes(this.search.toLowerCase()))
            searched.push(tmp)
          else if (tmp.data.toLowerCase().includes(this.search.toLowerCase()))
            searched.push(tmp)
        })
      })
      // console.timeEnd('searching_pages')
      return searched
    }
  },

  watch: {
    $route (to, from) {
      this.search = this.$route.query.q || ''
      this.live_search = this.search
    }
  }

}
</script>

<style lang="scss">
</style>
