<template>
  <v-container>
    <v-row>
      <v-col sm="12" v-if="helpSearch || helpCategory!=='' || helpPage!==''">

        <div class="title" v-if="helpSearch">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">
            <router-link :to="{name:'HelpPage'}">Help</router-link>
            <span> / Search</span>
          </h5>
          <h2 class="text-h2 font-weight-regular">
            Search: {{search}}
          </h2>
        </div>

        <div class="title" v-if="!helpSearch && helpCategory!=='' && helpPage===''">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">
            <router-link :to="{name:'HelpPage'}">Help</router-link>
            <span> / {{helpFiles.find(o => o.link === helpCategory).title}}</span>
          </h5>
          <h2 class="text-h2 font-weight-regular">
            {{helpFiles.find(o => o.link === helpCategory).title}}
          </h2>
        </div>

        <div class="title" v-if="!helpSearch && helpCategory!=='' && helpPage!==''">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">
            <router-link :to="{name:'HelpPage'}">Help</router-link>
            <span> / </span>
            <router-link :to="{name:'HelpPage.Category',params:{helpCategory}}">{{helpFiles.find(o => o.link === helpCategory).title}}</router-link>
            <span> / {{helpFiles.find(o => o.link === helpCategory).pages.find(o => o.link === helpPage).title}}</span>
          </h5>
          <h2 class="text-h2 font-weight-regular">
            {{helpFiles.find(o => o.link === helpCategory).pages.find(o => o.link === helpPage).title}}
          </h2>
          <span class="text-subtitle-1 font-weight-regular">
            Last Updated <b>{{moment(helpFiles.find(o => o.link === helpCategory).pages.find(o => o.link === helpPage).updated).format('MMMM D, YYYY')}}</b>
          </span>
        </div>

      </v-col>
      <v-col sm="12">

        <!-- SEARCH PAGE -->
        <div v-if="helpSearch">
          <HelpSearchPage
            :search="search"
            :helpFiles="helpFiles"
          />
        </div>

        <!-- MAIN PAGE -->
        <div v-if="!helpSearch && helpCategory==='' && helpPage===''">
          <HelpMainPage
            :helpFiles="helpFiles"
          />
        </div>

        <!-- CATEGORY PAGE -->
        <div v-if="!helpSearch && helpCategory!=='' && helpPage===''">
          <HelpCategoryPage
            :category="helpFiles.find(o => o.link === helpCategory)"
          />
        </div>

        <!-- HELP PAGE -->
        <div v-if="!helpSearch && helpCategory!=='' && helpPage!==''">
          <HelpIndividualPage
            :page="helpFiles.find(o => o.link === helpCategory).pages.find(o => o.link === helpPage)"
          />
        </div>

      </v-col>
      <v-col sm="12 mt-10">
        <HelpTicketFooter
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HelpMainPage from '@/components/Help/HelpMainPage'
import HelpSearchPage from '@/components/Help/HelpSearchPage'
import HelpCategoryPage from '@/components/Help/HelpCategoryPage'
import HelpIndividualPage from '@/components/Help/HelpIndividualPage'
import HelpTicketFooter from '@/components/Help/HelpTicketFooter'
var moment = require('moment')

export default {
  components: {
    HelpMainPage,
    HelpSearchPage,
    HelpCategoryPage,
    HelpIndividualPage,
    HelpTicketFooter
  },
  props: {
    helpCategory: {
      type: String,
      required: false,
      default: ''
    },
    helpPage: {
      type: String,
      required: false,
      default: ''
    },
    helpSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      moment: moment,
      helpFiles: [
        {
          title: 'Getting Started',
          link: 'getting-started',
          image: 'undraw_monitor',
          access: ['Admin', 'Coordinator'],
          pages: [
            {title: 'Logging In',         link: 'logging-in',         updated: '2022-01-20', data: ''},
            {title: 'Forgot Password',    link: 'forgot-password',    updated: '2022-01-20', data: ''},
            {title: 'Account Management', link: 'account-management', updated: '2022-01-20', data: ''},
            {title: 'Documents',          link: 'documents',          updated: '2022-03-10', data: ''},
            {title: 'Logging Out',        link: 'logging-out',        updated: '2022-01-20', data: ''},
          ]
        },
        {
          title: 'Client Management',
          link: 'client-management',
          image: 'undraw_interview',
          access: ['Admin', 'Coordinator'],
          pages: [
            {title: 'Finding a client',           link: 'finding-a-client',           updated: '2022-01-20', data: ''},
            {title: 'Adding a client',            link: 'adding-a-client',            updated: '2022-01-20', data: ''},
            {title: 'Editing Client Information', link: 'editing-client-information', updated: '2022-01-20', data: ''},
            {title: 'Client Interactions',        link: 'client-interactions',        updated: '2022-01-20', data: ''},
            {title: 'Stories',                    link: 'stories',                    updated: '2022-01-20', data: ''},
          ]
        },
        {
          title: 'Plan Management',
          link: 'plan-management',
          image: 'undraw_add_notes',
          access: ['Admin', 'Coordinator'],
          pages: [
            {title: 'Creating a New Plan',        link: 'creating-a-new-plan',      updated: '2022-01-20', data: ''},
            {title: 'Editing & Updating a Plan',  link: 'editing-updating-a-plan',  updated: '2022-01-20', data: ''},
            {title: 'Barriers',                   link: 'barriers',                 updated: '2022-02-01', data: ''},
            {title: 'Supports',                   link: 'supports',                 updated: '2022-01-20', data: ''},
            {title: 'Trainings',                  link: 'trainings',                updated: '2022-01-20', data: ''},
            {title: 'Spendings',                  link: 'spendings',                updated: '2022-01-20', data: ''},
            {title: 'Notes',                      link: 'notes',                    updated: '2022-01-20', data: ''},
            {title: 'Outcomes',                   link: 'outcomes',                 updated: '2022-01-16', data: ''},
          ]
        },
        {
          title: 'Reporting',
          link: 'reporting',
          image: 'undraw_data_report',
          access: ['Admin', 'Coordinator', 'Reporter'],
          pages: [
            {title: 'Reporting',  link: 'reporting',  updated: '2022-03-10', data: ''},
          ]
        },
        {
          title: 'Admin',
          link: 'admin',
          image: 'undraw_dev_focus',
          access: ['Admin'],
          pages: [
            {title: 'Mappings',           link: 'mapping',            updated: '2022-03-25', data: ''},
            {title: 'Managing Centres',   link: 'managing-centres',   updated: '2022-03-25', data: ''},
            {title: 'Managing Users',     link: 'managing-users',     updated: '2022-03-25', data: ''},
            {title: 'Ministry Reporting', link: 'ministry-reporting', updated: '2022-03-25', data: ''},
          ]
        },
        // {
        //   title: 'FAQs',
        //   link: 'faqs',
        //   image: 'undraw_questions',
        //   pages: [
        //   ]
        // }
      ],
      search: '',
      help_data: '',
      ownUser: false
    }
  },

  created () {
    this.ownUser = this.$store.getters.getUser
    this.search = this.search = this.$route.query.q || ''
    this.gatherAllPages()
  },

  methods: {
    gatherAllPages () {
      this.helpFiles.forEach((category,i) => {
        if (!category.access.includes(this.ownUser.roles[0].title)) return
        category.pages.forEach((page,j) => {
          fetch('/help/' + category.link + '/' + page.link + '.html')
            .then(response => response.text())
            .then(data => {
              this.helpFiles[i].pages[j].data = data
            });
        })
      })
    },
    searchHelp () {
      this.$router.push({name:'HelpPage.Search',query:{q:this.search}})
    }
  },

  watch: {
    $route (to, from) {
      this.search = this.$route.query.q || ''
    }
  }
}
</script>

<style lang="scss">
</style>
