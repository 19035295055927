<template>
  <div>
    <label class="text-body-2 grey--text text--darken-1">Description</label>
    <p class="text-body-1">{{group.description || '-'}}</p>

    <label class="text-body-2 grey--text text--darken-1">Date Created</label>
    <p class="text-body-1">{{ moment(group.created_at).format('MMMM DD, YYYY hh:mma') }}</p>

    <FormActionButtons
      :buttons="[
        'edit',
        'delete'
      ]"
      @edit="$emit('edit')"
      @delete="deleteGroup"
    />

    <DeleteModal
      v-if="deleteModal"
      :loading="loading"
      title="Delete Group"
      :text="`Are you sure you wish to delete the current group?`"
      @confirm="deleteGroupConfirm"
      @cancel="deleteGroupCancel" 
    />
  </div>
</template>

<script>
import DeleteModal from '@/components/elements/DeleteModal.vue'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
import { Api } from '@/gateways/endpoints'
var moment = require('moment')

export default {
  components: {
    DeleteModal,
    FormActionButtons
  },
  props: {
    group: {
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      deleteModal: false,
      loading: false,
    }
  },

  created () {
  },

  methods: {
    deleteGroup () {
      this.deleteModal = true
    },
    async deleteGroupConfirm () {
      this.deleteModal = false
      this.loading = true
      const response = await Api.Groups.delete(this.group.group_id)
      if (response) {
        this.loading = false
        this.$toast.success('The group was successfully deleted.')
        this.$router.push({name:'GroupsPage'})
        return
      }
      this.loading = false
      this.$toast.error('Something went wrong. Please try again.')
    },
    deleteGroupCancel () {
      this.deleteModal = false
    }
  }
}
</script>

<style lang="scss">
</style>
