import { render, staticRenderFns } from "./Display.vue?vue&type=template&id=79e307d2&scoped=true&"
import script from "./Display.vue?vue&type=script&lang=js&"
export * from "./Display.vue?vue&type=script&lang=js&"
import style0 from "./Display.vue?vue&type=style&index=0&id=79e307d2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e307d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCol,VContainer,VRow,VSkeletonLoader})
