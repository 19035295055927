<template>
  <v-container style="padding:0;">

    <v-row v-if="!outcome && !outcomeEditing">
      <v-col class="skeleton_loader" sm="12" md="7" lg="5" v-if="outcome===null">
        <div
          v-for="i in 3"
          :key="i"
          style="margin-bottom:24px;"
        >
          <v-skeleton-loader height="17px" width="400px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
          <v-skeleton-loader height="24px" width="200px" type="image"></v-skeleton-loader>
        </div>
      </v-col>

      <v-col sm="12" md="7" lg="5" v-if="outcome===false || outcome===''">
        <p class="placeholder text-body-1 mb-0 blue-grey--text text--darken-2">
          This case currently does not have an outcome. 
          <br/>
          You can create one by clicking the button below.
        </p>
        <FormActionButtons
          :buttons="[]" 
          :customButton="{
            text: false,
            color: 'primary',
            label: 'Add Outcome' ,
            icon: 'mdi-plus'
          }"
          @custom="newOutcome"
        />
      </v-col>
    </v-row>

    <v-row v-if="outcome && !edit">
      <v-col sm="12" md="7" lg="5">
        <label class="text-body-2 grey--text text--darken-1">Outcome date</label>
        <p class="text-body-1">{{ moment(outcome.date).format('MMMM D, YYYY') }}</p>

        <label class="text-body-2 grey--text text--darken-1">Outcome of the program</label>
        <p class="text-body-1">{{outcome.program_outcome || '-'}}</p>

        <div v-if="outcome.incomplete_reason">
          <label class="text-body-2 grey--text text--darken-1">If incomplete, why?</label>
          <p class="text-body-1">{{outcome.incomplete_reason || '-'}}</p>
        </div>

        <label class="text-body-2 grey--text text--darken-1">Post-program employment status</label>
        <p class="text-body-1">{{outcome.post_program_status || '-'}}</p>

        <div v-if="outcome.employment_type">
          <label class="text-body-2 grey--text text--darken-1">If employed or self-employed, what is the employment type?</label>
          <p class="text-body-1">{{outcome.employment_type || '-'}}</p>
          
          <label class="text-body-2 grey--text text--darken-1">If employed or self-employed, what is the job title?</label>
          <p class="text-body-1">{{outcome.job_title || '-'}}</p>
        </div>

        <FormActionButtons
          :buttons="[
            'edit',
            'delete'
          ]" 
          @edit="edit=true;outcomeEditing={...outcome}"
          @delete="deleteOutcome"
        />
      </v-col>
    </v-row>

    <v-row v-if="outcomeEditing && edit">
      <v-col sm="12" md="7" lg="5">
        <p class="text-h5">{{ outcomeEditing.outcome_id===-1 ? 'New Outcome' : 'Edit Outcome' }}</p>
    
        <v-form 
          ref="form"
          @submit.prevent="saveOutcome" 
          novalidate
        >

          <v-text-field
            type="date"
            v-model="outcomeEditing.date"
            label="Date *"
            outlined
            prepend-inner-icon="mdi-calendar"
            :rules="[
              rules.required,
              rules.noFutureDate
            ]"
          />

          <v-select
            :items="['Complete', 'Incomplete', 'Cancelled', 'Rescheduled']"
            v-model="outcomeEditing.program_outcome"
            label="Outcome of the program *"
            outlined
            :rules="[
              rules.required
            ]"
          ></v-select>

          <v-select
            :items="['Gained employment', 'Medical issues', 'Job demands', 'Attending school', 'Family reasons', 'Moved', 'Personal reasons', 'Other']"
            v-model="outcomeEditing.incomplete_reason"
            label="If incomplete, why? *"
            outlined
            :rules="[
              rules.required
            ]"
            v-if="outcomeEditing.program_outcome!=='' && outcomeEditing.program_outcome!=='Complete'"
          ></v-select>

          <v-select
            :items="['Unemployed but available for work', 'Employed', 'Self-employed', 'Returned to school', 'Unspecified - Participant could not be reached', 'Not in the labour force', 'Starting a new action plan']"
            v-model="outcomeEditing.post_program_status"
            label="Post-program employment status *"
            outlined
            :rules="[
              rules.required
            ]"
          ></v-select>

          <div v-if="outcomeEditing.post_program_status && (outcomeEditing.post_program_status==='Employed' || outcomeEditing.post_program_status==='Self-employed')">
            <v-select
              :items="['Casual', 'Seasonal', 'Temporary', 'Permanent', 'Prefer not to report']"
              v-model="outcomeEditing.employment_type"
              label="If employed or self-employed, what is the employment type? *"
              outlined
              :rules="[
                rules.required
              ]"
            ></v-select>

            <v-text-field
              type="text"
              v-model="outcomeEditing.job_title"
              label="If employed or self-employed, what is the job title?"
              outlined
              :rules="[
                rules.maxLength(outcomeEditing.job_title, 64)
              ]"
            />
          </div>

          <FormActionButtons
            :buttons="[
              'cancel',
              'save'
            ]"
            noTopPadding
            :loading="loading"
            @cancel="outcomeEditing=false;edit=false;"
            @save="saveOutcome"
          />
        </v-form>
      </v-col>
    </v-row>

    <DeleteModal
      v-if="deleteModal"
      title="Delete Outcome"
      :text="`Are you sure you wish to delete the outcome for this plan?`"
      @confirm="deleteOutcomeModalConfirm"
      @cancel="deleteOutcomeModalCancel" 
    />

  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'

import DeleteModal from '@/components/elements/DeleteModal.vue'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
var moment = require('moment')

export default {
  components: {
    DeleteModal,
    FormActionButtons
  },
  props: {
    planId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      loading: false,
      rules: false,
      outcome: null,
      outcomeEditing: false,
      edit: false,
      deleteModal: false
    }
  },
  async created () { 
    this.rules = await validationRules
    this.refreshData()
  },
  methods: {
    async refreshData(){
      this.outcome = null
      this.edit = false
      this.outcomeEditing = false
      this.outcome = await Api.Outcomes.getFromPlan(this.planId)
    },
    newOutcome(){
      this.edit = true
      this.outcomeEditing = {
        outcome_id: -1,
        date: moment().format('YYYY-MM-DD'),
        program_outcome: '',
        incomplete_reason: '',
        post_program_status: '',
        employment_type: '',
        job_title: '',
      }
    },

    async saveOutcome() {
      if( !this.$refs.form.validate() ) return
      this.loading = true
      
      this.outcomeEditing.plan_id = this.planId

      if (this.outcomeEditing.outcome_id === -1){
        //create
        const response = await Api.Outcomes.create(this.outcomeEditing)
        if (response) {
          this.loading = false
          this.refreshData()
          this.$toast.success('The outcome was successfully created.')
          return
        }
      } else {
        //update
        const response = await Api.Outcomes.update(this.outcomeEditing.outcome_id, this.outcomeEditing)
        if (response) {
          this.loading = false
          this.refreshData()
          this.$toast.success('The outcome was successfully updated.')
          return
        }
      }
    },

    deleteOutcome () {
      this.deleteModal = true
    },
    async deleteOutcomeModalConfirm () {
      this.deleteModal = false
      const response = await Api.Outcomes.delete(this.outcome.outcome_id, this.outcomeEditing)
      if (response) {
        this.refreshData()
        this.$toast.success('The outcome was successfully deleted.')
        return
      }
      this.$toast.error('Something went wrong. Please try again.')
    },
    deleteOutcomeModalCancel () {
      this.deleteModal = false
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
</style>
