<template>
  <div class="ClientProfileEditForm" v-if="client && rules && provinces">

    <v-form 
      ref="form"
      @submit.prevent="saveClient" 
      novalidate
    >

      <div v-if="page==='client'">
        <v-text-field
          type="text"
          v-model="client.first_name"
          label="First name *"
          outlined
          :rules="[
            rules.required, 
            rules.maxLength(client.first_name,64)
          ]"
        ></v-text-field>
        <v-text-field
          type="text"
          v-model="client.middle_name"
          label="Middle name"
          outlined
          :rules="[
            rules.maxLength(client.middle_name,64)
          ]"
        ></v-text-field>
        <v-text-field
          type="text"
          v-model="client.last_name"
          label="Last name *"
          outlined
          :rules="[
            rules.required, 
            rules.maxLength(client.last_name,64)
          ]"
        ></v-text-field>
        
        <v-text-field
          type="text"
          v-model="client.email"
          label="Email"
          outlined
          :rules="[
            rules.isEmail,
            rules.maxLength(client.email,128)
          ]"
        ></v-text-field>
        
        <v-text-field
          type="text"
          v-model="client.phone"
          label="Phone"
          outlined
          :rules="[
            rules.maxLength(client.phone,32)
          ]"
        ></v-text-field>
        
        <v-text-field
          type="text"
          v-model="client.sin"
          :label="'Social Insurance Number ' + ( !sinNotRequired ? '*': '' )"
          outlined
          :rules="[
            ( !sinNotRequired ? rules.required: true ),
            rules.isSin
          ]"
          :disabled="sinNotRequired"
        ></v-text-field>

        <v-checkbox
          class="mb-3"
          v-model="sinNotRequired"
          label="Missing Social Insurance Number"
        ></v-checkbox>

        <v-row>
          <v-col cols="6">
            <v-text-field
              type="date"
              v-model="client.date_of_birth"
              label="Date of birth *"
              prepend-inner-icon="mdi-calendar"
              outlined
              :rules="[
                rules.required, 
                rules.noFutureDate
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="['Male','Female','Other','Prefer not to say']"
              item-value="id"
              item-text="title"
              v-model="client.gender"
              label="Gender"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-text-field
          type="text"
          v-model="client.address"
          label="Address"
          outlined
          :rules="[
            rules.maxLength(client.address,128)
          ]"
        ></v-text-field>

        <v-text-field
          type="text"
          v-model="client.city"
          label="City *"
          outlined
          :rules="[
            rules.required,
            rules.maxLength(client.city,64)
          ]"
        ></v-text-field>

        <v-row>
          <v-col cols="6">
            <v-select
              :items="provinces"
              item-value="abbr"
              item-text="name"
              v-model="client.province"
              label="Province"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="text"
              v-model="client.postal_code"
              label="Postal Code"
              outlined
              :rules="[
                rules.noSpaces,
                rules.isPostalCode
              ]"
            ></v-text-field>
          </v-col>
        </v-row>

      </div>

      <div v-if="page==='demographic'">
        <v-select
          :items="['Single', 'Married', 'Common Law', 'Divorced', 'Widowed', 'Other', 'Prefer not to say']"
          v-model="client.marital_status"
          label="Marital status"
          outlined
        ></v-select>
        <v-text-field
          type="text"
          v-model="client.number_dependents"
          label="Number of dependents"
          outlined
          :rules="[
            rules.isWholeNumber,
            rules.minNumber(client.number_dependents, 0),
            rules.maxNumber(client.number_dependents, 99)
          ]"
        ></v-text-field>
        <v-select
          :items="['Yes', 'No', 'Prefer not to say']"
          v-model="client.disability"
          label="Do you have a disability?"
          outlined
        ></v-select>
        <v-select
          :items="['Yes', 'No', 'Prefer not to say']"
          v-model="client.youth_in_care"
          label="Have you ever been a Youth in Care?"
          outlined
        ></v-select>
        <v-select
          :items="['English only', 'French only', 'French and English', 'Other']"
          v-model="client.language_spoken"
          label="Languages spoken"
          outlined
        ></v-select>
        <v-select
          :items="['Canadian citizen', 'Permanent resident', 'Protected person']"
          v-model="client.citizenship"
          label="Citizenship *"
          outlined
          :rules="[
            rules.required
          ]"
        ></v-select>
        <v-select
          :items="['None', 'Some highschool', 'Highschool diploma', 'Some college/university', 'University/college diploma', 'Bachelor\'s degree', 'Post-grad degree', 'Prefer not to say']"
          v-model="client.highest_education"
          label="Highest education received"
          outlined
        ></v-select>
      </div>

      <div v-if="page==='ancestry'">
        <v-select
          :items="['Yes', 'No']"
          v-model="client.indigenous"
          label="Do you identify as Indigenous?"
          outlined
        ></v-select>

        <div v-if="client.indigenous==='Yes'">
          <v-select
            :items="['Status First Nation','Non-status First Nation','Métis','Inuit','Prefer not to say']"
            v-model="client.status_type"
            label="Please indicate your ancestry"
            outlined
          ></v-select>

          <v-text-field
            type="text"
            v-model="client.nation"
            label="Nation"
            outlined
            :rules="[
              rules.maxLength(client.nation, 64)
            ]"
          ></v-text-field>

          <v-select
            :items="['On reserve','Off reserve','Prefer not to say']"
            v-model="client.reserve"
            label="Do you live on or off reserve?"
            outlined
          ></v-select>

          <v-select
            :items="['Yes','No']"
            v-model="spokenLanguages"
            label="Do you speak an Indigenous language?"
            outlined
          ></v-select>

          <div v-if="spokenLanguages==='Yes'">
            <v-text-field
              type="text"
              v-model="client.indigenous_language"
              label="Please specify which language(s)"
              outlined
              :rules="[
                rules.maxLength(client.indigenous_language, 64)
              ]"
            ></v-text-field>
          </div>
        </div>

      </div>

      <FormActionButtons
        :buttons="[
          'cancel',
          'save'
        ]"
        noTopPadding
        :loading="loading"
        @cancel="$emit('cancel')"
        @save="saveClient"
      />

    </v-form>

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import Provinces from '@/helpers/provinces'
import validationRules from '@/helpers/validationRules'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  name: 'ClientProfileEditForm',
  components: {
    FormActionButtons
  },
  props: {
    client: {
      type: Object,
      required: true
    },
    page: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      provinces: false,
      rules: false,
      spokenLanguages: 'No',
      sinNotRequired: false,
      loading: false
    }
  },
  async created() {
    this.rules = await validationRules
    this.provinces = await Provinces
    if (this.client.indigenous_language) this.spokenLanguages = 'Yes'
    if (!this.client.sin) this.sinNotRequired = true
  },

  methods: {
    async saveClient() {
      if( !this.$refs.form.validate() ) return
      this.loading = true

      if (this.spokenLanguages=='No') this.client.indigenous_language = ''

      const response = await Api.Clients.update(this.client.client_id, this.client)
      if (response) {
        this.$toast.success('The client was successfully updated.')
        this.$emit('save')
        return
      } else {
        this.loading = false
        this.$toast.error('Something went wrong. Please try again.')
        return
      }
    },
    cancelClient () {
      this.$emit('cancel')
    }
  },

  watch: {
    sinNotRequired(newValue, oldValue){
      if (newValue) {
        this.client.sin = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-input--checkbox {
  margin-top: 0;
}
.actions {
  margin-top: 1.875rem;
  button {
    margin-right: 20px;
  }
}
</style>
