import connection from '../connection'

export default {

  async getAllTypes () {
    try {
      const response = await connection.get('/reporting/types')
      if (!response) return false
      let sorted = response.data.sort((a, b) => (a.type.toLowerCase() > b.type.toLowerCase()) ? -1 : 1)
      sorted = sorted.sort((a, b) => (a.category.toLowerCase() < b.category.toLowerCase()) ? -1 : 1)
      let category = ''
      const cleaned = []
      sorted.forEach((item, i) => {
        if (item.category !== category) {
          category = item.category
          if (i !== 0) {
            cleaned.push({ divider: true })
          }
          cleaned.push({ header: category })
        }
        cleaned.push(item)
      })
      return cleaned
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getMinistryReport (startDate, endDate) {
    try {
      const response = await connection.get(`/reporting/ministry-report?start=${startDate}&end=${endDate}`, {
        responseType: 'blob'
      })
      if (!response) return false
      return response
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}
