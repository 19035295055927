import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'

import LoginRoutes from './routes/Login'
import CentresRoutes from './routes/Centres'
import UsersRoutes from './routes/Users'
import GroupsRoutes from './routes/Groups'
import ClientsRoutes from './routes/Clients'
import PlansRoutes from './routes/Plans'
import StoriesRoutes from './routes/Stories'
import HelpRoutes from './routes/Help'
import ReportRoutes from './routes/Reports'
import MappingRoutes from './routes/Mappings'
import ErrorRoutes from './routes/Errors'

Vue.use(VueRouter)

const routes = [
  ...LoginRoutes,
  ...CentresRoutes,
  ...UsersRoutes,
  ...GroupsRoutes,
  ...ClientsRoutes,
  ...PlansRoutes,
  ...StoriesRoutes,
  ...HelpRoutes,
  ...ReportRoutes,
  ...MappingRoutes,
  ...ErrorRoutes,

  { path: '/', redirect: '/clients' },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from, prevPosition) => {
    goTo(0)
  }
})

export default router
