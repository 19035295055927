var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.clients)?_c('div',{staticClass:"skeleton_loader"},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody, table-tfoot"}})],1):_vm._e(),(_vm.clients)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clients,"sort-by":"updated_at","sort-desc":""},scopedSlots:_vm._u([{key:"item._missing",fn:function(ref){
var item = ref.item;
return [(item._missing)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"help-icon",staticStyle:{"font-size":"21px"},attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-octagon-outline")])]}}],null,true)},[_c('span',[_vm._v("This client is missing required information.")])]):_vm._e()]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'ClientProfilePage',params:{clientId:item.client_id}}}},[_vm._v(_vm._s(item.full_name))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-email-outline")]),_vm._v(" "+_vm._s(item.email))],1):_vm._e(),(!item.email)?_c('span',[_vm._v("-")]):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone || '-')+" ")]}},{key:"item.recent_plan_status",fn:function(ref){
var item = ref.item;
return [_c('span',{},[_vm._v(_vm._s(item.recent_plan_status || '-'))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format('YYYY-MM-DD hh:mma'))+" ")]}}],null,true)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }