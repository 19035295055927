<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <div class="title d-flex align-center justify-space-between">
          <h2 class="text-h2 font-weight-regular">Groups</h2>
          <v-btn
            color="primary"
            elevation="0"
            large
            rounded
            :to="{name:'GroupNewPage'}"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
            New Group
          </v-btn>
        </div>

        
        <div class="skeleton_loader" v-if="!groups">
          <v-skeleton-loader 
            type="table-thead, table-tbody, table-tfoot">
          </v-skeleton-loader>
        </div>
        
        <div v-if="groups">
          <v-text-field
            type="text"
            v-model="search"
            label="Group Search"
            outlined
            hide-details
            style="width:500px;"
          ></v-text-field>

          <v-data-table
            :headers="headers"
            :items="groups"
            sort-by="created_at"
            :search="search"
            sort-desc
          >
            <template v-slot:[`item.name`]="{ item }">
              <router-link :to="{name:'GroupProfilePage',params:{groupId:item.group_id}}">{{item.name}}</router-link>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              {{item.description?(item.description.length>60?item.description.substring(0,57)+'...':item.description):'-'}}
            </template>
            <template v-slot:[`item.clients_count`]="{ item }">
              {{ item.clients_count || 0 }}
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ moment(item.created_at).format('MMMM D, YYYY') }}
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
var moment = require('moment')

export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      moment: moment,
      groups: false,
      search: ''
    }
  },

  created () {
    this.getGroups()
  },

  methods: {
    async getGroups () {
      this.groups = await Api.Groups.getAll()
    }
  },

  computed: {
    headers () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md': 
        case 'lg':
        case 'xl': 
          return [
            { text: 'Name', value: 'name' },
            { text: 'Description', value: 'description' },
            { text: 'Members', value: 'clients_count', width: '150px' },
            { text: 'Date Created', value: 'created_at', width: '250px' },
          ]
      }
    }
  }
}
</script>

<style lang="scss">
</style>
