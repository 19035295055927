<template>
  <div>

    <div v-if="page===1">
      <v-form ref="form_client" @submit.prevent="" novalidate>
        <v-text-field
          type="text"
          v-model="client.first_name"
          label="First Name *"
          outlined
          :rules="[
            rules.required,
            rules.maxLength(client.first_name, 64)
          ]"
        ></v-text-field>
        <v-text-field
          type="text"
          v-model="client.middle_name"
          label="Middle Name"
          outlined
          :rules="[
            rules.maxLength(client.middle_name, 64)
          ]"
        ></v-text-field>
        <v-text-field
          type="text"
          v-model="client.last_name"
          label="Last Name *"
          outlined
          :rules="[
            rules.required,
            rules.maxLength(client.last_name, 64)
          ]"
        ></v-text-field>
        
        <v-text-field
          type="text"
          v-model="client.email"
          label="Email"
          outlined
          :rules="[
            rules.isEmail,
            rules.maxLength(client.email,128)
          ]"
        ></v-text-field>
        
        <v-text-field
          type="text"
          v-model="client.phone"
          label="Phone"
          outlined
          :rules="[
            rules.maxLength(client.phone,32)
          ]"
        ></v-text-field>
        
        <v-text-field
          type="text"
          v-model="client.sin"
          :label="'Social Insurance Number ' + ( !sinNotRequired ? '*': '' )"
          outlined
          :rules="[
            ( !sinNotRequired ? rules.required: true ),
            rules.isSin
          ]"
          :disabled="sinNotRequired"
        ></v-text-field>

        <v-checkbox
          class="mb-3"
          v-model="sinNotRequired"
          label="Missing Social Insurance Number"
        ></v-checkbox>

        <v-row>
          <v-col cols="6">
            <v-text-field
              type="date"
              v-model="client.date_of_birth"
              label="Date of birth *"
              prepend-inner-icon="mdi-calendar"
              outlined
              :rules="[
                rules.required,
                rules.noFutureDate
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="['Male','Female','Other','Prefer not to say']"
              v-model="client.gender"
              label="Gender"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        
        <v-text-field
          type="text"
          v-model="client.address"
          label="Address"
          outlined
          :rules="[
            rules.maxLength(client.address, 128)
          ]"
        ></v-text-field>
        
        <v-text-field
          type="text"
          v-model="client.city"
          label="City *"
          outlined
          :rules="[
            rules.required,
            rules.maxLength(client.city, 64)
          ]"
        ></v-text-field>

        <v-row>
          <v-col cols="6">
            <v-select
              :items="provinces"
              item-value="abbr"
              item-text="name"
              v-model="client.province"
              label="Province"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="text"
              v-model="client.postal_code"
              label="Postal Code"
              outlined
              :rules="[
                rules.noSpaces,
                rules.isPostalCode
              ]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <FormActionButtons
        :buttons="[
          'cancel',
          'next'
        ]" 
        noTopPadding
        @cancel="$router.push({name:'ClientsPage'})"
        @next="nextPage('form_client')"
      />
    </div>

    <div v-if="page===2">
      <v-form ref="form_demographic" @submit.prevent="" novalidate>
        <v-select
          :items="['Single','Married','Divorced','Widowed', 'Other', 'Prefer not to say']"
          v-model="client.marital_status"
          label="Marital Status"
          outlined
        ></v-select>

        <v-text-field
          type="text"
          v-model="client.number_dependents"
          label="Number of dependants"
          outlined
          :rules="[
            rules.isWholeNumber,
            rules.minNumber(client.number_dependents, 0),
            rules.maxNumber(client.number_dependents, 99)
          ]"
        ></v-text-field>

        <v-select
          :items="['Yes','No', 'Prefer not to say']"
          v-model="client.disability"
          label="Do you have a disability?"
          outlined
        ></v-select>

        <v-select
          :items="['Yes','No', 'Prefer not to say']"
          v-model="client.youth_in_care"
          label="Have you ever been a Youth in Care?"
          outlined
        ></v-select>

        <v-select
          :items="['English only','French only', 'French and English', 'Other']"
          v-model="client.language_spoken"
          label="Languages spoken"
          outlined
        ></v-select>

        <v-select
          :items="['Canadian citizen','Permanent resident', 'Protected person']"
          v-model="client.citizenship"
          label="Citizenship *"
          outlined
          :rules="[
            rules.required
          ]"
        ></v-select>

        <v-select
          :items="['None','Some highschool','Highschool diploma','Some university/college','University/college diploma','Bachelor\'s degree','Post-grad degree','Prefer not to say']"
          v-model="client.highest_education"
          label="Highest education received"
          outlined
        ></v-select>
      </v-form>

      <FormActionButtons
        :buttons="[
          'back',
          'next'
        ]" 
        noTopPadding
        @back="previousPage()"
        @next="nextPage('form_demographic')"
      />
    </div>

    <div v-if="page===3">
      <v-form ref="form_ancestry" @submit.prevent="" novalidate>
        <v-select
          :items="['Yes','No','Prefer not to say']"
          v-model="client.indigenous"
          label="Do you identify as Indigenous?"
          outlined
        ></v-select>

        <div v-if="client.indigenous==='Yes'">
          <v-select
            :items="['Status First Nation','Non-status First Nation','Métis','Inuit','Prefer not to say']"
            v-model="client.status_type"
            label="Please indicate your ancestry"
            outlined
          ></v-select>

          <v-text-field
            type="text"
            v-model="client.nation"
            label="Nation"
            outlined
            :rules="[
              rules.maxLength(client.nation,64)
            ]"
          ></v-text-field>

          <v-select
            :items="['On reserve','Off reserve','Prefer not to say']"
            v-model="client.reserve"
            label="Do you live on or off reserve?"
            outlined
          ></v-select>

          <v-select
            :items="['Yes','No']"
            v-model="spokenLanguages"
            label="Do you speak an Indigenous language?"
            outlined
          ></v-select>

          <div v-if="spokenLanguages==='Yes'">
            <v-text-field
              type="text"
              v-model="client.indigenous_language"
              label="Please specify which language(s)"
              outlined
              :rules="[
                rules.maxLength(client.indigenous_language,64)
              ]"
            ></v-text-field>
          </div>
        </div>
      </v-form>

      <FormActionButtons
        :buttons="[
          'back',
          'next'
        ]" 
        noTopPadding
        @back="previousPage()"
        @next="nextPage('form_ancestry')"
      />
    </div>

    <div v-if="page===4">
      <v-form ref="form_consent" @submit.prevent="" novalidate>
        <v-checkbox
          v-model="client.consent"
          label="Consent/release form signed by client *"
          :rules="[
            rules.required
          ]"
        ></v-checkbox>

        <p>By checking this box, you are acknowledging that you have a received consent from the client to store their data in the system and to be used for the purposes of this program.</p>

        <p>
          <v-icon color="primary">mdi-information-outline</v-icon>
          <a href="#" color="primary" @click.prevent="viewConsent">View consent form</a>
        </p>
      </v-form>

      <FormActionButtons
        :buttons="[
          'back',
          'save'
        ]" 
        noTopPadding
        :loading="loading"
        @back="previousPage()"
        @save="createClient('form_consent')"
      />
    </div>

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import Provinces from '@/helpers/provinces'
import validationRules from '@/helpers/validationRules'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  components: {
    FormActionButtons
  },
  created() {
    this.rules = validationRules
    this.provinces = Provinces
  },
  data () {
    return {
      client: {
        first_name: '',
        middle_name: '',
        last_name: '',
        date_of_birth: '',
        sin: '',
        gender: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        province: '',
        postal_code: '',
        language_spoken: '',
        indigenous_language: '',
        marital_status: '',
        number_dependents: '',
        disability: '',
        youth_in_care: '',
        highest_education: '',
        citizenship: '',
        indigenous: '',
        status_type: '',
        nation: '',
        reserve: ''
      },
      sinNotRequired: false,
      spokenLanguages: false,
      provinces: false,
      rules: false,
      page: 1,
      loading: false,
    }
  },

  methods: {
    async createClient(lastForm) {
      if( !this.$refs[lastForm].validate() ) return
      this.loading = true
      
      if (this.spokenLanguages=='No') this.client.indigenous_language = ''

      const response = await Api.Clients.create(this.client)
      if (response) {
        this.loading = false
        this.$emit('leave')
        this.$toast.success('The client was successfully saved!')
        this.$router.push({name:'ClientProfilePage',params:{clientId:response.id}})
        return
      } else {
        this.loading = false
        this.$toast.error('Something went wrong. Please try again.')
        return
      }
    },
    previousPage () {
      this.page--
      this.$emit('pageNumber', this.page)
    },
    nextPage (check_form) {
      if( !this.$refs[check_form].validate() ) return
      this.page++
      this.$emit('pageNumber', this.page)
    },
    viewConsent () {
      window.open('/documents/collection_notice.pdf', '_blank', 'location=yes,height=700,width=850,scrollbars=yes,status=yes')
    }
  },
  watch: {
    sinNotRequired(newValue, oldValue){
      if (newValue) {
        this.client.sin = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-input--checkbox {
  margin-top: 0;
}
</style>
