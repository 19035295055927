var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.group)?_c('div',{staticClass:"skeleton_loader"},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody, table-tfoot"}})],1):_vm._e(),(_vm.group)?_c('div',[_c('div',{staticStyle:{"text-align":"right"}},[_c('FormActionButtons',{attrs:{"buttons":[],"customButton":{
          text: false,
          color: 'primary',
          label: 'Manage Members',
          icon: 'mdi-plus',
          icon_start: true,
        },"noPadding":""},on:{"custom":function($event){return _vm.$emit('edit')}}})],1),_c('v-data-table',{attrs:{"headers":[
        { text: 'Name', value: 'full_name' },
        { text: 'Date of Birth', value: 'date_of_birth' } ],"items":_vm.group.clients,"sort-by":"full_name","sort-desc":""},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'ClientProfilePage',params:{clientId:item.client_id}}}},[_vm._v(_vm._s(item.full_name))])]}},{key:"item.date_of_birth",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date_of_birth).format('MMMM D, YYYY'))+" ")]}}],null,true)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }