import connection from '../connection'
import store from '@/store'

// login
// logout
// forgot password
// update password

export default {

  async login (user, pass) {
    const response = await connection.post('/auth/login', {
      email: user,
      password: pass
    })
    if (!response) return false
    store.dispatch('setAccessToken', response.data.accessToken)
    store.dispatch('setRefreshToken', response.data.refreshToken)
    store.dispatch('setUser', response.data.user)
    return true
  },

  logout () {
    store.dispatch('removeLogin')
    store.dispatch('clearClient')
    store.dispatch('clearCache')
    return true
  },

  async forgotPassword (user) {
    const response = await connection.post('/auth/request-reset', {
      email: user
    })
    if (!response) return false
    return response.data
  },

  async checkPasswordToken (token, id) {
    const response = await connection.post('/auth/check-reset', { token, id })
    if (!response) return false
    return true
  },

  async updatePassword (password, token, id) {
    const response = await connection.post('/auth/reset-password', { password, token, id })
    if (!response) return false
    return true
  }

}

