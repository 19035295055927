const provinces = [
  { abbr: 'AB', name: 'Alberta' },
  { abbr: 'BC', name: 'British Columbia' },
  { abbr: 'MB', name: 'Manitoba' },
  { abbr: 'NB', name: 'New Brunswick' },
  { abbr: 'NL', name: 'Newfoundland and Labrador' },
  { abbr: 'NT', name: 'Northwest Territories' },
  { abbr: 'NS', name: 'Nova Scotia' },
  { abbr: 'NU', name: 'Nunavut' },
  { abbr: 'ON', name: 'Ontario' },
  { abbr: 'PE', name: 'Prince Edward Island' },
  { abbr: 'QC', name: 'Quebec' },
  { abbr: 'SK', name: 'Saskatchewan' },
  { abbr: 'YT', name: 'Yukon' }
]

export default provinces
