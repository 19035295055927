<template>
  <v-app>

    <!-- <div style="position:fixed;top:0;left:0;z-index:10000;background-color:white;padding:10px;max-width:200px">
      {{$vuetify.breakpoint.name}}
    </div> -->

    <SideMenu />
    <NavMenu />

    <v-main :style="`margin-left:${mainMargin}px`">
      <router-view />
    </v-main>

  </v-app>
</template>

<script>
import NavMenu from '@/components/NavMenu'
import SideMenu from '@/components/SideMenu'

export default {
  components: {
    NavMenu,
    SideMenu
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      mainMargin: 0,
    }
  },
  
  created() {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth<1700 && this.isSideMenuVisible){
        this.mainMargin = 1700 - this.windowWidth 
        if (this.mainMargin>220) this.mainMargin = 220
      } else {
        this.mainMargin = 0
      }
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        // Update title
        // OVERRIDE CODE
        // this.$route.meta.title = 'title'
        if (to.meta.title) {
          document.title = to.meta.title + ' | ELST'
        }
        else {
          document.title = 'ELST'
        }

        this.onResize()
      }
    }
  },

  computed: {
    isSideMenuVisible () {
      const visiblePages = [
        'ClientProfilePage',
        'PlanProfilePage',
        'SuccessStories'
      ]
      if (
        visiblePages.includes(this.$route.name)
      ) {
        return true
      }
      return false
    }
  },
}
</script>

<style lang="scss">
/* Style Overrides */
.theme--light.v-application {
  background: transparent!important;
}
@media (min-width: 1904px) {
  .container {
    max-width: 1320px!important;
  }
}

.hidden {
  display: none!important;
}

.v-main {
  margin-top: 30px;
  margin-bottom: 50px;
}

.v-tooltip__content {
  max-width: 400px;
}

#bg-image {
  display: flex;
  height: 70vh;
  svg {
    width: 100%;
    height: auto;
  }
}
#bg-block {
  position: fixed;
  top: 0;
  right: 0;
  width: 45%;
  height: 100vh;
  z-index: -100;
  background-color: #E1F5FE;
  opacity: 0.45;
  @media screen and (max-width: 959px){
    display: none!important;
  }
}

.v-application p.text-body-1 {
  font-size: 1.125rem!important;
  // font-weight: 300!important;
  // margin-bottom: 0;
}
.v-navigation-drawer__border {
  display: none!important;
}
.v-input--checkbox {
  margin-top: 0!important;
}


.v-data-table table {
  thead th {
    font-size: 1.125rem!important;
    padding-top: 1.25rem!important;
    padding-bottom: 1.25rem!important;
  }
  tbody td {
    font-size: 1rem!important;
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;

    a {
      text-decoration: none!important;
    }
  }
}

.help-icon {
  cursor: help!important;
}
</style>
