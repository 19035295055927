<template>
  <div class="ClientProfileEditForm" v-if="plan && rules">

    <v-form 
      ref="form"
      @submit.prevent="" 
      novalidate
    >

      <v-text-field
        type="date"
        v-model="plan.date"
        label="Plan start date *"
        prepend-inner-icon="mdi-calendar"
        outlined
        :rules="[
          rules.required,
          rules.noFutureDate
        ]"
      />

      <v-select
        :items="['Unemployed','Employed','Self-employed','Not in the labour force']"
        v-model="plan.current_employment"
        label="Current level of employment"
        outlined
      ></v-select>

      <v-select
        :items="['Casual','Seasonal','Temporary','Insufficient hours', 'Permanent', 'Prefer not to report']"
        v-model="plan.employment_type"
        label="Employment type"
        v-if="plan.current_employment === ('Employed' || 'Self-employed')"
        outlined
      ></v-select>

      <v-select
        :items="['Yes','No','Prefer not to say']"
        v-model="plan.apprentice"
        label="Are you an apprentice?"
        outlined
      ></v-select>

      <v-select
        :items="['Yes','No','Prefer not to say']"
        v-model="plan.accessing_supports"
        label="Are you accessing other supports in the community?"
        outlined
      ></v-select>

      <v-select
        :items="['Yes','No','Prefer not to say']"
        v-model="plan.relocate"
        label="Are you willing to relocate for training?"
        outlined
      ></v-select>

      <v-select
        :items="['Yes','No','Prefer not to say']"
        v-model="plan.transportation"
        label="Do you have access to reliable transportation?"
        outlined
      ></v-select>

      <FormActionButtons
        :buttons="[
          'cancel',
          'next'
        ]" 
        noTopPadding
        @cancel="$emit('cancel')"
        @next="$emit('next')"
      />

    </v-form>

  </div>
</template>

<script>
import validationRules from '@/helpers/validationRules'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  name: 'PlanNewForm',
  components: {
    FormActionButtons
  },
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules: false
    }
  },
  async created() {
    this.rules = await validationRules
  }
}
</script>

<style scoped lang="scss">
.v-input--checkbox {
  margin-top: 0;
}
</style>
