var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.interactions || !_vm.clientInteractionSums)?_c('v-container',{staticClass:"skeleton_loader",staticStyle:{"padding":"0"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"7","lg":"5","order-sm":"2","order-md":"1"}},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody"}})],1),_c('v-col',{attrs:{"sm":"12","md":"5","lg":"7","order-sm":"1","order-md":"2"}},[_c('v-skeleton-loader',{attrs:{"height":"36px","width":"189px","type":"image"}})],1)],1)],1):_vm._e(),(_vm.interactions && _vm.clientInteractionSums)?_c('v-container',{staticStyle:{"padding":"0"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"7","lg":"5","order-sm":"2","order-md":"1"}},[(_vm.cleanedList)?_c('v-data-table',{attrs:{"headers":[
            { text: 'Interaction Type', value: 'name' },
            { text: 'Amount', value: 'count' }
          ],"items":_vm.cleanedList}}):_vm._e()],1),_c('v-col',{attrs:{"sm":"12","md":"5","lg":"7","order-sm":"1","order-md":"2"}},[_c('FormActionButtons',{attrs:{"buttons":[],"customButton":{
            text: false,
            color: 'primary',
            label: 'Add Interaction' ,
            icon: 'mdi-plus'
          }},on:{"custom":function($event){_vm.modal=true}}})],1)],1)],1):_vm._e(),(_vm.modal)?_c('ClientInteractionModal',{attrs:{"client":_vm.client.client_id},on:{"close":function($event){_vm.modal=false;_vm.updateCounts();}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }