import { checkAuth } from '../functions'
import ReportPage from '@/views/Reports/ReportPage'

export default [
  {
    path: '/reports',
    name: 'ReportPage',
    component: ReportPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'Reports'
    }
  }
]
