<template>
  <v-container>
    <v-row>
      <v-col sm="12" class="pb-0">
        <div class="title d-flex align-center justify-space-between">
          <div>
            <h5 class="text-h5 font-weight-light grey--text text--darken-1">Mappings</h5>
            <h2 class="text-h2 font-weight-regular">{{titleCapitalized}}</h2>
          </div>
          <v-btn
            color="primary"
            elevation="0"
            large
            rounded
            :to="{name:'MappingIndividualPage',params:{category:category,id:'new'}}"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
            New {{titleSigular}}
          </v-btn>
        </div>
      </v-col>
      <v-col sm="12" class="pt-0">
        <MappingList
          :category="category"
          :items="selectedCategory"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import MappingList from '@/components/Mappings/MappingList'

export default {
  components: {
    MappingList
  },
  props: {
    category: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selectedCategory: {
        active: false,
        disabled: false
      },
    }
  },

  created () {
    this.getLists()
  },

  methods: {
    async getLists(){
      if (this.category==='barriers') {
        this.selectedCategory.active = await Api.Barriers.getAll()
        this.selectedCategory.disabled = await Api.Barriers.getAllDisabled()
      } else if (this.category==='supports') {
        this.selectedCategory.active = await Api.Supports.getAll()
        this.selectedCategory.disabled = await Api.Supports.getAllDisabled()
      } else {
        this.selectedCategory.active = await Api.Trainings.getAll()
        this.selectedCategory.disabled = await Api.Trainings.getAllDisabled()
      }
    },
  },

  computed: {
    titleCapitalized(){
      return this.category.charAt(0).toUpperCase() + this.category.slice(1)
    },
    titleSigular(){
      return this.category.slice(0, -1)
    }
  }
}
</script>

<style lang="scss">
</style>
