/* eslint-disable dot-notation */
import axios from 'axios'
import getEnv from '@/helpers/getEnv'

const connection = axios.create()

connection.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json'
  config.headers['user-key'] = getEnv().VUE_APP_NOC_KEY
  return config
}, (err) => {
  console.error('Error Sending: ' + err.message)
  return false
})

connection.interceptors.response.use(async (response) => {
  return response
}, async (err) => {
  console.error('Error Receving: ' + err.message)
  return false
})

export default {
  async searchNoc (query) {
    try {
      const response = await connection.get(`https://lmi-nac-esdc-edsc-apicast-production.api.canada.ca/clmix-wsx/ai/nbc/noc2016/v2?q=${query}&s=3`)
      if (!response) return false
      return response.data.nbLabels
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }
}
