<template>
  <div>
    <v-form ref="form_details" @submit.prevent="saveGroup" novalidate>
      <v-text-field
        type="text"
        v-model="group.name"
        label="Group Name *"
        outlined
        :rules="[
          rules.required,
          rules.maxLength(group.name,64)
        ]"
      ></v-text-field>

      <v-textarea
        type="text"
        v-model="group.description"
        label="Description"
        outlined
        rows="3"
        counter="128"
        :rules="[
          rules.maxLength(group.description,128)
        ]"
      />

      <FormActionButtons
        v-if="type==='edit'"
        :buttons="[
          'cancel',
          'save'
        ]"
        @cancel="$emit('cancel')"
        @save="saveGroup"
      />

      <FormActionButtons
        v-if="type==='new'"
        :buttons="[
          'cancel',
          'next'
        ]"
        @cancel="$emit('cancel')"
        @next="saveGroup"
      />
    </v-form>
  </div>
</template>

<script>
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
import validationRules from '@/helpers/validationRules'
import { Api } from '@/gateways/endpoints'
var moment = require('moment')

export default {
  components: {
    FormActionButtons
  },
  props: {
    group: {
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      rules: validationRules,
    }
  },

  created () {

  },

  methods: {
    async saveGroup () {
      if( !this.$refs['form_details'].validate() ) return
      this.$emit('save', this.group)
    }
  }
}
</script>

<style lang="scss">
</style>
