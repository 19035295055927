import Users from './Users.api'
import Auth from './Auth.api'
import Centres from './Centres.api'
import Roles from './Roles.api'

import Clients from './Clients.api'
import Groups from './Groups.api'
import Interactions from './Interactions.api'
import Stories from './Stories.api'

import Plans from './Plans.api'
import Barriers from './Barriers.api'
import Supports from './Supports.api'
import Trainings from './Trainings.api'
import Spendings from './Spendings.api'
import Notes from './Notes.api'
import Outcomes from './Outcomes.api'

import Reporting from './Reporting.api'

import Nocs from './Nocs.api'
import Jira from './Jira.api'

export const Api = {
  Users: { ...Users },
  Auth: { ...Auth },
  Centres: { ...Centres },
  Roles: { ...Roles },
  Clients: { ...Clients },
  Groups: { ...Groups },
  Interactions: { ...Interactions },
  Stories: { ...Stories },
  Plans: { ...Plans },
  Barriers: { ...Barriers },
  Supports: { ...Supports },
  Trainings: { ...Trainings },
  Spendings: { ...Spendings },
  Notes: { ...Notes },
  Outcomes: { ...Outcomes },
  Reporting: { ...Reporting },

  Nocs: { ...Nocs },
  Jira: { ...Jira }
}
