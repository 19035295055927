import validator from 'validator'
const zxcvbn = require('zxcvbn')
const moment = require('moment')

const allRules = {

  required: (v, msg = false) => !!v || (msg || 'This field is required'),

  minLength: (v, limit, msg = false) => !v || validator.isLength(v, { min: limit }) || (msg || `This field must be at least ${limit} characters`),
  maxLength: (v, limit, msg = false) => !v || validator.isLength(v, { max: limit }) || (msg || `This field must be less than ${limit} characters`),
  minNumber: (v, limit, msg = false) => !v || parseInt(v) >= limit || (msg || `This field must be more than ${limit}`),
  maxNumber: (v, limit, msg = false) => !v || parseInt(v) <= limit || (msg || `This field must be less than ${limit}`),

  noSpaces: (v, msg = false) => !v || !((/\s/).test(v)) || (msg || 'This field must not contain any spaces'),

  isWholeNumber: (v, msg = false) => !v || (/^\d+$/).test(v) || (msg || 'This field must be a whole number'),
  isFloatNumber: (v, msg = false) => !v || (/^\d*\.?\d*$/).test(v) || (msg || 'This field must be a number'),
  isMoney: (v, msg = false) => !v || (/(^[0-9]*\.[0-9]{2}$)|(^[0-9]*$)/).test(v) || (msg || 'This field must be a monetary value'),

  isEmail: (v, msg = false) => !v || validator.isEmail(v) || (msg || 'This field must be an email'),
  isSin: (v, msg = false) => !v || (v <= 999999999 && v >= 99999999) || (msg || 'This field must be 9 numbers'),
  isPostalCode: (v, msg = false) => !v || ((/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]?\d[ABCEGHJ-NPRSTV-Z]\d$/i).test(v)) || (msg || 'This field must be a valid postal code'),
  isNOC: (v, msg = false) => !v || (validator.isLength(v, { min: 4, max: 4 }) && (/^\d+$/).test(v)) || (msg || 'This field must be a vlid NOC'),

  mustMatch: (v, matching, msg = false) => v === matching || (msg || 'Values must match'),

  maxImageSize: (v, msg = false) => !v || v < 70000 || (msg || 'Image is too large'),

  noFutureDate: (v, msg = false) => !v || moment(v).isBefore(moment()) || (msg || 'Date can\'t be in the future'),

  checkPasswordStrength: (password) => {
    const results = {
      pass: true,
      score: 0,
      checks: {
        length: false,
        uppercase: false,
        lowercase: false,
        number: false
      }
    }

    // check if empty
    if (password.length === 0) {
      results.pass = false
      return results
    }

    // generate score
    results.score = zxcvbn(password).score + 1

    // check if min length
    if (validator.isLength(password, { min: 8 })) {
      results.checks.length = true
    } else {
      results.pass = false
    }

    // check if contains uppercase
    if (password !== password.toLowerCase()) {
      results.checks.uppercase = true
    } else {
      results.pass = false
    }

    // check if contains lowercase
    if (password !== password.toUpperCase()) {
      results.checks.lowercase = true
    } else {
      results.pass = false
    }

    // check if contains number
    if ((/\d/).test(password)) {
      results.checks.number = true
    } else {
      results.pass = false
    }

    return results

  }

}

export default allRules
