<template>
  <div>
    <div class="skeleton_loader" v-if="!group || !allClients">
      <v-skeleton-loader 
        type="table-thead, table-tbody, table-tfoot">
      </v-skeleton-loader>
    </div>

    <div v-if="group && allClients">

      <v-form @submit.prevent="addClient" novalidate style="display:flex;">
        <v-autocomplete
          v-model="selectedClient"
          :items="cleanedAllClients"
          item-text="title"
          outlined
          label="Client"
          return-object
        ></v-autocomplete>
        <v-btn 
          color="primary" 
          x-large 
          @click="addClient"
          elevation="0"
          style="height:56px;min-width:56px;margin-left:10px;"
        >Add Member</v-btn>
      </v-form>

      <v-data-table
        :headers="[
          { text: 'Name', value: 'full_name' },
          { text: 'Date of Birth', value: 'date_of_birth' },
          { text: '', value: '_controls', sortable: false, width: '53' },
        ]"
        :items="cleanedClients"
        sort-by="name"
        :items-per-page="-1"
        sort-desc
      >
        <template v-slot:[`item.date_of_birth`]="{ item }">
          {{ moment(item.date_of_birth).format('MMMM D, YYYY') }}
        </template>
        <template v-slot:[`item._controls`]="{ item }">
          <v-btn
            color="red darken-2"
            icon
            @click="removeClient(item.client_id)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <FormActionButtons
        :buttons="[
          'cancel',
          'save'
        ]" 
        @cancel="$emit('cancel')"
        @save="saveGroup"
      />
    </div>
  </div>
</template>

<script>
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
import { Api } from '@/gateways/endpoints'
var moment = require('moment')

export default {
  components: {
    FormActionButtons
  },
  props: {
    group: {
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      allClients: false,
      selectedClient: false,
    }
  },

  async created () {
    this.allClients = await Api.Clients.getAllMinimal()
  },

  methods: {
    addClient () {
      if (!this.selectedClient) return
      this.group.clients.unshift(this.selectedClient)
      this.selectedClient = false
    },
    removeClient (client_id) {
      this.group.clients.splice(this.group.clients.findIndex(function(i){
        return i.client_id === client_id;
      }), 1);
    },
    async saveGroup () {
      this.$emit('save', this.group)
    }
  },

  computed: {
    cleanedAllClients () {
      let cleaned = Object.assign([], this.allClients)
      this.group.clients.forEach((client,i) => {
        cleaned.splice(cleaned.findIndex(function(i){
          return i.client_id === client.client_id;
        }), 1);
      })
      return cleaned
    },
    cleanedClients () {
      let cleaned = []
      this.group.clients.forEach((client,i) => {
        cleaned.push({
          ...client,
          _controls: null
        })
      })
      return cleaned
    }
  }
}
</script>

<style lang="scss">
</style>
