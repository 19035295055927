import connection from '../connection'
import store from '@/store'
import { Api } from '@/gateways/endpoints'
const moment = require('moment')

async function returnCleanClients (clients) {
  if (Object.prototype.toString.call(clients) === '[object Object]') {
    clients = [clients]
  }

  let centres
  const ownUser = store.getters.getUser
  if (ownUser.roles[0].role_id === 1) {
    centres = await Api.Centres.getAll()
  }

  const cleaned = clients.map((row) => {
    return {
      ...row,
      full_name: row.first_name + ' ' + row.last_name,
      centre_name: ownUser.roles[0].role_id === 1 ? centres.find((obj) => obj.centre_id === row.centre_id).name : '',
      _missing: (
        // mandatory for system ie. cannot create a user without
        (row.first_name === null || row.first_name === '') ||
        (row.last_name === null || row.last_name === '') ||
        (row.date_of_birth === null || row.date_of_birth === '') ||
        (row.city === null || row.city === '') ||
        (row.sin === null || row.sin === '') ||
        (row.citizenship === null || row.citizenship === '') ||

        // system with throw an alert
        (row.sin === null || row.sin === '') ||
        (row.phone === null || row.phone === '') ||
        (row.email === null || row.email === '') ||
        (row.marital_status === null || row.marital_status === '') ||
        (row.disability === null || row.disability === '') ||
        (row.youth_in_care === null || row.youth_in_care === '') ||
        (row.language_spoken === null || row.language_spoken === '') ||
        (row.highest_education === null || row.highest_education === '') ||
        (row.indigenous === null || row.indigenous === '') ||

        // if indigenous is 'Yes' then type and reserve are required
        (row.indigenous === 'Yes' ? ((row.status_type === null || row.status_type === '') || (row.reserve === null || row.reserve === '')) : '')
      )
    }
  })
  return cleaned
}

async function sendCleanClients (client) {
  if (client.centre_id === undefined) client.centre_id = store.getters.getUser.centre.centre_id

  if (client.indigenous === 'No') {
    client.status_type = null
    client.nation = null
    client.reserve = null
    client.status_number = null
  }

  // remove extra data
  delete client.full_name
  delete client.centre_name
  delete client._missing

  // convert all empty strings to nulls
  client = Object.keys(client).reduce((acc, key) => { acc[key] = client[key] === '' ? null : client[key]; return acc }, {})

  return client
}

export default {

  async getAll (search) {
    try {
      let response
      if (search === '') {
        response = await connection.get('/clients')
      } else {
        response = await connection.get(`/clients/search/${search}`)
      }
      if (!response) return false
      const values = await returnCleanClients(response.data)
      return values
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getAllMinimal () {
    try {
      const response = await connection.get('/clients')
      if (!response) return false
      const values = response.data.map((row) => {
        return {
          client_id: row.client_id,
          title: row.first_name + ' ' + row.last_name + ' (' + moment(row.date_of_birth).format('MMM D, YYYY') + ')',
          full_name: row.first_name + ' ' + row.last_name,
          date_of_birth: row.date_of_birth
        }
      })
      return values
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getSingle (clientId) {
    try {
      const response = await connection.get('/clients/' + clientId)
      if (!response) return false
      const values = await returnCleanClients(response.data)
      return values[0]
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (clientId, clientData) {
    try {
      clientData = await sendCleanClients(clientData)
      const response = await connection.put('/clients/' + clientId, clientData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (clientData) {
    try {
      clientData = await sendCleanClients(clientData)
      const response = await connection.post('/clients', clientData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (clientId) {
    try {
      const response = await connection.delete('/clients/' + clientId)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

