<template>
  <div class="LoginForm" v-if="rules">

    <div class="img-holder">
      <img src="/images/bcaafc.png" />
    </div>
    <div class="title text-center">
      <span class="text-h3">ELST Login</span>
    </div>

    <v-form 
      ref="form"
      @submit.prevent="login" 
      novalidate
    >

      <v-text-field
        type="text"
        v-model="loginUser"
        id="loginUser"
        label="Email *"
        outlined
        :rules="[
          rules.required(loginUser, 'Email is required'),
          rules.minLength(loginUser, 8, 'Email must be at least 8 characters'),
          rules.maxLength(loginUser, 128, 'Email must less than 128 characters'),
          rules.isEmail(loginUser, 'Must be a valid email')
        ]"
        validate-on-blur
        @blur="()=>{this.submissionStatus=true}"
      ></v-text-field>

      <v-text-field
        :type="loginPassShow ? 'text' : 'password'" 
        v-model="loginPass"
        id="loginPass"
        label="Password *"
        :append-icon="loginPassShow ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="togglePassword"
        outlined
        :rules="[
          rules.required(loginPass, 'Password is required'),
          rules.minLength(loginPass, 8, 'Password must be at least 8 characters'),
          rules.maxLength(loginPass, 128, 'Password must less than 128 characters')
        ]"
        validate-on-blur
        @blur="()=>{this.submissionStatus=true}"
      ></v-text-field>

      <v-btn
        type="submit"
        color="primary"
        block
        :loading="loading"
        :disabled="loading"
        x-large
        elevation="0"
      >
        Login <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-form>

    <br/>
    <div class="text-center">
      <router-link :to="{name:'ForgotPasswordPage'}">Forgot Password</router-link>
    </div>
  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'
import homePage from '@/helpers/homePage'

export default {
  data () {
    return {
      rules: false,
      submissionStatus: true,
      submitting: false,
      loginUser: '',
      loginPass: '',
      loginPassShow: false,
      loading: false,
    }
  },

  created () {
    this.rules = validationRules
  },

  mounted () {
    if (this.$store.getters.checkUserAuth === true) {
      homePage(this.$vuetify)
    }
  },

  methods: {
    togglePassword () {
      this.loginPassShow = !this.loginPassShow;
    },
    async login (e) {
      if( !this.$refs.form.validate() ) return
      this.loading = true

      this.submitting = true
      const response = await Api.Auth.login(this.loginUser, this.loginPass)
      if (response) {
        homePage(this.$vuetify)
        return
      } else {
        this.loading = false
        this.$toast.error('Incorrect email and password combination.');
        this.submissionStatus = false
        return
      }
    }
  }
}
</script>

<style scoped lang="scss">
.img-holder {
  text-align: center;

  img {
    width: 80%;
    max-width: 200px;
    margin-bottom: 0px;
  }
}
</style>
