const moment = require('moment')

export default {
  getFiscalDates (date = false) {
    if (!date) {
      date = moment()
    }

    const year = moment(date).format('YYYY')
    const month = moment(date).format('M')
    let range
    if (month > 3) {
      range = {
        start: year + '-04-01',
        end: (parseInt(year) + 1) + '-03-31'
      }
    } else {
      range = {
        start: (parseInt(year) - 1) + '-04-01',
        end: year + '-03-31'
      }
    }

    return range
  }
}
