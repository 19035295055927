var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Active")]),_c('v-tab',[_vm._v("Disabled")])],1),(!_vm.tableItems)?_c('div',{staticClass:"skeleton_loader"},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody, table-tfoot"}})],1):_vm._e(),(_vm.tableItems)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"sort-by":"name"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name!=='Other')?_c('router-link',{attrs:{"to":{name:'MappingIndividualPage',params:{category:_vm.category,id:item[_vm.tableKey]}}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(item.name==='Other')?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e()]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description!==null?(item.description.length>50?item.description.substring(0,47)+'...':item.description):'-')+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category||'-')+" ")]}},{key:"item.certificate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.certificate?'Yes':'No')+" ")]}},{key:"item.reporting_type_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reporting_type_name!==null?(item.reporting_type_name.length>50?item.reporting_type_name.substring(0,47)+'...':item.reporting_type_name):'-')+" ")]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }