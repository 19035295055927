<template>
  <v-container>
    <v-row>
      <v-col sm="12" md="6" lg="5" v-if="rules">
        <v-form 
          ref="form"
          @submit.prevent="saveSupport" 
          novalidate
        >

          <v-text-field
            type="text"
            v-model="item.name"
            label="Name *"
            outlined
            :rules="[
              rules.required, 
              rules.maxLength(item.name,64)
            ]"
          />

          <v-select
            :items="['Cultural', 'Employment', 'Life Skills', 'Misc.']"
            v-model="item.category"
            label="Category *"
            outlined
            :rules="[
              rules.required
            ]"
          ></v-select>

          <v-select
            :items="mappings"
            item-text="type"
            item-value="reporting_type_id"
            v-model="item.reporting_type"
            label="Mapping *"
            outlined
            :rules="[
              rules.required
            ]"
          ></v-select>

          <v-textarea
            type="text"
            v-model="item.description"
            label="Description"
            outlined
            rows="5"
            counter="350"
            :rules="[
              rules.maxLength(item.description,350)
            ]"
          />

          <FormActionButtons
            :buttons="[
              'cancel',
              'save'
            ]"
            :loading="loading"
            @cancel="goBack()"
            @save="saveSupport"
          />
        </v-form>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  components: {
    FormActionButtons
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    mappings: {
      type: Array,
      required: true,
    }
  },
  data () {
    return {
      rules: false,
      loading: false
    }
  },

  async created () {
    this.rules = await validationRules
  },

  methods: {
    goBack(){
      if(this.item.support_id){
        this.$emit('back')
      } else {
        this.$router.push({name:'MappingCategoryPage',params:{category:'supports'}})
      }
    },
    async saveSupport () {
      if( !this.$refs.form.validate() ) return
      this.loading = true
      
      let response;
      if(this.item.support_id){
        response = await Api.Supports.update(this.item.support_id, this.item)
      } else {
        response = await Api.Supports.create(this.item)
      }

      if (response) {
        this.$toast.success('The support was successfully updated.')
        this.goBack()
        return
      } else {
        this.loading = false
        this.$toast.error('Something went wrong. Please try again.')
        return
      }
    }
  },

  computed: {
  }
}
</script>

<style lang="scss">
</style>
