var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rules && _vm.dropdowns.centres && _vm.dropdowns.roles)?_c('div',{staticClass:"UserProfileNewForm"},[_c('v-form',{ref:"UserProfileNewForm",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.createUser.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"id":"user_first_name","type":"text","label":"First Name *","outlined":"","rules":[
        _vm.rules.required, 
        _vm.rules.maxLength(_vm.user.first_name,128)
      ]},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),_c('v-text-field',{attrs:{"id":"user_last_name","type":"text","label":"Last Name *","outlined":"","rules":[
        _vm.rules.required, 
        _vm.rules.maxLength(_vm.user.first_name,128)
      ]},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('v-text-field',{attrs:{"id":"user_email","type":"email","label":"Email *","outlined":"","rules":[
        _vm.rules.required, 
        _vm.rules.maxLength(_vm.user.first_name,128),
        _vm.rules.isEmail
      ]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-select',{attrs:{"id":"user_centre","items":[{name:'None',centre_id:-1}].concat(_vm.dropdowns.centres),"item-text":"name","item-value":"centre_id","label":"Centre *","outlined":"","rules":[
        _vm.rules.required
      ]},model:{value:(_vm.user.centre.centre_id),callback:function ($$v) {_vm.$set(_vm.user.centre, "centre_id", $$v)},expression:"user.centre.centre_id"}}),_c('v-select',{attrs:{"id":"user_role","items":_vm.dropdowns.roles,"item-text":"title","item-value":"role_id","label":"Role *","outlined":"","rules":[
        _vm.rules.required
      ]},model:{value:(_vm.user.roles[0].role_id),callback:function ($$v) {_vm.$set(_vm.user.roles[0], "role_id", $$v)},expression:"user.roles[0].role_id"}}),_c('FormActionButtons',{attrs:{"buttons":[
        'cancel',
        'save'
      ],"noTopPadding":""},on:{"cancel":function($event){return _vm.$router.push({name:'UsersPage'})},"save":_vm.createUser}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }