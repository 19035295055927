<template>
  <v-container>
    <v-row justify-md="space-between" justify="center">
      <v-col xl="5" lg="5" md="6" sm="8">
        <div class="title">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">Group Profile</h5>
          <h2 class="text-h2 font-weight-regular">
            <v-skeleton-loader class="skeleton_loader" height="60px" width="400px" type="image" v-if="!group"></v-skeleton-loader>
            {{group.name}}
          </h2>
        </div>

        <v-tabs class="pb-6" v-model="page" show-arrows>
          <v-tab @click="edit=false">Members</v-tab>
          <v-tab @click="edit=false">Group Details</v-tab>
        </v-tabs>

        <div v-if="page===0">
          <GroupMembersDisplay
            v-if="!edit"
            :group="group"
            @edit="edit=true"
          />
          <GroupMembersEdit
            v-if="edit"
            :group="groupEdit"
            @cancel="cancelEdit"
            @save="saveGroupMembers"
          />
        </div>

        <div v-if="page===1">
          <GroupDetailsDisplay
            v-if="!edit"
            :group="group"
            @edit="edit=true"
          />
          <GroupDetailsEdit
            v-if="edit"
            type="edit"
            :group="groupEdit"
            @cancel="cancelEdit"
            @save="saveGroup"
          />
        </div>

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex">

        <div id="bg-block"></div>
        <div id="bg-image"><InlineSvg src="/images/svgs/undraw_group_selfie.svg" /></div>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GroupMembersDisplay from '@/components/Groups/GroupMembersDisplay'
import GroupMembersEdit from '@/components/Groups/GroupMembersEdit'
import GroupDetailsDisplay from '@/components/Groups/GroupDetailsDisplay'
import GroupDetailsEdit from '@/components/Groups/GroupDetailsEdit'
import { Api } from '@/gateways/endpoints'

export default {
  components: {
    GroupMembersDisplay,
    GroupMembersEdit,
    GroupDetailsDisplay,
    GroupDetailsEdit
  },
  props: {
    groupId: {
      required: true
    }
  },
  data () {
    return {
      edit: false,
      loading: false,
      page: 0,
      group: false,
      groupEdit: false,
    }
  },

  created () {
    this.getGroup()
  },

  methods: {
    async getGroup () {
      this.group = await Api.Groups.getSingle(this.groupId)
      this.groupEdit = JSON.parse(JSON.stringify(this.group))
    },

    async cancelEdit () {
      this.groupEdit = JSON.parse(JSON.stringify(this.group))
      this.edit = false
    },

    async saveGroupMembers (groupData) {
      this.edit = false
      const response = await Api.Groups.updateMembers(this.group.group_id, groupData)
      if (response) {
        this.$toast.success('The group was successfully updated.');
        this.getGroup()
        return
      }
      this.$toast.error('Something went wrong. Please try again.');
    },

    async saveGroup (groupData) {
      this.edit = false
      const response = await Api.Groups.update(this.group.group_id, groupData)
      if (response) {
        this.$toast.success('The group was successfully updated.');
        this.getGroup()
        return
      }
      this.$toast.error('Something went wrong. Please try again.');
    }
  }
}
</script>

<style lang="scss">
</style>
