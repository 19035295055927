<template>
  <v-container>
    <v-row>
      <v-col sm="12" class="pb-0">
        <div class="title">
          <h2 class="text-h2 font-weight-regular">Reports</h2>
        </div>

        <v-tabs>
          <v-tab 
            v-for="tab in cleanedTabs"
            :key="tab.id"
            @click="currentTab=tab;updateReport();"
          >
            {{tab.title}}
          </v-tab>
        </v-tabs>

        <div class="mt-5" v-if="currentTab.id">
          <v-form @click.prevent="updateReport" style="display:flex;gap:20px;">
            <v-select
              :items="[{name:'All',centre_id:-1}].concat(centres)"
              item-text="name"
              item-value="centre_id"
              v-model="params.centre_id"
              label="Centre"
              outlined
              v-if="ownUser.roles[0].title!=='Coordinator'"
            ></v-select>
            <v-text-field
              type="date"
              v-model="params.start_date"
              label="Start Date"
              outlined
              prepend-inner-icon="mdi-calendar"
            />
            <v-text-field
              type="date"
              v-model="params.end_date"
              label="End Date"
              outlined
              prepend-inner-icon="mdi-calendar"
            />
            <v-btn
              color="primary"
              @click="updateReport"
              x-large
              style="height:56px;"
            >
              <v-icon class="pr-2">mdi-refresh</v-icon> Refresh
            </v-btn>
          </v-form>

          <v-alert class="mt-5" type="info" v-if="!iframeLoading && currentTab.message">
            {{currentTab.message}} 
          </v-alert>

          <v-progress-circular
            v-if="iframeLoading"
            :size="50"
            color="primary"
            indeterminate
            class="mt-5"
            style="width:100%;"
          ></v-progress-circular>
          <iframe
            id="reportFrame"
            :src="iframeUrl"
            frameborder="0"
            allowtransparency
            @load="iframeLoading=false"
            :style="iframeLoading?'display:none;':''"
          ></iframe>
        </div>

        <div class="mt-5" v-if="currentTab.title==='Ministry'">
          <v-row>
            <v-col cols="2">
              <v-text-field
                type="date"
                v-model="params.start_date"
                label="Start Date"
                outlined
                prepend-inner-icon="mdi-calendar"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                type="date"
                v-model="params.end_date"
                label="End Date"
                outlined
                prepend-inner-icon="mdi-calendar"
              />
            </v-col>
            <v-col cols="2">
              <v-btn
                color="primary"
                @click="downloadMinistry"
                x-large
                style="height:56px;"
                :loading="ministryLoading"
                :disabled="ministryLoading"
              >
                <v-icon class="pr-2">mdi-cloud-download-outline</v-icon> Download
              </v-btn>
            </v-col>
          </v-row>
        </div>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import fiscalDates from '@/helpers/fiscalDates'
import getEnv from '@/helpers/getEnv'
var jwt = require("jsonwebtoken")

export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      fiscalDates: false,

      getEnv: getEnv(),
      metabaseUrl: getEnv().VUE_APP_METABASE_URL,
      metabaseKey: getEnv().VUE_APP_METABASE_KEY,
      allTabs: [
        {
          title: 'Clients',
          id: getEnv().VUE_APP_METABASE_DASHBOARDS.clients,
          message: 'All information displayed is based on plans that are open anytime during the selected date range.',
          permission: ['Admin', 'Coordinator', 'Reporter']
        },
        {
          title: 'Client Interactions',
          id: getEnv().VUE_APP_METABASE_DASHBOARDS.client_interactions,
          message: 'All information displayed is from the selected date range.',
          permission: ['Admin', 'Coordinator', 'Reporter']
        },
        {
          title: 'Plans',
          id: getEnv().VUE_APP_METABASE_DASHBOARDS.plans,
          message: 'All information displayed is based on plans that are open anytime during the selected date range.',
          permission: ['Admin', 'Coordinator', 'Reporter']
        },
        {
          title: 'Outcomes',
          id: getEnv().VUE_APP_METABASE_DASHBOARDS.outcomes,
          message: 'All information displayed is based on plans that are open anytime during the selected date range.',
          permission: ['Admin', 'Coordinator', 'Reporter']
        },
        {
          title: 'Supports & Trainings',
          id: getEnv().VUE_APP_METABASE_DASHBOARDS.supports_trainings,
          message: 'All information displayed is based on plans that are open anytime during the selected date range.',
          permission: ['Admin', 'Coordinator', 'Reporter']
        },
        {
          title: 'Stories',
          id: getEnv().VUE_APP_METABASE_DASHBOARDS.stories,
          message: 'All information displayed is from the selected date range.',
          permission: ['Admin', 'Coordinator', 'Reporter']
        },
        {
          title: 'Ministry',
          id: null,
          message: null,
          permission: ['Admin']
        }
      ],
      currentTab: null,

      centres: false,
      iframeUrl: '',
      iframeLoading: true,
      ministryLoading: false,
      params: {
        start_date: null,
        end_date: null,
        centre_id: -1,
      },
      ownUser: null
    }
  },

  async created () {
    this.ownUser = this.$store.getters.getUser
    this.currentTab = this.cleanedTabs[0]
    if(this.ownUser.roles[0].title!=='Coordinator'){
      this.centres = await Api.Centres.getAll()
    } else {
      this.params.centre_id = this.ownUser.centre.centre_id
    }
    this.fiscalDates = await fiscalDates
    this.params.start_date = this.fiscalDates.getFiscalDates().start
    this.params.end_date = this.fiscalDates.getFiscalDates().end
    this.updateReport()
  },

  methods: {
    updateReport () {
      this.loading = true
      var payload = {
        resource: { dashboard: this.currentTab.id },
        params: {
          "start_date": String(this.params.start_date),
          "end_date": String(this.params.end_date),
          "centre_id": String(this.params.centre_id)
        },
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
      };
      var token = jwt.sign(payload, this.metabaseKey)

      this.iframeUrl = this.metabaseUrl + '/embed/dashboard/' + token + '#bordered=false&titled=false'
    },
    async downloadMinistry () {
      this.ministryLoading = true
      const response = await Api.Reporting.getMinistryReport(this.params.start_date, this.params.end_date)
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `ministryreport_${this.params.start_date}_${this.params.end_date}.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();

      this.ministryLoading = false
    }
  },

  computed: {
    cleanedTabs () {
      if(!this.ownUser) return
      let cleaned = []
      this.allTabs.forEach((tab, i) => {
        if (!tab.permission.includes(this.ownUser.roles[0].title)) return
        cleaned.push(tab)
      })
      return cleaned
    }
  }
}
</script>

<style lang="scss">
#reportFrame {
  width: 100%;
  min-height: 80vh;
}
</style>
