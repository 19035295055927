/* eslint-disable dot-notation */
import axios from 'axios'

const connection = axios.create()

connection.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json'
  config.headers['Authorization'] = 'Basic ' + process.env.VUE_APP_JIRA_KEY
  return config
}, (err) => {
  console.error('Error Sending: ' + err.message)
  return false
})

export default {
  async createIssue2 (ticketData) {
    try {
      ticketData = {
        fields: {
          project: {
            id: '10020' // project id => Test Project
          },
          issuetype: {
            id: '10025' // issue id => Default task
          },
          summary: ticketData.summary,
          description: ticketData.description
        }
      }
      const response = await connection.post('https://ursa-creative.atlassian.net/rest/api/2/issue', ticketData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }
}
