<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <div class="title d-flex align-center justify-space-between">
          <h2 class="text-h2 font-weight-regular">Centres</h2>
          <router-link :to="{name:'CentreProfileNewPage'}">
            <v-btn
              color="primary"
              elevation="0"
              large
              rounded
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
              New Centre
            </v-btn>
          </router-link>
        </div>

        <CentresTable :centres="centres" />

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import CentresTable from '@/components/Centres/CentresTable'

export default {
  name: 'Centres',
  components: {
    CentresTable
  },
  data () {
    return {
      centres: false
    }
  },

  async mounted() {
    this.centres = await Api.Centres.getAll()
  }
}
</script>

<style lang="scss">
.title {
  margin-bottom: 2.188rem;
}
</style>
