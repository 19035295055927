<template>
  <v-container>
    <v-row justify-md="space-between" justify="center">
      <v-col xl="5" lg="5" md="6" sm="8">

        <div class="title skeleton_loader" v-if="!user">
          <v-skeleton-loader height="30px" width="250px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
          <v-skeleton-loader height="60px" width="400px" type="image"></v-skeleton-loader>
        </div>

        <div class="title" v-if="user">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">{{user.roles[0].title}} Profile</h5>
          <h2 class="text-h2 font-weight-regular">
            {{user.first_name}} {{user.last_name}}
          </h2>
        </div>

        <div v-if="page=='profile'">
          <UserProfileView
            :user="user" 
            :ownAccount="ownAccount"
          />

          <FormActionButtons
            :buttons="[]"
            :customButton="{
              text: false,
              color: 'primary',
              label: 'Change Password',
              icon: 'mdi-lock-reset'
            }"
            v-if="(user && ownAccount) && ownAccount.user_id===user.user_id"
            @custom="editType='password';page='edit';"
          />
          <FormActionButtons
            :buttons="[]" 
            :customButton="{
              text: false,
              color: 'primary',
              label: 'Edit Info',
              icon: 'mdi-pencil'
            }"
            v-if="user && ownAccount"
            @custom="editType='info';page='edit';"
          />
          <FormActionButtons
            :buttons="[
              (ownAccount.user_id!==user.user_id?'delete':'')
            ]" 
            @delete="deleteModal=true"
          />

          <!-- <v-card
            class="mt-15"
            max-width="100%"
            v-if="user && ownAccount"
            elevation="0"
            style="border:1px solid #E0E0E0;padding:15px;"
          >
            <v-card-text>
              <div>If you've lost one of your devices or notice any suspicious activity, you can log out of all your devices. We highly recommend you aslo change your password.</div>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="red darken-2"
                text
                small
                @click="forceMassLogout"
              >
                Logout from all devices
              </v-btn>
            </v-card-actions>
          </v-card> -->
        </div>

        <UserProfileEditForm
          v-if="page=='edit'"
          :user="userEditing" 
          :ownAccount="ownAccount" 
          :editType="editType"
          @save="page='profile';refreshData()"
          @cancel="page='profile';resetData()" 
        />

        <DeleteModal
          v-if="deleteModal"
          title="Delete User"
          :text="`Are you sure you wish to delete the current user <b>${user.first_name} ${user.last_name}</b>?`"
          @confirm="deleteUserModalConfirm"
          @cancel="deleteUserModalCancel" />

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex">

        <div id="bg-block"></div>
        <div id="bg-image"><InlineSvg src="/images/svgs/undraw_mobile_user.svg" /></div>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import UserProfileView from '@/components/Users/UserProfileView.vue'
import UserProfileEditForm from '@/components/Users/UserProfileEditForm.vue'
import DeleteModal from '@/components/elements/DeleteModal.vue'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  name: 'UserProfilePage',
  components: {
    UserProfileView,
    UserProfileEditForm,
    DeleteModal,
    FormActionButtons
  },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      page: 'profile',
      editType: '',
      ownAccount: false,
      user: false,
      userEditing: false,
      deleteModal: false
    }
  },
  
  async created() {
    await this.refreshData()
    this.ownAccount = this.$store.getters.getUser
  },
  methods: {
    async refreshData () {
      this.user = await Api.Users.getSingle(this.userId)
      if (this.user.centre === null) {
        this.user.centre = {
          centre_id: -1,
          name: 'None'
        }
      }
      if (this.ownAccount.user_id === this.user.user_id) {
        this.$store.dispatch('setUser', this.user)
      }
      this.userEditing = Object.assign({}, this.user)
    },
    resetData () {
      this.userEditing = Object.assign({}, this.user)
    },

    async deleteUserModalConfirm () {
      this.deleteModal = false
      const response = await Api.Users.delete(this.user.user_id)
      if (response) {
        this.$toast.success('The user was successfully deleted.');
        this.$router.push({name:'UsersPage'})
        return
      }
      this.$toast.error('Something went wrong. Please try again.');
    },
    deleteUserModalCancel () {
      this.deleteModal = false
    },

    async forceMassLogout () {
      this.$toast.warning('This function is not implimented yet.');
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 2.188rem;
}
.actions {
  margin-top: 1.875rem;
  button {
    margin-right: 20px;
  }
}
</style>
