<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Active</v-tab>
      <v-tab>Disabled</v-tab>
    </v-tabs>

    <div class="skeleton_loader" v-if="!tableItems">
      <v-skeleton-loader 
        type="table-thead, table-tbody, table-tfoot">
      </v-skeleton-loader>
    </div>

    <v-data-table
      v-if="tableItems"
      :headers="tableHeaders"
      :items="tableItems"
      sort-by="name"
    >
      <template v-slot:[`item.name`]="{ item }">
        <router-link 
          :to="{name:'MappingIndividualPage',params:{category:category,id:item[tableKey]}}"
          v-if="item.name!=='Other'"
        >
          {{item.name}}
        </router-link>
        <span v-if="item.name==='Other'">{{item.name}}</span>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        {{item.description!==null?(item.description.length>50?item.description.substring(0,47)+'...':item.description):'-'}}
      </template>
      <template v-slot:[`item.category`]="{ item }">
        {{item.category||'-'}}
      </template>
      <template v-slot:[`item.certificate`]="{ item }">
        {{item.certificate?'Yes':'No'}}
      </template>
      <template v-slot:[`item.reporting_type_name`]="{ item }">
        {{item.reporting_type_name!==null?(item.reporting_type_name.length>50?item.reporting_type_name.substring(0,47)+'...':item.reporting_type_name):'-'}}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'

export default {
  components: {
  },
  props: {
    category: {
      type: String,
      required: true,
    },
    items: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      tab: false,
    }
  },

  created () {
  },

  methods: {
  },

  computed: {
    tableItems() {
      if(!this.items.active) return false
      if (this.tab === 0){
        return this.items.active
      } else {
        return this.items.disabled
      }
    },
    tableKey() {
      if(!this.items.active) return
      switch (this.category) {
        case 'barriers':
          return 'barrier_id'
        case 'supports':
          return 'support_id'
        case 'trainings':
          return 'training_id'
      }
    },
    tableHeaders() {
      if(!this.items.active) return
      switch (this.category) {
        case 'barriers':
          return [
            { text: 'Name', value: 'name' },
            { text: 'Description', value: 'description' },
            { text: 'Category', value: 'category' },
          ]
        case 'supports':
          return [
            { text: 'Name', value: 'name' },
            { text: 'Description', value: 'description' },
            { text: 'Category', value: 'category' },
            { text: 'Mapping', value: 'reporting_type_name' },
          ]
        case 'trainings':
          return [
            { text: 'Name', value: 'name' },
            { text: 'Description', value: 'description' },
            { text: 'Certificate', value: 'certificate' },
            { text: 'Mapping', value: 'reporting_type_name' },
          ]
      }
    }
  }
}
</script>

<style lang="scss">
</style>
