<template>
  <div class="UserProfileNewForm" v-if="rules && dropdowns.centres && dropdowns.roles">

    <v-form 
      ref="UserProfileNewForm"
      @submit.prevent="createUser" 
      novalidate
    >

      <v-text-field
        id="user_first_name"
        type="text"
        v-model="user.first_name"
        label="First Name *"
        outlined
        :rules="[
          rules.required, 
          rules.maxLength(user.first_name,128)
        ]"
      ></v-text-field>

      <v-text-field
        id="user_last_name"
        type="text"
        v-model="user.last_name"
        label="Last Name *"
        outlined
        :rules="[
          rules.required, 
          rules.maxLength(user.first_name,128)
        ]"
      ></v-text-field>

      <v-text-field
        id="user_email"
        type="email"
        v-model="user.email"
        label="Email *"
        outlined
        :rules="[
          rules.required, 
          rules.maxLength(user.first_name,128),
          rules.isEmail
        ]"
      ></v-text-field>

      <v-select
        id="user_centre"
        :items="[{name:'None',centre_id:-1}].concat(dropdowns.centres)"
        item-text="name"
        item-value="centre_id"
        v-model="user.centre.centre_id"
        label="Centre *"
        outlined
        :rules="[
          rules.required
        ]"
      ></v-select>

      <v-select
        id="user_role"
        :items="dropdowns.roles"
        item-text="title"
        item-value="role_id"
        v-model="user.roles[0].role_id"
        label="Role *"
        outlined
        :rules="[
          rules.required
        ]"
      ></v-select>

      <FormActionButtons
        :buttons="[
          'cancel',
          'save'
        ]" 
        noTopPadding
        @cancel="$router.push({name:'UsersPage'})"
        @save="createUser"
      />

    </v-form>

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  name: 'UserProfileNewForm',
  components: {
    FormActionButtons
  },
  data () {
    return {
      rules: false,
      dropdowns: {
        centres: false,
        roles: false
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        centre: {
          centre_id: '',
        },
        roles: [
          { role_id: ''} 
        ]
      },
    }
  },
  
  async mounted() {
    this.dropdowns.centres = await Api.Centres.getAll()
    this.dropdowns.roles = await Api.Roles.getAll()
    this.rules = await validationRules
  },

  methods: {
    async createUser() {
      if( !this.$refs.UserProfileNewForm.validate() ) return

      // check if a centre was selected
      if (this.user.centre.centre_id===-1) {
        this.user.centre = null
      }

      const response = await Api.Users.create(this.user)
      if (response) {
        this.$toast.success('The user was successfully created');
        this.$router.push({name:'UsersPage'})
        return
      }
      this.$toast.error('Something went wrong. Please try again.');
    }
  }
}
</script>

<style scoped lang="scss">
</style>
