var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify-md":"space-between","justify":"center"}},[_c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"title"},[_c('h5',{staticClass:"text-h5 font-weight-light grey--text text--darken-1"},[_vm._v("Client Profile")]),_c('h2',{staticClass:"text-h2 font-weight-regular"},[(!_vm.client)?_c('v-skeleton-loader',{staticClass:"skeleton_loader",attrs:{"height":"60px","width":"400px","type":"image"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.client.first_name)+" "+_vm._s(_vm.client.last_name)+" ")],1)]),_c('v-tabs',{staticClass:"mb-0",attrs:{"show-arrows":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_c('v-tab',{on:{"click":function($event){_vm.edit=false;_vm.resetData()}}},[_vm._v("Client")]),_c('v-tab',{on:{"click":function($event){_vm.edit=false;_vm.resetData()}}},[_vm._v("Demographic")]),_c('v-tab',{on:{"click":function($event){_vm.edit=false;_vm.resetData()}}},[_vm._v("Ancestry")]),_c('v-tab',{on:{"click":function($event){_vm.edit=false;_vm.resetData()}}},[_vm._v("Client Interactions")])],1)],1),(_vm.page==0)?_c('v-col',{attrs:{"sm":"12","md":"7","lg":"5"}},[(!_vm.edit)?_c('ClientProfileView',{attrs:{"client":_vm.client,"page":"client"},on:{"edit":function($event){_vm.edit=true}}}):_vm._e(),(_vm.edit && _vm.client)?_c('ClientProfileEditForm',{attrs:{"page":"client","client":_vm.clientEditing},on:{"save":function($event){_vm.edit=false;_vm.refreshData()},"cancel":function($event){_vm.edit=false;_vm.resetData()}}}):_vm._e(),(!_vm.edit && _vm.client)?_c('FormActionButtons',{attrs:{"buttons":[
          'edit',
          'delete'
        ],"loading":_vm.loading},on:{"edit":function($event){_vm.edit=true},"delete":function($event){_vm.deleteModal=true}}}):_vm._e()],1):_vm._e(),(_vm.page==1)?_c('v-col',{attrs:{"sm":"12","md":"7","lg":"5"}},[(!_vm.edit)?_c('ClientProfileView',{attrs:{"client":_vm.client,"page":"demographic"},on:{"edit":function($event){_vm.edit=true}}}):_vm._e(),(_vm.edit)?_c('ClientProfileEditForm',{attrs:{"page":"demographic","client":_vm.clientEditing},on:{"save":function($event){_vm.edit=false;_vm.refreshData()},"cancel":function($event){_vm.edit=false;_vm.resetData()}}}):_vm._e(),(!_vm.edit)?_c('FormActionButtons',{attrs:{"buttons":[
          'edit'
        ]},on:{"edit":function($event){_vm.edit=true}}}):_vm._e()],1):_vm._e(),(_vm.page==2)?_c('v-col',{attrs:{"sm":"12","md":"7","lg":"5"}},[(!_vm.edit)?_c('ClientProfileView',{attrs:{"client":_vm.client,"page":"ancestry"},on:{"edit":function($event){_vm.edit=true}}}):_vm._e(),(_vm.edit)?_c('ClientProfileEditForm',{attrs:{"page":"ancestry","client":_vm.clientEditing},on:{"save":function($event){_vm.edit=false;_vm.refreshData()},"cancel":function($event){_vm.edit=false;_vm.resetData()}}}):_vm._e(),(!_vm.edit)?_c('FormActionButtons',{attrs:{"buttons":[
          'edit'
        ]},on:{"edit":function($event){_vm.edit=true}}}):_vm._e()],1):_vm._e(),(_vm.page==3)?_c('v-col',{attrs:{"sm":"12"}},[_c('ClientInteractionsPage',{attrs:{"client":_vm.client}})],1):_vm._e(),(_vm.page==4)?_c('v-col',{attrs:{"sm":"12"}},[_vm._v(" ATTACHMENTS WILL GO HERE ")]):_vm._e(),(_vm.deleteModal)?_c('DeleteModal',{attrs:{"title":"Delete Client","text":("Are you sure you wish to delete the current client <b>" + (_vm.client.first_name) + " " + (_vm.client.last_name) + "</b>?")},on:{"confirm":_vm.deleteClientModalConfirm,"cancel":_vm.deleteClientModalCancel}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }