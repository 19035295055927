<template>
  <v-dialog
    v-model="dialog"
    width="500"
    @click:outside="cancel"
  ><!-- allClients rules interactions -->
    <v-card>
      <v-card-title class="text-h5 font-weight-medium">
        <v-icon color="blue darken-2">mdi-account</v-icon>
        Add Client Interaction
      </v-card-title>
      
      <ClientInteractionElement
        :client="client"
        @close="close"
      />

    </v-card>
  </v-dialog>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import ClientInteractionElement from '@/components/elements/ClientInteractionElement.vue'

export default {
  components: {
    ClientInteractionElement
  },
  props: {
    client: {
      type: Number,
      required: false,
      default: -1
    }
  },
  data() {
    return {
      dialog: true,
    }
  },
  async created () {
  },

  methods: {
    close () {
      this.$emit('close')
    }      
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.v-card__title span {
  margin-left: 5px;
}
</style>