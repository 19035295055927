import connection from '../connection'
// import store from '../../store'

async function sendCleanOutcome (outcome) {

  if (outcome.program_outcome === 'Complete') {
    outcome.incomplete_reason = null
  }
  if (
    outcome.post_program_status !== 'Employed' &&
    outcome.post_program_status !== 'Self-employed'
  ) {
    outcome.employment_type = null
    outcome.job_title = null
  }

  return outcome
}

export default {

  async getFromPlan (planId) {
    try {
      const response = await connection.get(`/plans/${planId}/outcomes`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (outcomeId, outcomeData) {
    try {
      outcomeData = await sendCleanOutcome(outcomeData)
      const response = await connection.put(`/outcomes/${outcomeId}`, outcomeData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (outcomeData) {
    try {
      delete outcomeData.outcome_id
      outcomeData = await sendCleanOutcome(outcomeData)
      const response = await connection.post('/outcomes', outcomeData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (outcomeId) {
    try {
      const response = await connection.delete(`/outcomes/${outcomeId}`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

