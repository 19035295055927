import connection from '../connection'
import store from '../../store'

function sendCleanBarriers (data) {
  let cleaned = {}
  cleaned.name = data.name
  cleaned.description = data.description
  cleaned.category = data.category
  cleaned.disabled = data.disabled || false
  cleaned.reporting_type = data.reporting_type

  // convert all empty strings to nulls
  cleaned = Object.keys(cleaned).reduce((acc, key) => { acc[key] = cleaned[key] === '' ? null : cleaned[key]; return acc }, {})

  return cleaned
}

export default {

  async getAll () {
    try {
      const cache = store.getters.getBarriers
      if (cache && cache.data) {
        return cache.data
      }

      // reset cache
      const response = await connection.get('/barriers')
      if (!response) return false
      const sorted = response.data.sort((a, b) => (a.name < b.name) ? -1 : 1)
      store.dispatch('setBarriers', sorted)
      return sorted
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getAllDisabled () {
    try {
      const response = await connection.get('/barriers?disabled=true')
      if (!response) return false
      const sorted = response.data.sort((a, b) => (a.name < b.name) ? -1 : 1)
      return sorted
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getSingle (barrierId) {
    try {
      const response = await connection.get(`/barriers/${barrierId}`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (barrierId, barrierData) {
    try {
      barrierData = sendCleanBarriers(barrierData)
      const response = await connection.put(`/barriers/${barrierId}`, barrierData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (barrierData) {
    try {
      barrierData = sendCleanBarriers(barrierData)
      const response = await connection.post('/barriers/', barrierData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (barrierId, barrierData) {
    try {
      barrierData = sendCleanBarriers(barrierData)
      barrierData.disabled = true
      const response = await connection.put('/barriers/' + barrierId, barrierData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async restore (barrierId, barrierData) {
    try {
      barrierData = sendCleanBarriers(barrierData)
      barrierData.disabled = false
      const response = await connection.put('/barriers/' + barrierId, barrierData)
      if (!response) return false
      store.dispatch('clearCache')
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getFromPlan (planId) {
    try {
      const response = await connection.get(`/plans/${planId}/barriers`)
      if (!response) return false

      const cleaned = response.data.map((row) => {
        return {
          ...row.barrier,
          other_name: row.other_name
        }
      })
      return cleaned
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async updateToPlan (planId, barriers) {
    try {
      const cleanedBarriers = barriers.map((row) => {
        return {
          barrier_id: row.barrier_id,
          other_name: row.other_name
        }
      })
      const response = await connection.put(`/plans/${planId}/barriers`, cleanedBarriers)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

