<template>

  <v-container class="pa-0">
    <v-row class="mt-15 mb-10" justify-md="space-between" justify="center" style="max-height:600px;">
      <v-col xl="6" lg="6" class="d-none d-md-flex" align-self="center">
        <div id="bg-block" style="height:600px;"></div>
        <InlineSvg 
          src="/images/svgs/undraw_chat.svg" 
          width="100%"
        />
      </v-col>
      <v-col xl="5" lg="5" md="6" sm="8" align-self="center">
        <div class="title">
          <h2 class="text-h2 font-weight-regular mb-4">Can't find what you're looking for?</h2>
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">
            You can email us and we will reach out as soon as possible.
            <br/>
            <a href="mailto:elst-support@ursa-creative.atlassian.net">elst-support@ursa-creative.atlassian.net</a>
          </h5>
          <!-- <v-btn
            color="primary"
            class="mt-5"
            elevation="0"
            :to="{name:'OpenTicket'}"
          >Submit a ticket</v-btn> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
