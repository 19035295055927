var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user && _vm.rules && _vm.dropdowns.centres && _vm.dropdowns.roles)?_c('div',{staticClass:"UserProfileEditForm"},[_c('v-form',{ref:"UserProfileEditForm",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}}},[(_vm.editType==='info')?_c('div',[_c('v-text-field',{attrs:{"type":"text","label":"First Name *","outlined":"","rules":[
          _vm.rules.required, 
          _vm.rules.maxLength(_vm.user.first_name,64)
        ]},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),_c('v-text-field',{attrs:{"type":"text","label":"Last Name *","outlined":"","rules":[
          _vm.rules.required, 
          _vm.rules.maxLength(_vm.user.first_name,64)
        ]},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('v-text-field',{attrs:{"type":"email","label":"Email *","outlined":"","rules":[
          _vm.rules.required, 
          _vm.rules.maxLength(_vm.user.first_name,128),
          _vm.rules.isEmail
        ]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),(_vm.ownAccount.user_id!==_vm.user.user_id)?_c('div',[_c('v-select',{attrs:{"items":[{name:'None',centre_id:-1}].concat(_vm.dropdowns.centres),"item-text":"name","item-value":"centre_id","label":"Centre *","outlined":"","rules":[
            _vm.rules.required
          ]},model:{value:(_vm.user.centre.centre_id),callback:function ($$v) {_vm.$set(_vm.user.centre, "centre_id", $$v)},expression:"user.centre.centre_id"}}),_c('v-select',{attrs:{"items":_vm.dropdowns.roles,"item-text":"title","item-value":"role_id","label":"Role *","outlined":"","rules":[
            _vm.rules.required
          ]},model:{value:(_vm.user.roles[0].role_id),callback:function ($$v) {_vm.$set(_vm.user.roles[0], "role_id", $$v)},expression:"user.roles[0].role_id"}})],1):_vm._e()],1):_vm._e(),(_vm.ownAccount.user_id===_vm.user.user_id && _vm.editType==='password')?_c('div',[(_vm.user && _vm.ownAccount)?_c('v-card',{staticClass:"mb-8",staticStyle:{"border":"1px solid #E0E0E0","padding":"0px"},attrs:{"max-width":"100%","elevation":"0"}},[_c('v-card-text',[_c('span',{staticClass:"text-body-1 font-weight-bold"},[_vm._v("New Password Requirements")]),_c('div',{staticClass:"text-body-1"},[_c('div',[(_vm.passwordScore.checks.length)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check")]):_vm._e(),(!_vm.passwordScore.checks.length)?_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-close")]):_vm._e(),_vm._v(" Must be at least 8 characters ")],1),_c('div',[(_vm.passwordScore.checks.uppercase)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check")]):_vm._e(),(!_vm.passwordScore.checks.uppercase)?_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-close")]):_vm._e(),_vm._v(" Must contain at least one uppercase letter ")],1),_c('div',[(_vm.passwordScore.checks.lowercase)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check")]):_vm._e(),(!_vm.passwordScore.checks.lowercase)?_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-close")]):_vm._e(),_vm._v(" Must contain at least one lowercase letter ")],1),_c('div',[(_vm.passwordScore.checks.number)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check")]):_vm._e(),(!_vm.passwordScore.checks.number)?_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-close")]):_vm._e(),_vm._v(" Must contain at least one number ")],1)])])],1):_vm._e(),_c('v-text-field',{attrs:{"type":"password","label":"New Password","outlined":"","loading":"","rules":[
          _vm.rules.checkPasswordStrength(_vm.newPassword.new).pass
        ]},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"value":_vm.passwordScore.score*20,"color":['red','orange','amber','light-green','green'][_vm.passwordScore.score-1],"absolute":"","height":"7"}})]},proxy:true}],null,false,3084180301),model:{value:(_vm.newPassword.new),callback:function ($$v) {_vm.$set(_vm.newPassword, "new", $$v)},expression:"newPassword.new"}}),_c('v-text-field',{attrs:{"type":"password","label":"Confirm Password","outlined":"","rules":[
          _vm.rules.mustMatch(_vm.newPassword.confirm, _vm.newPassword.new, 'Passwords must match')
        ]},model:{value:(_vm.newPassword.confirm),callback:function ($$v) {_vm.$set(_vm.newPassword, "confirm", $$v)},expression:"newPassword.confirm"}})],1):_vm._e(),_c('FormActionButtons',{attrs:{"buttons":[
        'cancel',
        'save'
      ],"noTopPadding":""},on:{"cancel":function($event){return _vm.$emit('cancel')},"save":_vm.saveUser}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }