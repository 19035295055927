<template>
  <div class="NewPasswordForm" v-if="rules">

    <div 
      class="text-center"
      v-if="requestSent">
      <div class="img-holder">
        <img src="/images/bcaafc.png" />
      </div>
      <div class="text-h3">Success</div>
      <br/>
      <p>Your password was successfully changed! You can now return to the login page and sign-in with your new password.</p>

      <router-link :to="{name:'LoginPage'}">
        <v-btn 
          type="submit"
          color="primary"
          block
          x-large
          elevation="0"
        >
          Return to login <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </router-link>
    </div>

    <v-form 
      ref="form"
      @submit.prevent="setNewPassword" 
      novalidate
      v-if="!requestSent"
    >
      <div class="img-holder">
        <img src="/images/bcaafc.png" />
      </div>
      <div class="text-h3">Set New Password</div>

      <v-card
        class="mb-3 mt-4"
        max-width="100%"
        elevation="0"
        style="border:1px solid #E0E0E0;padding:0px;"
      >
        <v-card-text class="text-body-1">
          <span class="font-weight-bold">New Password Requirements</span>
          <div>
            <v-icon color="green" small v-if="passwordScore.checks.length">mdi-check</v-icon> 
            <v-icon color="red" small v-if="!passwordScore.checks.length">mdi-close</v-icon> 
            Must be at least 8 characters
          </div>
          <div>
            <v-icon color="green" small v-if="passwordScore.checks.uppercase">mdi-check</v-icon> 
            <v-icon color="red" small v-if="!passwordScore.checks.uppercase">mdi-close</v-icon> 
            Must contain at least one uppercase letter
          </div>
          <div>
            <v-icon color="green" small v-if="passwordScore.checks.lowercase">mdi-check</v-icon> 
            <v-icon color="red" small v-if="!passwordScore.checks.lowercase">mdi-close</v-icon> 
            Must contain at least one lowercase letter
          </div>
          <div>
            <v-icon color="green" small v-if="passwordScore.checks.number">mdi-check</v-icon> 
            <v-icon color="red" small v-if="!passwordScore.checks.number">mdi-close</v-icon> 
            Must contain at least one number
          </div>
        </v-card-text>
      </v-card>

      <v-text-field
        type="password"
        v-model="newPasswordOrg"
        label="New Password *"
        class="pb-3"
        outlined
        loading
        hide-details
        :rules="[
          rules.checkPasswordStrength(newPasswordOrg).pass
        ]"
      >
        <template v-slot:progress>
          <v-progress-linear
            :value="passwordScore.score*20"
            :color="['red','orange','amber','light-green','green'][passwordScore.score-1]"
            absolute
            height="7"
          ></v-progress-linear>
        </template>
      </v-text-field>

      <v-text-field
        type="password"
        v-model="newPasswordCon"
        label="Confirm Password *"
        outlined
        :rules="[
          rules.required(newPasswordCon, 'Please confirm the password'),
          rules.mustMatch(newPasswordCon, newPasswordOrg, 'Passwords must match')
        ]"
      ></v-text-field>

      <v-btn 
        type="submit"
        color="primary"
        block
        :loading="loading"
        :disabled="loading"
        x-large
        elevation="0"
      >
        Set New Password <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-form>

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'

export default {
  name: 'NewPasswordForm',
  created() {
    this.rules = validationRules
  },
  props: {
    token: {
      type: String,
      default: null,
      required: true
    },
    id: {
      type: String,
      default: null,
      required: true
    }
  },

  data() {
    return {
      rules: false,
      requestSent: false,
      newPasswordOrg: '',
      newPasswordCon: '',
      loading: false,
    }
  },

  methods: {
    async setNewPassword(e){
      if( !this.$refs.form.validate() ) return
      this.loading = true

      const response = await Api.Auth.updatePassword(this.newPasswordOrg, this.token, this.id)
      if (response) {
        this.loading = false
        this.requestSent = true
        return
      }

    }
  },
  computed: {
    passwordScore() { 
      return this.rules.checkPasswordStrength(this.newPasswordOrg)
    }
  }
}
</script>

<style scoped lang="scss">
.v-progress-linear {
  margin-top: -6px;
  z-index: -1;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.img-holder {
  text-align: center;

  img {
    width: 80%;
    max-width: 200px;
    margin-bottom: 0px;
  }
}
</style>
