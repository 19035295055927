<template>
  <div class="CentreProfileEditForm" v-if="centre && rules && provinces">

    <v-form 
      ref="CentreProfileEditForm"
      @submit.prevent="saveCentre" 
      novalidate
    >

      <v-text-field
        type="text"
        v-model="centre.name"
        label="Centre Name *"
        outlined
        :rules="[
          rules.required,
          rules.maxLength(centre.name, 128)
        ]"
      ></v-text-field>

      <v-text-field
        type="text"
        v-model="centre.address"
        label="Street Address"
        outlined
        :rules="[
          rules.maxLength(centre.address, 128)
        ]"
      ></v-text-field>

      <v-text-field
        type="text"
        v-model="centre.city"
        label="City"
        outlined
        :rules="[
          rules.maxLength(centre.city, 64)
        ]"
      ></v-text-field>

      <v-row>
        <v-col cols="6">
          <v-select
            :items="provinces"
            item-text="name"
            item-value="abbr"
            v-model="centre.province"
            label="Province"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            type="text"
            v-model="centre.postal_code"
            label="Postal Code"
            outlined
            :rules="[
              rules.noSpaces,
              rules.isPostalCode
            ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-text-field
        type="text"
        v-model="centre.phone"
        label="Phone Number *"
        outlined
        :rules="[
          rules.required,
          rules.maxLength(centre.phone,15)
        ]"
      ></v-text-field>

      <!-- <v-file-input
        show-size
        outlined
        hint="Image must be under 70 MB"
        label="Centre Logo"
        @change="fileUpload"
        :rules="[
          rules.maxImageSize(imageSize)
        ]"
      ></v-file-input> -->

      <FormActionButtons
        :buttons="[
          'cancel',
          'save'
        ]" 
        noTopPadding
        @cancel="$emit('cancel')"
        @save="saveCentre"
      />
    </v-form>

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'
import Provinces from '@/helpers/provinces'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  name: 'CentreProfileEditForm',
  components: {
    FormActionButtons
  },
  props: {
    centre: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules: false,
      provinces: false,
      imageBase64: '',
      imageSize: 0,
    }
  },
  
  async created() {
    this.rules = await validationRules
    this.provinces = await Provinces
  },

  methods: {
    async saveCentre() {
      if( !this.$refs.CentreProfileEditForm.validate() ) return

      this.centre.logo = this.imageBase64

      // save profile
      const response = await Api.Centres.update(this.centre.centre_id, this.centre)
      if (response) {
        this.$toast.success('The centre was successfully updated.');
        this.$emit('save')
        return
      }
      this.$toast.error('Something went wrong. Please try again.');
    },
    fileUpload (e) {
      this.imageSize = e.size
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64 = reader.result
        this.imageBase64 = base64
      }
      reader.readAsDataURL(e)
    }
  }
}
</script>

<style scoped lang="scss">
.actions {
  // margin-top: 1.875rem;
  button {
    margin-right: 20px;
  }
}
</style>