<template>
  <div>

    <v-container v-if="selectedSpendings" style="padding:0;">
      <v-row>
        <v-col sm="12" md="6" lg="4" v-if="spendingEdit">
          <p class="text-h5">{{ selectedSpendings.spending_id===-1 ? 'New Line Item' : 'Edit Line Item' }}</p>
          
          <v-form 
            ref="form"
            @submit.prevent="saveSpending" 
            novalidate
          >

            <v-text-field
              type="date"
              v-model="selectedSpendings.date"
              label="Date *"
              outlined
              prepend-inner-icon="mdi-calendar"
              :rules="[
                rules.required,
                rules.noFutureDate
              ]"
            />

            <v-text-field
              type="text"
              v-model="selectedSpendings.item"
              label="Item *"
              outlined
              :rules="[
                rules.required, 
                rules.maxLength(selectedSpendings.item,64)
              ]"
            />

            <v-text-field
              type="text"
              v-model="selectedSpendings.value"
              label="Value"
              outlined
              prefix="$"
              :rules="[
                rules.isMoney
              ]"
            />

            <v-checkbox
              v-model="selectedSpendings.work_gear"
              label="This is a work gear item"
              class="mt-0"
            ></v-checkbox>

            <v-textarea
              type="text"
              v-model="selectedSpendings.description"
              label="Description"
              outlined
              rows="5"
              counter="1024"
              :rules="[
                rules.maxLength(selectedSpendings.description,1024)
              ]"
            />

            <FormActionButtons
              :buttons="[
                'cancel',
                'save'
              ]"
              :loading="loading"
              noTopPadding
              @cancel="cancelSpendingEdit"
              @save="saveSpending"
            />
          </v-form>
        </v-col>
        <v-col sm="12" md="6" lg="4" v-if="!spendingEdit">

          <FormActionButtons
            :buttons="[]" 
            :customButton="{
              text: true,
              color: 'secondary',
              label: 'Back to spendings' ,
              icon: 'mdi-chevron-left',
              icon_start: true
            }"
            noPadding
            @custom="selectedSpendings=false"
          />
          <div class="pb-5"></div>


          <label class="text-body-2 grey--text text--darken-1">Item</label>
          <p class="text-body-1">{{selectedSpendings.item}}</p>

          <label class="text-body-2 grey--text text--darken-1">Date</label>
          <p class="text-body-1">{{ moment(selectedSpendings.date).format('MMMM D, YYYY') }}</p>

          <label class="text-body-2 grey--text text--darken-1">Value</label>
          <p class="text-body-1">{{formatMoney(selectedSpendings.value)}}</p>

          <label class="text-body-2 grey--text text--darken-1">This is a work gear item</label>
          <p class="text-body-1">{{ selectedSpendings.work_gear?'Yes':'No' }}</p>

          <label class="text-body-2 grey--text text--darken-1">Description</label>
          <p class="text-body-1">{{selectedSpendings.description || '-'}}</p>

          <FormActionButtons
            :buttons="[
              'edit',
              'delete'
            ]"
            :loading="loading"
            @edit="spendingEdit=true"
            @delete="deleteSpending"
          />

        </v-col>
      </v-row>
    </v-container>

    <v-container class="skeleton_loader" v-if="!spendings" style="padding:0;">
      <v-row style="padding:0 16px;">
        <v-col sm="4" md="4" lg="3">
          <v-skeleton-loader height="28px" width="200px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
          <v-skeleton-loader height="24px" width="100px" type="image"></v-skeleton-loader>
        </v-col>
        <v-col sm="4" md="4" lg="3">
          <v-skeleton-loader height="28px" width="200px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
          <v-skeleton-loader height="24px" width="100px" type="image"></v-skeleton-loader>
        </v-col>
        <v-col sm="4" md="4" lg="6" style="text-align:right">
          <v-skeleton-loader height="36px" width="165px" type="image" style="float:right;"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader 
            
            type="table-thead, table-tbody, table-tfoot">
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="spendings && !selectedSpendings" style="padding:0;">
      <v-row style="padding:0 16px;">
        <v-col sm="4" md="4" lg="3">
          <p class="subtitle-1 font-weight-medium mb-0 pb-0">TOTAL CASE SPENDING</p>
          <p class="body-1 mb-0 pb-0">{{ formatMoney(caseTotal) }}</p>
        </v-col>
        <v-col sm="4" md="4" lg="3">
          <p class="subtitle-1 font-weight-medium mb-0 pb-0">TOTAL FISCAL SPENDING</p>
          <p class="body-1 mb-0 pb-0">{{ formatMoney(fiscalTotal) }}</p>
        </v-col>
        <v-col sm="4" md="4" lg="6" style="text-align:right">
          <FormActionButtons
            :buttons="[]" 
            :customButton="{
              text: false,
              color: 'primary',
              label: 'New Line Item' ,
              icon: 'mdi-plus'
            }"
            noPadding
            @custom="newSpending"
          />
          <!-- <FormActionButtons
            :buttons="[]" 
            :customButton="{
              text: false,
              color: 'primary',
              label: 'Add Item',
              icon: 'mdi-plus'
            }"
            noPadding
            @custom="newSpending"
          /> -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">

          <v-data-table
            :headers="tableHeaders"
            :items="spendings"
            sort-by="date"
            sort-desc
          >
            <template v-slot:[`item.item`]="{ item }">
              <a href="" @click.prevent="selectedSpendings=Object.assign({},item)">
                {{ item.item.length>32 ? item.item.substring(0,32)+"..." : item.item }}
              </a>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              {{ item.description ? (item.description.length>64 ? item.description.substring(0,64)+"..." : item.description) : '-' }}
            </template>
            <template v-slot:[`item.work_gear`]="{ item }">
              <v-icon>{{ item.work_gear ? 'mdi-check' : '' }}</v-icon>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              {{ moment(item.date).format('MMM D, YYYY') }}
            </template>
            <template v-slot:[`item.value`]="{ item }">
              {{ formatMoney(item.value) }}
            </template>
          </v-data-table>

        </v-col>
      </v-row>
    </v-container>

    <DeleteModal
      v-if="deleteModal"
      title="Delete Line Item"
      :text="`Are you sure you wish to delete the current line item?`"
      @confirm="deleteSpendingModalConfirm"
      @cancel="deleteSpendingModalCancel" 
    />

    <!-- <hr/>
    <pre>{{spendings}}</pre> -->

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'
import fiscalDates from '@/helpers/fiscalDates'

import DeleteModal from '@/components/elements/DeleteModal.vue'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
var moment = require('moment')

export default {
  components: {
    DeleteModal,
    FormActionButtons
  },
  props: {
    planId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      loading: false,
      rules: false,
      fiscalDates: false,
      deleteModal: false,
      spendings: false,
      spendingEdit: false,
      selectedSpendings: false,
      tableHeaders: [
        { text: 'Item', value: 'item' },
        { text: 'Description', value: 'description' },
        { text: 'Work Gear', value: 'work_gear' },
        { text: 'Date', value: 'date' },
        { text: 'Value', value: 'value' }
      ],
      caseTotal: 0,
      fiscalTotal: 0
    }
  },
  async created () { 
    this.rules = await validationRules
    this.fiscalDates = await fiscalDates
    this.refreshData()
  },
  methods: {
    async refreshData () {
      this.spendings = false
      this.spendingEdit = false
      this.selectedSpendings = false
      this.spendings = await Api.Spendings.getFromPlan(this.planId)
      this.calculateNewTotals()
    },

    calculateNewTotals () {
      this.caseTotal = 0
      this.fiscalTotal = 0
      if (this.spendings.length === 0) return false
      const range = this.fiscalDates.getFiscalDates()

      this.spendings.forEach((item,i) => {
        this.caseTotal += parseFloat(item.value)
        if (moment(item.date).isBetween(moment(range.start).add(1, 'd'), moment(range.end).subtract(1, 'd'))) {
          this.fiscalTotal += parseFloat(item.value)
        }
      })
    },

    formatMoney (amount) {
      let val = (amount/1).toFixed(2).replace(',', '.')
      return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    cancelSpendingEdit () {
      if (this.selectedSpendings.spending_id == -1) {
        this.selectedSpendings = false
      }
      this.spendingEdit = false
    },

    async saveSpending (selectedSpendings) {
      if( !this.$refs.form.validate() ) return
      this.loading = true

      this.selectedSpendings.plan_id = this.planId

      if (this.selectedSpendings.spending_id === -1){
        //create
        const response = await Api.Spendings.create(this.selectedSpendings)
        if (response) {
          this.loading = false
          this.refreshData()
          this.$toast.success('The line item was successfully created.')
          return
        }
      } else {
        //update
        const response = await Api.Spendings.update(this.selectedSpendings.spending_id, this.selectedSpendings)
        if (response) {
          this.loading = false
          this.refreshData()
          this.$toast.success('The line item was successfully updated.')
          return
        }
      }

      this.$toast.error('Something went wrong. Please try again.')
    },

    newSpending () {
      this.selectedSpendings = {
        spending_id: -1,
        item: '',
        value: '',
        description: '',
        date: moment().format('YYYY-MM-DD')
      }
      this.spendingEdit = true
    },

    deleteSpending () {
      this.deleteModal = true
    },
    async deleteSpendingModalConfirm () {
      this.deleteModal = false
      this.loading = true
      const response = await Api.Spendings.delete(this.selectedSpendings.spending_id, this.selectedSpendings)
      if (response) {
        this.loading = false
        this.refreshData()
        this.$toast.success('The line item was successfully deleted.')
        return
      }
      this.loading = false
      this.$toast.error('Something went wrong. Please try again.')
    },
    deleteSpendingModalCancel () {
      this.deleteModal = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
