<template>
  <v-container>
    <v-row justify-md="space-between" justify="center" align="center">

      <v-col xl="4" lg="5" md="6" sm="8" v-if="loading">

        <div class="text-center">
          <div class="img-holder">
            <img src="/images/bcaafc.png" />
          </div>
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            class="mt-5"
            style="width:100%;"
          ></v-progress-circular>
        </div>

      </v-col>
      
      <v-col xl="4" lg="5" md="6" sm="8" v-if="!loading">

        <div 
          class="text-center"
          v-if="!requestValid"
        >
          <div class="img-holder">
            <img src="/images/bcaafc.png" />
          </div>
          <div class="text-h3">Invalid link</div>
          <br/>
          <p>This link is either invalid or has expired. Please ensure the URL is correct or attempt resetting your password again.</p>

          <router-link :to="{name:'LoginPage'}">
            <v-btn 
              type="submit"
              color="primary"
              block
              x-large
            >
              Return to login <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </router-link>
        </div>

        <NewPasswordForm 
          v-if="requestValid" 
          :id="id"
          :token="token" />

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex">

        <div id="bg-block"></div>
        <div id="bg-image"><InlineSvg src="/images/svgs/undraw_authentication.svg" /></div>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import NewPasswordForm from '@/components/Login/NewPasswordForm.vue';

export default {
  name: 'ResetPasswordPage',
  components: {
    NewPasswordForm
  },
  data() {
    return {
      loading: true,
      requestValid: false,
      token: null,
      id: null
    }
  },
  async created () {
    this.token = this.$route.query.token
    this.id = this.$route.query.id
    this.checkPasswordToken()
  },
  methods: {
    async checkPasswordToken () {
      this.loading = true
      this.requestValid = false

      if (!this.token || !this.id) {
        this.loading = false
        this.requestValid = false
        return
      }

      const response = await Api.Auth.checkPasswordToken(this.token, this.id)
      if (response) {
        this.loading = false
        this.requestValid = true
        return
      } else {
        this.loading = false
        this.requestValid = false
        return
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  margin-top: -75px;
  margin-bottom: -50px;

  .row {
    min-height: 100vh;
  }
  .img-holder {
    text-align: center;

    img {
      width: 80%;
      max-width: 200px;
      margin-bottom: 0px;
    }
  }
}
</style>
