<template>
  <div>

    <DraggableColumns
      :settings="settings"
      :activeColumn="activeSupports"
      :masterColumn="masterSupports"

      :loading="loading"

      @save="saveSupports"
    />

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import DraggableColumns from '@/components/elements/DraggableColumns'

export default {
  name: 'PlanPageSupports',
  components: {
    DraggableColumns
  },
  props: {
    planId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      settings: {
        item: 'Support',
        arrayKey: 'support_id',
        arrayTitle: 'name',
        arrayCategory: 'category',
        arrayDescription: 'description',
        columnOneTitle: 'Plan Supports',
        columnTwoTitle: 'Add Supports',
        emptyStringView: 'There are no supports on this plan.',
        emptyStringEdit: 'There are no supports on this plan.<br/>You can add some from the right column.'
      },
      activeSupports: false,
      masterSupports: false,
      loading: false,
    }
  },
  created () { 
    this.refreshData()
  },
  methods: {
    async refreshData () {
      this.activeSupports = await Api.Supports.getFromPlan(this.planId)
      this.masterSupports = await Api.Supports.getAll()
    },

    async saveSupports (arrayOne) {
      this.loading = true
      this.activeSupports = arrayOne

      const response = await Api.Supports.updateToPlan(this.planId, this.activeSupports)
      if (response) {
        this.loading = false
        this.$toast.success('The plan was successfully updated.')
        return
      } else {
        this.loading = false
        this.$toast.error('Something went wrong. Please try again.')
        return
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">

</style>
