import connection from '../connection'
// import store from '../../store'

async function returnCleanSpending (spendings) {
  // if (Object.prototype.toString.call(clients) === '[object Object]') {
  //   clients = [clients]
  // }

  const cleaned = spendings.map((row) => {
    return {
      ...row,
      value: (row.value / 1).toFixed(2).replace(',', '.')
    }
  })
  return cleaned
}

async function sendCleanSpending (spending) {
  if (spending.value === '') spending.value = 0.00
  if (!spending.work_gear) spending.work_gear = false

  // convert all empty strings to nulls
  spending = Object.keys(spending).reduce((acc, key) => { acc[key] = spending[key] === '' ? null : spending[key]; return acc }, {})

  return spending
}

export default {

  async getFromPlan (planId) {
    try {
      const response = await connection.get(`/plans/${planId}/spendings`)
      if (!response) return false
      const values = await returnCleanSpending(response.data)
      return values
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (spendingId, spendingData) {
    try {
      spendingData = await sendCleanSpending(spendingData)
      const response = await connection.put(`/spendings/${spendingId}`, spendingData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (spendingData) {
    try {
      delete spendingData.spending_id
      spendingData = await sendCleanSpending(spendingData)
      const response = await connection.post('/spendings', spendingData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (spendingId) {
    try {
      const response = await connection.delete(`/spendings/${spendingId}`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

