var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form_details",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.saveGroup.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"type":"text","label":"Group Name *","outlined":"","rules":[
        _vm.rules.required,
        _vm.rules.maxLength(_vm.group.name,64)
      ]},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}}),_c('v-textarea',{attrs:{"type":"text","label":"Description","outlined":"","rows":"3","counter":"128","rules":[
        _vm.rules.maxLength(_vm.group.description,128)
      ]},model:{value:(_vm.group.description),callback:function ($$v) {_vm.$set(_vm.group, "description", $$v)},expression:"group.description"}}),(_vm.type==='edit')?_c('FormActionButtons',{attrs:{"buttons":[
        'cancel',
        'save'
      ]},on:{"cancel":function($event){return _vm.$emit('cancel')},"save":_vm.saveGroup}}):_vm._e(),(_vm.type==='new')?_c('FormActionButtons',{attrs:{"buttons":[
        'cancel',
        'next'
      ]},on:{"cancel":function($event){return _vm.$emit('cancel')},"next":_vm.saveGroup}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }