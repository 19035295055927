<template>
  <v-container>
    <v-row justify-md="space-between" justify="center">
      <v-col sm="12">
        <div class="title">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">Plan Profile</h5>
          <h2 class="text-h2 font-weight-regular">
            <v-skeleton-loader class="skeleton_loader" height="60px" width="400px" type="image" v-if="!client"></v-skeleton-loader>
            {{client.first_name}} {{client.last_name}}
          </h2>
        </div>

        <v-tabs class="mb-0" v-model="page" show-arrows>
          <v-tab @click="edit=false">Plan Info</v-tab>
          <v-tab @click="edit=false">Barriers</v-tab>
          <v-tab @click="edit=false">Supports</v-tab>
          <v-tab @click="edit=false">Trainings</v-tab>
          <v-tab @click="edit=false">Spendings</v-tab>
          <v-tab @click="edit=false">Notes</v-tab>
          <v-tab @click="edit=false">Outcome</v-tab>
        </v-tabs>
      </v-col>

      <v-col sm="12" md="7" lg="5" v-if="page==0">
        <PlanPageInfo     
          :planId="planId"
          :clientId="clientId"
        />
      </v-col>

      <v-col cols="12" v-if="page==1">
        <PlanPageBarriers     
          :planId="planId"
        />
      </v-col>

      <v-col cols="12" v-if="page==2">
        <PlanPageSupports     
          :planId="planId"
        />
      </v-col>

      <v-col cols="12" v-if="page==3">
        <PlanPageTrainings     
          :planId="planId"
        />
      </v-col>

      <v-col cols="12" v-if="page==4">
        <PlanPageSpendings     
          :planId="planId"
        />
      </v-col>

      <v-col cols="12" v-if="page==5">
        <PlanPageNotes     
          :planId="planId"
        />
      </v-col>

      <v-col cols="12" v-if="page==6">
        <PlanPageOutcome     
          :planId="planId"
        />
      </v-col>
        
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import PlanPageInfo from '@/components/Plans/PlanPageInfo.vue'
import PlanPageTrainings from '@/components/Plans/PlanPageTrainings.vue'
import PlanPageSupports from '@/components/Plans/PlanPageSupports.vue'
import PlanPageBarriers from '@/components/Plans/PlanPageBarriers.vue'
import PlanPageSpendings from '@/components/Plans/PlanPageSpendings.vue'
import PlanPageNotes from '@/components/Plans/PlanPageNotes.vue'
import PlanPageOutcome from '@/components/Plans/PlanPageOutcome.vue'

export default {
  name: 'PlanProfilePage',
  components: {
    PlanPageInfo,
    PlanPageTrainings,
    PlanPageSupports,
    PlanPageBarriers,
    PlanPageSpendings,
    PlanPageNotes,
    PlanPageOutcome
  },
  props: {
    planId: {
      type: Number,
      required: true
    },
    clientId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      page: 0,
      client: false
    }
  },
  
  async created() {
    this.refreshData()
  },
  methods: {
    async refreshData () {
      this.client = await Api.Clients.getSingle(this.clientId)
      this.$store.dispatch('setClient', this.client)
    },
  },
  
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      this.refreshData()
      this.page = 0
    }
    next()
  }
}
</script>

<style scoped lang="scss">
// * {
//   border: 1px solid red;
// }
.v-tabs {
  margin-bottom: 1rem;
}
</style>
