<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="6" class="skeleton_loader" v-if="masterArray.length===0">
        <p class="text-h5">What barriers do you face?</p>
        <v-skeleton-loader max-width="100%" max-height="48px" type="image" style="margin-bottom:21px;"></v-skeleton-loader>
        <v-skeleton-loader max-width="150px" max-height="14px" type="image" style="margin-bottom:9px;"></v-skeleton-loader>
        <div
          v-for="i in 5"
          :key="i"
          style="margin-bottom:8px;"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            max-height="48px"
            type="image"
          ></v-skeleton-loader>
        </div>
      </v-col>
      <v-col cols="6" v-if="masterArray && masterArray.length!==0">
        <p class="text-h5">What barriers do you face?</p>
        <div class="single-grid">
          <div class="grid-item">
            <div class="empty-to" v-if="masterArray.length===0" :move="false">
              <p class="placeholder text-body-1 mb-0 blue-grey--text text--darken-2">There are no more barriers.</p>
            </div>
          </div>
          <div class="grid-item">
            <v-tabs class="mb-3" show-arrows>
              <v-tab
                :class="(category===''?'hidden':'')"
                v-for="(category) in categories" 
                :key="category"
                @click="$vuetify.goTo(`#header_${category.replace(/(\s|[.])+/g,'-')}`,{container:'.scroll-area'})"
              >{{category}}</v-tab>
              <v-tab @click="$vuetify.goTo(`#header_other`,{container:'.scroll-area'})">Other</v-tab>
            </v-tabs>

            <div class="scroll-area">
              <span id="header_top"></span>
              <div v-for="item in masterArray" :key="item.key">
                <span :id="`header_${item.key.replace(/(\s|[.])+/g,'-')}`" class="text-overline" v-if="categories.includes(item.key)">{{item.key}}</span>
                <span :id="`header_other`" class="text-overline" v-if="item.key===''">Other</span>
                <draggable 
                  class="column column_1"
                  v-model="item.values"
                  v-bind="{...dragOptions,sort:false,filter:'.no-move'}" 
                  :group="{ 
                    name: 'barriers',
                    pull: (item.category===''?'clone':true),
                    put: false
                  }"
                >
                  <div 
                    class="draggable pa-3 mb-2 blue lighten-5"
                    color="blue lighten-5"
                    v-for="subitem in item.values"
                    :key="subitem.barrier_id"
                  >
                    {{subitem.name}}
                  </div>
                </draggable>
              </div>
            </div>

          </div>
        </div>
        <!-- <pre>{{masterArray}}</pre> -->
      </v-col>
      <v-col cols="6" v-if="masterArray">
        <p class="text-h5">Selected barriers</p>
        <div class="single-grid">
          <div class="grid-item">
            <div class="empty-to" v-if="newArray.length===0" :move="false">
              <p class="placeholder text-body-1 mb-0 blue-grey--text text--darken-2">
                There are no barriers. 
                <br/>
                You can add some by dragging from the left column.
              </p>
            </div>
          </div>
          <div class="grid-item">
            <draggable
              class="column column_2"
              v-model="newArray"
              v-bind="dragOptions" 
              :group="{ 
                name: 'barriers',
                pull: false,
                put: true
              }"
              @change="update"
            >
              <div 
                class="draggable pa-3 mb-2 blue lighten-5"
                v-for="(item, i) in newArray" 
                :key="i"
              >
                {{item.name}}{{ item.other_name?': '+item.other_name:'' }}
                <v-btn 
                  small 
                  icon
                  @click="removeAt(i)"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </div>
            </draggable>
          </div>
        </div>
        <!-- <pre>{{newArray}}</pre> -->

        <FormActionButtons
          :buttons="[
            'back',
            'save'
          ]" 
          :loading="loading"
          @back="$emit('back');saveCurrent()"
          @save="$emit('save');saveCurrent()"
        />

      </v-col>
    </v-row>

    <!-- MODAL -->
    <v-dialog
      v-model="modal.show"
      width="500"
      @click:outside="modalCancel"
    >
      <v-card>
        <v-card-title class="text-h5 font-weight-medium">
          <v-icon color="blue darken-2">mdi-information-outline</v-icon>
          Information Required
        </v-card-title>

        <v-card-text class="text-h6 font-weight-light">
          <v-form 
            ref="form"
            @submit.prevent="modalConfirm" 
            novalidate
          >
            <p>Please give this barrier a name.</p>
            <v-text-field
              label="Name *"
              type="text"
              outlined
              v-model="modal.other_name"
              :rules="[
                rules.required
              ]"
            />
          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="modalCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="modalConfirm"
          >
            Add barrier
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import validationRules from '@/helpers/validationRules'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  name: 'BarrierColumns',
  components: {
    FormActionButtons,
    draggable
  },
  props: {
    columnOne: {
      required: true
    },
    columnTwo: {
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      rules: false,
      masterArray: [],
      newArray: this.columnTwo,
      lastCategory: null,
      modal: {
        show: false,
        newIndex: false,
        other_name: ''
      }
    }
  },
  async created () { 
    this.rules = await validationRules
    this.resetMasterList()
  },
  methods: {
    update (event) {
      event = event.added
      if (event.element.name==='Other') {
        this.modal = {
          newIndex: event.newIndex,
          show: true,
          other_name: '',
        }
      }
      this.resetMasterList()
    },
    saveCurrent() {
      this.$emit('update', this.masterArray, this.newArray)
    },
    resetMasterList () {
      if (!this.columnOne) return []
      this.cleanedMaster = []
      this.columnOne.forEach((objMaster) => {
        let result = true

        this.newArray.some((objActive) => {
          if (objMaster.name==='Other') {
            result = true
            return true
          }

          if (objMaster.barrier_id === objActive.barrier_id) {
            result = false
            return true
          }
        })

        if (result) this.cleanedMaster.push(objMaster)
      })

      this.masterArray = []
      this.categories.forEach((item, i) => {
        this.masterArray.push({key: item, values: this.cleanedMaster.filter((obj) => obj.category === item)})
      })

    },
    async removeAt(index) {
      await this.newArray.splice(index, 1)
      this.resetMasterList()
    },
    
    async modalCancel () {
      await this.newArray.splice(this.modal.newIndex, 1)
      this.resetMasterList()
      this.modal.show = false
    },
    modalConfirm () {
      // check values
      if( !this.$refs.form.validate() ) return

      this.newArray[this.modal.newIndex] = {
        ...this.newArray[this.modal.newIndex],
        other_name: this.modal.other_name
      }
      this.modal.show = false
    },
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    categories () {
      return [...new Set(this.columnOne.map((obj) => obj.category))].sort((a, b) => {
        if (a === '' || a === null) return 1
        if (b === '' || b === null) return -1
        if (a === b) return 0
        return a < b ? -1 : 1
      })
    }
  },

  watch: {
    columnOne (oldValue, newValue) {
      this.resetMasterList()
    }
  }
}
</script>

<style scoped lang="scss">
.column {
  height: 100%;
}

.single-grid {
  display: grid; 
  grid-template-columns: 100%; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  // height: 100%;
  min-height: 150px;

  .grid-item {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.scroll-area {
  max-height: 600px;
  overflow-y: scroll;
  padding-right: 10px;
}

.draggable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 0.25rem;

  &:hover {
    cursor: grab;
    background-color: #2196F3!important;
    color: white;
  }

  &:active {
    cursor: grabbing;
  }
}

.empty-to {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  height: 150px;
  opacity: 0.8;
  border: 2px dashed #BDBDBD;
  border-radius: 0.25rem;
  text-align: center;
}
</style>
