<template>
  <v-container>
    <v-row justify-md="space-between" justify="center">
      <v-col xl="5" lg="5" md="6" sm="8">

        <div class="title">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">Centre Profile</h5>
          <h2 class="text-h2 font-weight-regular">
            <v-skeleton-loader class="skeleton_loader" height="60px" width="400px" type="image" v-if="!centre"></v-skeleton-loader>
            {{centre.name}}
          </h2>
        </div>

        <div v-if="page=='profile'">
          <CentreProfileView
            :centre="centre" 
          />

          <FormActionButtons
            :buttons="[
              'edit'
            ]"
            v-if="centre"
            @edit="page='edit'"
            @delete="deleteModal=true"
          />
        </div>

        <CentreProfileEditForm
          v-if="page=='edit'"
          :centre="centreEditing"
          @save="page='profile';refreshData()"
          @cancel="page='profile';resetData()" 
        />

        <!-- <DeleteModal
          v-if="deleteModal"
          title="Delete Centre"
          :text="`Are you sure you wish to delete the current centre <b>${centre.name}</b>? This will also delete all of its assosiated users and clients.`"
          :hardConfirm="true"
          @confirm="deleteCentreModalConfirm"
          @cancel="deleteCentreModalCancel" 
        /> -->

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex">

        <div id="bg-block"></div>
        <div id="bg-image"><InlineSvg src="/images/svgs/undraw_coming_home.svg" /></div>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import CentreProfileView from '@/components/Centres/CentreProfileView.vue'
import CentreProfileEditForm from '@/components/Centres/CentreProfileEditForm.vue'
import DeleteModal from '@/components/elements/DeleteModal.vue'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  name: 'CentreProfilePage',
  components: {
    CentreProfileView,
    CentreProfileEditForm,
    DeleteModal,
    FormActionButtons
  },
  props: {
    centreId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      page: 'profile',
      centre: false,
      centreEditing: false,
      deleteModal: false
    }
  },
  
  async created() {
    await this.refreshData()
  },
  methods: {
    async refreshData () {
      this.centre = await Api.Centres.getSingle(this.centreId)
      this.centreEditing = Object.assign({}, this.centre)
    },
    resetData () {
      this.centreEditing = Object.assign({}, this.centre)
    },

    deleteCentre () {
      this.deleteModal = true
    },
    async deleteCentreModalConfirm () {
      this.deleteModal = false
      const response = await Api.Centres.delete(this.centreId)
      if (response) {
        this.$toast.success('The centre was successfully deleted.');
        this.$router.push({name:'CentresPage'})
        return
      }
      this.$toast.error('Something went wrong. Please try again.');
    },
    deleteCentreModalCancel () {
      this.deleteModal = false
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 2.188rem;
}
.actions {
  margin-top: 1.875rem;
  button {
    margin-right: 20px;
  }
}
</style>