<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="5" class="skeleton_loader" v-if="!activeColumn || !masterColumn">
        <div
          v-for="i in 5"
          :key="i"
          style="margin-bottom:8px;"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            max-height="52px"
            type="image"
          ></v-skeleton-loader>
        </div>
      </v-col>
      <v-col cols="5" v-if="activeColumn && masterColumn">
        <div class="single-grid">
          <div class="grid-item">
            <div class="empty-to" v-if="activeColumn.length===0" :move="false">
              <p class="placeholder text-body-1 mb-0 blue-grey--text text--darken-2" v-html="settings.emptyStringView"></p>
            </div>
          </div>
          <div class="grid-item">
            <div 
              class="draggable py-3 px-6 mb-2 blue lighten-5"
              v-for="(item,i) in activeColumn" 
              :key="i"
            >
              {{item[settings.arrayTitle]}}{{ item.other_name?': '+item.other_name:'' }}
            </div>
          </div>
        </div>

        <FormActionButtons
          :buttons="[
            'edit'
          ]" 
          @edit="$emit('edit')"
        />

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  components: {
    FormActionButtons
  },
  props: {
    settings: {
      type: Object,
      required: true
    },
    activeColumn: {
      required: true
    },
    masterColumn: {
      required: true
    },
  },
  data () {
    return {
    }
  },
  created () { 
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
// * {
//   border: 1px solid blue;
// }
.column {
  height: 100%;
}

.single-grid {
  display: grid;
  grid-template-columns: 100%; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  // height: 100%;
  min-height: 150px;

  .grid-item {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.scroll-area {
  max-height: 600px;
  overflow-y: scroll;
  padding-right: 10px;
}

.draggable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 0.25rem;
  min-height: 52px;

  &.draggable-active {
    &:hover {
      cursor: grab;
      background-color: #2196F3!important;
      color: white;
    }

    &:active {
      cursor: grabbing;
    }
  }
}

.empty-to {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  opacity: 0.8;
  border: 1px solid #E0E0E0;
  border-radius: 0.25rem;
  text-align: center;
}
</style>
