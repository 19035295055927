<template>
  <v-container>
    <v-row justify-md="space-between" justify="center">
      <v-col xl="5" lg="5" md="6" sm="8">

        <div class="title">
          <h2 class="text-h2 font-weight-regular">New User</h2>
        </div>

        <UserProfileNewForm />

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex">

        <div id="bg-block"></div>
        <div id="bg-image"><InlineSvg src="/images/svgs/undraw_mobile_user.svg" /></div>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserProfileNewForm from '@/components/Users/UserProfileNewForm.vue'

export default {
  name: 'UserProfilePage',
  components: {
    UserProfileNewForm,
  }
}
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 2.188rem;
}
</style>
