import { checkAuth } from '../functions'
import GroupsPage from '@/views/Groups/GroupsPage.vue'
import GroupProfilePage from '@/views/Groups/GroupProfilePage.vue'
import GroupNewPage from '@/views/Groups/GroupNewPage.vue'

export default [
  {
    path: '/groups',
    name: 'GroupsPage',
    component: GroupsPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'Groups'
    }
  },
  {
    path: '/group/create',
    name: 'GroupNewPage',
    component: GroupNewPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'New Group'
    }
  },
  {
    path: '/group/:groupId',
    name: 'GroupProfilePage',
    component: GroupProfilePage,
    props: (route) => {
      return {
        groupId: parseInt(route.params.groupId)
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'Group'
    }
  }
]
