import { checkAuth } from '../functions'
import MappingListPage from '@/views/Mappings/MappingListPage'
import MappingCategoryPage from '@/views/Mappings/MappingCategoryPage'
import MappingIndividualPage from '@/views/Mappings/MappingIndividualPage'

export default [
  {
    path: '/mappings',
    name: 'MappingListPage',
    component: MappingListPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'Mappings'
    }
  },
  {
    path: '/mapping/:category',
    name: 'MappingCategoryPage',
    component: MappingCategoryPage,
    props: (route) => {
      return {
        category: route.params.category
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'Mapping'
    }
  },
  {
    path: '/mapping/:category/:id',
    name: 'MappingIndividualPage',
    component: MappingIndividualPage,
    props: (route) => {
      return {
        category: route.params.category,
        id: route.params.id
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'Mapping'
    }
  }
]
