<template>
  <v-container style="padding:0;">

    <v-row v-if="storyEdit">
      <v-col cols="5">

        <p class="text-h5">{{ selectedStory.story_id===-1 ? 'New Success Story' : 'Edit Success Story' }}</p>
    
        <v-form 
          ref="form"
          @submit.prevent="saveStory" 
          novalidate
        >

          <v-text-field
            type="date"
            v-model="selectedStory.date"
            label="Date *"
            outlined
            prepend-inner-icon="mdi-calendar"
            :rules="[
              rules.required,
              rules.noFutureDate
            ]"
          />

          <v-textarea
            type="text"
            v-model="selectedStory.writeup"
            label="Story *"
            outlined
            rows="5"
            counter="2048"
            :rules="[
              rules.required,
              rules.maxLength(selectedStory.writeup,2048)
            ]"
          />

          <FormActionButtons
            :buttons="[
              'cancel',
              'save'
            ]" 
            noTopPadding
            :loading="loading"
            @cancel="storyEdit=false;selectedStory=false"
            @save="saveStory"
          />
        </v-form>

      </v-col>
    </v-row>

    <v-row v-if="!storyEdit">
      
      <v-col cols="5" class="skeleton_loader" v-if="!stories">
        <div
          v-for="i in 5"
          :key="i"
          style="margin-bottom:8px;"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            max-height="48px"
            type="image"
          ></v-skeleton-loader>
        </div>
      </v-col>

      <v-col cols="5" v-if="stories">
        <div class="single-grid">
          <div class="grid-item">
            <div class="empty-to" v-if="stories.length===0" :move="false">
              <p class="placeholder text-body-1 mb-0 blue-grey--text text--darken-2">
                There are no stories. 
                <br/>
                You can create one by clicking the button below.
              </p>
            </div>
          </div>
          <div class="grid-item">
            <div 
              v-for="item in stories" 
              :class="'story-item pa-3 mb-2 blue lighten-5' + (item.story_id===selectedStory.story_id?' active':'')"
              :key="item.story_id"
              @click="selectedStory=Object.assign({},item)"
            >
              <div class="more-info">
                <span>{{ moment(item.date).format('MMMM D, YYYY') }}</span>
              </div>
            </div>
          </div>
        </div>

        <FormActionButtons
          :buttons="[]" 
          :customButton="{
            text: false,
            color: 'primary',
            label: 'New Story' ,
            icon: 'mdi-plus'
          }"
          @custom="newStory"
        />
      </v-col>
      <v-col cols="7" v-if="stories && stories.length!==0">

        <div class="single-story">

          <div class="empty" v-if="!selectedStory">
            <p class="text-body-1 mb-0">Select a success story from the left to view its details</p>
          </div>
          <div v-if="selectedStory">

            <div>
              <p class="text-h5">
                {{ moment(selectedStory.date).format('MMMM D, YYYY') }}
              </p>
              <p class="text-body-1">{{selectedStory.writeup}}</p>

              <FormActionButtons
                :buttons="[
                  'edit',
                  'delete'
                ]"
                @edit="storyEdit=true"
                @delete="deleteStory"
              />
            </div>

          </div>
        </div>
      </v-col>
    </v-row>  

    <DeleteModal
      v-if="deleteModal"
      title="Delete Story"
      :text="`Are you sure you wish to delete this story?`"
      @confirm="deleteStoryModalConfirm"
      @cancel="deleteStoryModalCancel" 
    />

  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'

import DeleteModal from '@/components/elements/DeleteModal.vue'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
var moment = require('moment')

export default {
  components: {
    DeleteModal,
    FormActionButtons
  },
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      loading: false,
      rules: false,
      stories: false,
      selectedStory: false,
      storyEdit: false,
      deleteModal: false
    }
  },
  async created () { 
    this.rules = await validationRules
    this.refreshData()
  },
  methods: {
    async refreshData () {
      this.stories = false
      this.storyEdit = false
      this.selectedStory = false
      this.stories = await Api.Stories.getFromClient(this.clientId)
    },

    newStory () {
      this.storyEdit = true
      this.selectedStory = {
        story_id: -1,
        writeup: '',
        date: moment().format('YYYY-MM-DD')
      }
    },

    async saveStory () {
      if( !this.$refs.form.validate() ) return
      this.loading = true

      this.selectedStory.client_id = this.clientId

      if (this.selectedStory.story_id === -1){
        //create
        const response = await Api.Stories.create(this.selectedStory)
        if (response) {
          this.loading = false
          this.refreshData()
          this.$toast.success('The story was successfully created.')
          return
        }
      } else {
        //update
        const response = await Api.Stories.update(this.selectedStory.story_id, this.selectedStory)
        if (response) {
          this.loading = false
          this.refreshData()
          this.$toast.success('The story was successfully updated.')
          return
        }
      }

      this.$toast.error('Something went wrong. Please try again.')
    },

    deleteStory () {
      this.deleteModal = true
    },
    async deleteStoryModalConfirm () {
      this.deleteModal = false
      const response = await Api.Stories.delete(this.selectedStory.story_id, this.selectedStory)
      if (response) {
        this.refreshData()
        this.$toast.success('The story was successfully deleted.')
        return
      }
      this.$toast.error('Something went wrong. Please try again.')
    },
    deleteStoryModalCancel () {
      this.deleteModal = false
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.story-item {
  transition: all 0.2s ease;
  border-radius: 0.25rem;
  cursor: pointer;
  .v-icon {
    color: black;
  }

  &.active, &:hover {
    background-color: #2196F3!important;
    color: white;
    .v-icon {
      color: white!important;
    }
  }
}

.single-story {
  border: 1px solid #E0E0E0;
  padding: 28px 30px;
  border-radius: 0.25rem;

  .v-icon {
    color: black;
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    text-align: center;
    color: #455A64;
  }
}
</style>
