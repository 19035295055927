<template>
  <v-container>
    <v-row>
      <v-col sm="12" class="pb-0">
        <div class="title">
          <h2 class="text-h2 font-weight-regular">Mappings</h2>
        </div>
      </v-col>
      <v-col 
        cols="4"
        v-for="(category,i) in categories"
        :key="i"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            max-width="100%"
            :to="{name:'MappingCategoryPage',params:{category:category.key}}"
            :color="'blue ' + (hover?'lighten-4':'lighten-5')"
            style="transition:all 0.3s ease;"
            elevation="0"
          >
            <InlineSvg 
              height="175px"
              width="100%"
              class="pa-3"
              :src="'/images/svgs/'+category.image+'.svg'" 
            />

            <div class="blue lighten-4">
              <v-card-title class="pb-0">{{category.title}}</v-card-title>
              <v-card-text class="text-subtitle-1">
                {{category.items.length}} item(s)
              </v-card-text>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'

export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      categories: [
        {
          title: 'Supports',
          key: 'supports',
          image: 'undraw_team_spirit',
          items: []
        },
        {
          title: 'Trainings',
          key: 'trainings',
          image: 'undraw_certificate',
          items: []
        },
        {
          title: 'Barriers',
          key: 'barriers',
          image: 'undraw_through_the_desert',
          items: []
        }
      ],
    }
  },

  created () {
    this.getLists()
  },

  methods: {
    async getLists(){
      this.categories.find(val => val.key === 'barriers').items = await Api.Barriers.getAll()
      this.categories.find(val => val.key === 'supports').items = await Api.Supports.getAll()
      this.categories.find(val => val.key === 'trainings').items = await Api.Trainings.getAll()
    },
  }
}
</script>

<style lang="scss">
</style>
