var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rules)?_c('div',{staticClass:"LoginForm"},[_vm._m(0),_vm._m(1),_c('v-form',{ref:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"type":"text","id":"loginUser","label":"Email *","outlined":"","rules":[
        _vm.rules.required(_vm.loginUser, 'Email is required'),
        _vm.rules.minLength(_vm.loginUser, 8, 'Email must be at least 8 characters'),
        _vm.rules.maxLength(_vm.loginUser, 128, 'Email must less than 128 characters'),
        _vm.rules.isEmail(_vm.loginUser, 'Must be a valid email')
      ],"validate-on-blur":""},on:{"blur":function (){this$1.submissionStatus=true}},model:{value:(_vm.loginUser),callback:function ($$v) {_vm.loginUser=$$v},expression:"loginUser"}}),_c('v-text-field',{attrs:{"type":_vm.loginPassShow ? 'text' : 'password',"id":"loginPass","label":"Password *","append-icon":_vm.loginPassShow ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","rules":[
        _vm.rules.required(_vm.loginPass, 'Password is required'),
        _vm.rules.minLength(_vm.loginPass, 8, 'Password must be at least 8 characters'),
        _vm.rules.maxLength(_vm.loginPass, 128, 'Password must less than 128 characters')
      ],"validate-on-blur":""},on:{"click:append":_vm.togglePassword,"blur":function (){this$1.submissionStatus=true}},model:{value:(_vm.loginPass),callback:function ($$v) {_vm.loginPass=$$v},expression:"loginPass"}}),_c('v-btn',{attrs:{"type":"submit","color":"primary","block":"","loading":_vm.loading,"disabled":_vm.loading,"x-large":"","elevation":"0"}},[_vm._v(" Login "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('br'),_c('div',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{name:'ForgotPasswordPage'}}},[_vm._v("Forgot Password")])],1)],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img-holder"},[_c('img',{attrs:{"src":"/images/bcaafc.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title text-center"},[_c('span',{staticClass:"text-h3"},[_vm._v("ELST Login")])])}]

export { render, staticRenderFns }