<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <div class="title">
          <h2 class="text-h2 font-weight-regular">Documents</h2>
        </div>
      </v-col>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="documents"
          sort-by="name"
          sort-desc
        >
          <template v-slot:[`item.description`]="{ item }">
            {{item.description!==null?(item.description.length>100?item.description.substring(0,97)+'...':item.description):'-'}}
          </template>
          <template v-slot:[`item.link`]="{ item }">
            <v-btn 
              small 
              color="primary"
              elevation="0"
              :href="item.link"
              download
            >
              <v-icon small>mdi-download</v-icon> Download
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'

export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      documents: [
        {
          name: 'Client Intake Form',
          description: 'Form used to collect basic client information.',
          link: '/documents/ELST_Client_Intake.pdf'
        },
        {
          name: 'New Plan Form',
          description: 'Form used to track a client\'s current enrolment in the program.',
          link: '/documents/ELST_New_Plan.pdf'
        },
        {
          name: 'Client Consent Form',
          description: 'General, ministry-provided consent release.',
          link: '/documents/collection_notice.pdf'
        }
      ]
    }
  },

  created () {
  },

  methods: {
  },

  computed: {
    headers () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return [
            { text: 'Name', value: 'name' },
            { text: '', value: 'link', width: '50px', sortable: false },
          ]
        case 'md': 
        case 'lg':
        case 'xl': 
          return [
            { text: 'Name', value: 'name' },
            { text: 'Description', value: 'description' },
            { text: '', value: 'link', width: '50px', sortable: false },
          ]
      }
    }
  }
}
</script>

<style lang="scss">
</style>
