// state variables
export const state = {
  client: false
}

// fucntions that return state variables
export const getters = {
  getClient: (state) => {
    return state.client
  }
}

// functions that directly change state
export const mutations = {
  setClient (state, client) {
    state.client = client
  }
}

// functions to initiate changes to state
export const actions = {
  setClient (context, client) {
    context.commit('setClient', client)
  },
  clearClient (context) {
    context.commit('setClient', false)
  }
}
