<template>
  <v-container class="pa-0">
    <v-row>
      <v-col 
        cols="6"
        v-for="(page,i) in category.pages"
        :key="i"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            class="py-3 px-6 mb-0"
            elevation="0"
            :to="{name:'HelpPage.Page',params:{helpCategory:category.link,helpPage:page.link}}"
            :color="'blue ' + (hover?'lighten-4':'lighten-5')"
            style="transition:all 0.3s ease;"
          >
            <span class="text-subtitle-1 pa-0">{{page.title}}</span>
            <v-container class="pa-0">
              <v-row>
                <v-col cols="6">
                  <span class="text-caption grey--text text--darken-1">Last Updated <b>{{moment(page.updated).format('MMMM D, YYYY')}}</b></span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
var moment = require('moment')

export default {
  components: {
  },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      moment: moment
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
