import connection from '../connection'
// import store from '../../store'

export default {

  async getFromPlan (planId) {
    try {
      const response = await connection.get(`/plans/${planId}/notes`)
      if (!response) return false
      let sorted = response.data.sort((a, b) => (a.date < b.date) ? 1 : ((a.date > b.date) ? -1 : 0)) // date newest -> oldest
      sorted = sorted.sort((a, b) => (a.flag < b.flag) ? 1 : ((a.flag > b.flag) ? -1 : 0)) // pin -> not pin
      return sorted
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (noteId, noteData) {
    try {
      const response = await connection.put(`/notes/${noteId}`, noteData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (noteData) {
    try {
      delete noteData.note_id
      const response = await connection.post('/notes', noteData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (noteId) {
    try {
      const response = await connection.delete(`/notes/${noteId}`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

