<template>
  <v-container>
    <v-row>
      <v-col sm="12" class="pb-0">
        <div class="title">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">Mappings</h5>
          <h2 class="text-h2 font-weight-regular">{{category.charAt(0).toUpperCase()+category.slice(1)}}</h2>
        </div>
      </v-col>
      <v-col sm="12" class="pt-0">
        <div v-if="category==='supports'">
          <SupportView
            v-if="!edit"
            :item="item"
            @edit="edit=true"
          />
          <SupportEdit
            v-if="edit"
            :item="item"
            :mappings="mappings"
            @back="edit=false;updateItem();"
          />
        </div>
        <div v-if="category==='trainings'">
          <TrainingsView
            v-if="!edit"
            :item="item"
            @edit="edit=true"
          />
          <TrainingsEdit
            v-if="edit"
            :item="item"
            :mappings="mappings"
            @back="edit=false;updateItem();"
          />
        </div>
        <div v-if="category==='barriers'">
          <BarriersView
            v-if="!edit"
            :item="item"
            @edit="edit=true"
          />
          <BarriersEdit
            v-if="edit"
            :item="item"
            :mappings="mappings"
            @back="edit=false;updateItem();"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import SupportView from '@/components/Mappings/Supports/SupportView'
import SupportEdit from '@/components/Mappings/Supports/SupportEdit'
import TrainingsView from '@/components/Mappings/Trainings/TrainingView'
import TrainingsEdit from '@/components/Mappings/Trainings/TrainingEdit'
import BarriersView from '@/components/Mappings/Barriers/BarrierView'
import BarriersEdit from '@/components/Mappings/Barriers/BarrierEdit'

export default {
  components: {
    SupportView,
    SupportEdit,
    TrainingsView,
    TrainingsEdit,
    BarriersView,
    BarriersEdit
  },
  props: {
    category: {
      type: String,
      required: true
    },
    id: {
      required: true
    }
  },
  data () {
    return {
      item: false,
      edit: false,
      mappings: false,
    }
  },

  created () {
    this.updateItem()
  },

  methods: {
    async updateItem(){
      this.mappings = await Api.Reporting.getAllTypes()
      if(this.id==='new'){
        this.item = {}
        this.edit = true
      } else {
        if (this.category === 'supports') {
          this.item = await Api.Supports.getSingle(this.id)
        } else if (this.category === 'trainings') {
          this.item = await Api.Trainings.getSingle(this.id)
        } else {
          this.item = await Api.Barriers.getSingle(this.id)
        }
      }
    }
  }
}
</script>

<style lang="scss">
</style>
