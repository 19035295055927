import { checkAuth } from '../functions'
import UsersPage from '@/views/Users/UsersPage'
import UserProfilePage from '@/views/Users/UserProfilePage'
import UserProfileNewPage from '@/views/Users/UserProfileNewPage'

export default [
  {
    path: '/users',
    name: 'UsersPage',
    component: UsersPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'Users'
    }
  },
  {
    path: '/user/create',
    name: 'UserProfileNewPage',
    component: UserProfileNewPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'New User'
    }
  },
  {
    path: '/user/:userId',
    name: 'UserProfileViewPage',
    component: UserProfilePage,
    props: (route) => {
      return {
        userId: parseInt(route.params.userId)
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'User'
    }
  }
]
