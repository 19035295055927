/* eslint-disable dot-notation */
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import Toast from 'vue-toastification'
import { Api } from '@/gateways/endpoints'
import getEnv from '@/helpers/getEnv'

const { detect } = require('detect-browser')
const browser = detect()
const moment = require('moment')

Vue.use(Toast, {
  position: 'top-center',
  timeout: 3000
})

const connection = axios.create({
  baseURL: getEnv().VUE_APP_API_URL
})

connection.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json'
  config.headers['Authorization'] = 'bearer ' + store.getters.getAccessToken
  return config
}, (err) => {
  console.error('Error Sending: ' + err.message)
  return false
})

connection.interceptors.response.use(async (response) => {
  // THIS IS TESTING ONLY
  //
  // console.info('CALLING API: ' + response.config.url)
  // await new Promise(resolve => setTimeout(resolve, 1000))
  //
  // console.log({
  //   url: response.config.url,
  //   method: response.config.method,
  //   sentData: response.config.data,
  //   returnData: response.data
  // })
  return response
}, async (err) => {

  if (err.response.status === 401 && !err.response.config._retry) {
    // previous call failed and need new access token
    err.config._retry = true
    const response = await connection.post('/auth/token', { refreshToken: store.getters.getRefreshToken }, { _retry: true })
    if (response.status === 200) {
      // successfully got new access token
      store.dispatch('setAccessToken', response.data.accessToken)
      err.config.headers.Authorization = 'bearer ' + response.data.accessToken
      return connection(err.config)
    }
  } else if (err.response.status === 404 && err.response.config.url === '/auth/token') {
    // if previous call to token failed
    if (store.getters.getUser) {
      await Api.Auth.logout()
      Vue.$toast.warning('Your session has expired. Please login again to continue.', {
        timeout: false
      })
    }
    store.dispatch('removeLogin')
    router.push({ name: 'LoginPage' })
    return false
  } else if (err.response.config.url === '/auth/check-reset') {
    return false
  } else {
    // create error message for 404 page
    router.push({
      name: 'ErrorPage',
      params: {
        errorReport: {
          version: require('@/../package.json').version,
          timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          browser: browser.name + ' ' + browser.version,
          os: browser.os,
          previousUrl: window.location.protocol + '//' + window.location.host + router.history.current.fullPath,
          apiRequest: {
            method: err.config.method,
            url: err.config.baseURL + err.config.url,
            status: err.response.status,
            response: err.response.data.message
          }
        }
      }
    })
    return false
  }
})

export default connection
