import { checkAuth } from '../functions'
import ClientsPage from '@/views/Clients/ClientsPage.vue'
import ClientProfileNewPage from '@/views/Clients/ClientProfileNewPage.vue'
import ClientProfilePage from '@/views/Clients/ClientProfilePage.vue'
import ClientInteractionPage from '@/views/Clients/ClientInteractionPage.vue'

export default [
  {
    path: '/clients',
    name: 'ClientsPage',
    component: ClientsPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'Clients'
    }
  },
  {
    path: '/client/create',
    name: 'ClientProfileNewPage',
    component: ClientProfileNewPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'New Client'
    }
  },
  {
    path: '/client/:clientId',
    name: 'ClientProfilePage',
    component: ClientProfilePage,
    props: (route) => {
      return {
        clientId: parseInt(route.params.clientId)
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'Client'
    }
  },
  {
    path: '/interaction',
    name: 'ClientInteractionPage',
    component: ClientInteractionPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'Client Interaction'
    }
  }

]
