var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rules && _vm.provinces)?_c('div',{staticClass:"CentreProfileNewForm"},[_c('v-form',{ref:"CentreProfileNewForm",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.createCentre.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"type":"text","label":"Centre Name *","outlined":"","rules":[
        _vm.rules.required,
        _vm.rules.maxLength(_vm.centre.name, 128)
      ]},model:{value:(_vm.centre.name),callback:function ($$v) {_vm.$set(_vm.centre, "name", $$v)},expression:"centre.name"}}),_c('v-text-field',{attrs:{"type":"text","label":"Street Address","outlined":"","rules":[
        _vm.rules.maxLength(_vm.centre.address, 128)
      ]},model:{value:(_vm.centre.address),callback:function ($$v) {_vm.$set(_vm.centre, "address", $$v)},expression:"centre.address"}}),_c('v-text-field',{attrs:{"type":"text","label":"City","outlined":"","rules":[
        _vm.rules.maxLength(_vm.centre.city, 64)
      ]},model:{value:(_vm.centre.city),callback:function ($$v) {_vm.$set(_vm.centre, "city", $$v)},expression:"centre.city"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.provinces,"item-text":"name","item-value":"abbr","label":"Province","outlined":""},model:{value:(_vm.centre.province),callback:function ($$v) {_vm.$set(_vm.centre, "province", $$v)},expression:"centre.province"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"text","label":"Postal Code","outlined":"","rules":[
            _vm.rules.noSpaces,
            _vm.rules.isPostalCode
          ]},model:{value:(_vm.centre.postal_code),callback:function ($$v) {_vm.$set(_vm.centre, "postal_code", $$v)},expression:"centre.postal_code"}})],1)],1),_c('v-text-field',{attrs:{"type":"text","label":"Phone Number *","outlined":"","rules":[
        _vm.rules.required,
        _vm.rules.maxLength(_vm.centre.phone,15)
      ]},model:{value:(_vm.centre.phone),callback:function ($$v) {_vm.$set(_vm.centre, "phone", $$v)},expression:"centre.phone"}}),_c('FormActionButtons',{attrs:{"buttons":[
        'cancel',
        'save'
      ],"noTopPadding":""},on:{"cancel":function($event){return _vm.$router.push({name:'CentresPage'})},"save":_vm.createCentre}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }