import connection from '../connection'
// import store from '@/store'
// import { Api } from '@/gateways/endpoints'

function returnCleanedGroup (group) {
  group.clients.forEach((client, i) => {
    group.clients[i].full_name = client.first_name + ' ' + client.last_name
  })

  return group
}

function sendCleanedGoup (group) {
  group.clients.forEach((client, i) => {
    group.clients[i] = {
      client_id: client.client_id
    }
  })

  delete group.created_at
  delete group.updated_at

  return group
}

export default {

  async getAll () {
    try {
      const response = await connection.get('/groups')
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getSingle (groupId) {
    try {
      const response = await connection.get(`/groups/${groupId}`)
      if (!response) return false
      return returnCleanedGroup(response.data)
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (groupId, groupData) {
    try {
      groupData = sendCleanedGoup(groupData)
      const response = await connection.put(`/groups/${groupId}`, groupData)
      if (!response) return false
      return true
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async updateMembers (groupId, groupData) {
    try {
      groupData = sendCleanedGoup(groupData).clients
      const response = await connection.post(`/groups/${groupId}/clients`, groupData)
      if (!response) return false
      return true
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (groupData) {
    try {
      groupData = sendCleanedGoup(groupData)
      const response = await connection.post('/groups', groupData)
      if (!response) return false
      return true
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (groupId) {
    try {
      const response = await connection.delete(`/groups/${groupId}`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

