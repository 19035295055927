import connection from '../connection'
import userColors from '@/helpers/userColors'

// get all
// get one
// update
// create
// delete

function cleanUsers (users) {
  if (Object.prototype.toString.call(users) === '[object Object]') {
    users = [users]
  }

  const cleaned = users.map((row) => {
    return {
      ...row,
      icon: (row.first_name.substring(0, 1) + row.last_name.substring(0, 1)).toUpperCase(),
      icon_bg: userColors[row.first_name.substring(0, 1).toLowerCase()] + ' darken-1',
      full_name: row.first_name + ' ' + row.last_name,
      centre: {
        ...row.centre,
        centre_id: (row.centre !== null ? row.centre.centre_id : -1),
        name: (row.centre !== null ? row.centre.name : 'None')
      }
    }
  })
  return cleaned
}

export default {

  async getAll () {
    try {
      const response = await connection.get('/users')
      if (!response) return false
      return cleanUsers(response.data)
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getSingle (userId) {
    try {
      const response = await connection.get('/users/' + userId)
      if (!response) return false
      return cleanUsers(response.data)[0]
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (userId, userData) {
    try {
      const response = await connection.put('/users/' + userId, userData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (userData) {
    try {
      const response = await connection.post('/users', userData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (userId) {
    try {
      const response = await connection.delete('/users/' + userId)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async changePassword (userId, password) {
    try {
      const response = await connection.post('/auth/change-password', { id: userId, password })
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

