// state variables
export const state = {
  refreshToken: null,
  accessToken: null,
  user: null
}

// fucntions that return state variables
export const getters = {
  checkUserAuth: (state) => {
    if (state.accessToken === null) return false
    return true
  },
  getUser: (state, getters) => {
    if (!getters.checkUserAuth) return false
    return state.user
  },
  getRefreshToken: (state) => {
    if (!getters.checkUserAuth) return false
    return state.refreshToken
  },
  getAccessToken: (state) => {
    if (!getters.checkUserAuth) return false
    return state.accessToken
  }
}

// functions that directly change state
export const mutations = {
  setAccessToken (state, token) {
    state.accessToken = token
  },
  setRefreshToken (state, token) {
    state.refreshToken = token
  },
  setUser (state, cleanData) {
    state.user = cleanData
  }
}

// functions to initiate changes to state
export const actions = {
  setAccessToken (context, token) {
    context.commit('setAccessToken', token)
  },
  setRefreshToken (context, token) {
    context.commit('setRefreshToken', token)
  },
  setUser (context, user) {
    context.commit('setUser', user)
  },
  removeLogin (context) {
    context.commit('setAccessToken', null)
    context.commit('setRefreshToken', null)
    context.commit('setUser', null)
  }
}
