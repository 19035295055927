var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"title d-flex align-center justify-space-between"},[_c('h2',{staticClass:"text-h2 font-weight-regular"},[_vm._v("Groups")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0","large":"","rounded":"","to":{name:'GroupNewPage'}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New Group ")],1)],1),(!_vm.groups)?_c('div',{staticClass:"skeleton_loader"},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody, table-tfoot"}})],1):_vm._e(),(_vm.groups)?_c('div',[_c('v-text-field',{staticStyle:{"width":"500px"},attrs:{"type":"text","label":"Group Search","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.groups,"sort-by":"created_at","search":_vm.search,"sort-desc":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'GroupProfilePage',params:{groupId:item.group_id}}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description?(item.description.length>60?item.description.substring(0,57)+'...':item.description):'-')+" ")]}},{key:"item.clients_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clients_count || 0)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format('MMMM D, YYYY'))+" ")]}}],null,true)})],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }