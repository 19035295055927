import { state as AuthState, getters as AuthGetters, actions as AuthActions, mutations as AuthMutations } from './Auth'
import { state as ClientState, getters as ClientGetters, actions as ClientActions, mutations as ClientMutations } from './Client'
import { state as CacheState, getters as CacheGetters, actions as CacheActions, mutations as CacheMutations } from './Cache'

export const state = {
  ...AuthState,
  ...ClientState,
  ...CacheState
}

export const getters = {
  ...AuthGetters,
  ...ClientGetters,
  ...CacheGetters
}

export const actions = {
  ...AuthActions,
  ...ClientActions,
  ...CacheActions
}

export const mutations = {
  ...AuthMutations,
  ...ClientMutations,
  ...CacheMutations
}
