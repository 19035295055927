<template>
  <div>
    <div class="skeleton_loader" v-if="!user">
      <div
        v-for="i in 3"
        :key="i"
        style="margin-bottom:24px;"
      >
        <v-skeleton-loader height="17px" width="400px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
        <v-skeleton-loader height="24px" width="200px" type="image"></v-skeleton-loader>
      </div>
    </div>

    <div v-if="user">

      <label class="text-body-2 grey--text text--darken-1">Email</label>
      <p class="text-body-1">{{user.email}}</p>

      <label class="text-body-2 grey--text text--darken-1">Centre</label>
      <p class="text-body-1">{{user.centre.name}}</p>

      <label class="text-body-2 grey--text text--darken-1">Role</label>
      <p class="text-body-1">{{user.roles[0].title}}</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'UserProfileView',
  props: {
    user: {
      required: true
    },
    ownAccount: {
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
</style>
