import config from '../../.secret.js'

export default function getEnv () {

  switch (window.location.protocol + '//' + window.location.host) {
    case 'https://staging.bcaafcemployment.ca':
      return config.staging
    case 'https://bcaafcemployment.ca':
      return config.production
    default:
      return config.localhost
  }

}
