import connection from '../connection'
// import store from '../../store'

// get all roles
// get one role

export default {

  async getAll () {
    try {
      const response = await connection.get('/roles')
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

