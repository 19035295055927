<template>
  <div>
    <div class="skeleton_loader" v-if="!client">
      <div
        v-for="i in 5"
        :key="i"
        style="margin-bottom:24px;"
      >
        <v-skeleton-loader height="17px" width="400px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
        <v-skeleton-loader height="24px" width="200px" type="image"></v-skeleton-loader>
      </div>
    </div>

    <div v-if="client">
      <div v-if="page==='client'">
        <label class="text-body-2 grey--text text--darken-1">Full name</label>
        <p class="text-body-1">
          {{client.first_name}} 
          {{client.middle_name}} 
          {{client.last_name}}
        </p>

        <label class="text-body-2 grey--text text--darken-1">Date of birth</label>
        <p class="text-body-1">{{moment(client.date_of_birth).format('MMMM D, YYYY')}}</p>

        <label class="text-body-2 grey--text text--darken-1">SIN</label>
        <p class="text-body-1">{{client.sin}}<v-icon color="red" v-if="!client.sin">mdi-alert-octagon-outline</v-icon></p>

        <label class="text-body-2 grey--text text--darken-1">Gender</label>
        <p class="text-body-1">{{client.gender || '-'}}</p>

        <label class="text-body-2 grey--text text--darken-1">Email</label>
        <p class="text-body-1">{{client.email}}<v-icon color="red" v-if="!client.email">mdi-alert-octagon-outline</v-icon></p>

        <label class="text-body-2 grey--text text--darken-1">Phone</label>
        <p class="text-body-1">{{client.phone}}<v-icon color="red" v-if="!client.phone">mdi-alert-octagon-outline</v-icon></p>

        <label class="text-body-2 grey--text text--darken-1">Address</label>
        <p class="text-body-1">
          <span v-if="!client.address && !client.city && !client.province && !client.postal_code"><v-icon color="red">mdi-alert-octagon-outline</v-icon></span>
          <span v-if="client.address || client.city || client.province || client.postal_code">
            {{client.address || '-'}}
            <br/>
            {{client.city || '-'}} {{client.province || '-'}}
            <br/>
            {{client.postal_code || '-'}}
          </span>
        </p>
      </div>

      <div v-if="page==='demographic'">
        <label class="text-body-2 grey--text text--darken-1">Marital status</label>
        <p class="text-body-1">{{client.marital_status}}<v-icon color="red" v-if="!client.marital_status">mdi-alert-octagon-outline</v-icon></p>

        <label class="text-body-2 grey--text text--darken-1">Number of dependents</label>
        <p class="text-body-1">{{client.number_dependents}}</p>

        <label class="text-body-2 grey--text text--darken-1">Do you have a disability?</label>
        <p class="text-body-1">{{client.disability}}<v-icon color="red" v-if="!client.disability">mdi-alert-octagon-outline</v-icon></p>

        <label class="text-body-2 grey--text text--darken-1">Have you ever been a Youth in Care?</label>
        <p class="text-body-1">{{client.youth_in_care}}<v-icon color="red" v-if="!client.youth_in_care">mdi-alert-octagon-outline</v-icon></p>

        <label class="text-body-2 grey--text text--darken-1">Languages spoken</label>
        <p class="text-body-1">{{client.language_spoken}}<v-icon color="red" v-if="!client.language_spoken">mdi-alert-octagon-outline</v-icon></p>

        <label class="text-body-2 grey--text text--darken-1">Citizenship</label>
        <p class="text-body-1">{{client.citizenship}}<v-icon color="red" v-if="!client.citizenship">mdi-alert-octagon-outline</v-icon></p>

        <label class="text-body-2 grey--text text--darken-1">Highest education received</label>
        <p class="text-body-1">{{client.highest_education}}<v-icon color="red" v-if="!client.highest_education">mdi-alert-octagon-outline</v-icon></p>
      </div>

      <div v-if="page==='ancestry'">
        <label class="text-body-2 grey--text text--darken-1">Do you identify as Indigenous?</label>
        <p class="text-body-1">{{client.indigenous}}<v-icon color="red" v-if="!client.indigenous">mdi-alert-octagon-outline</v-icon></p>

        <div v-if="client.indigenous==='Yes'">
          <label class="text-body-2 grey--text text--darken-1">Please indicate your ancestry</label>
          <p class="text-body-1">{{client.status_type}}<v-icon color="red" v-if="!client.status_type">mdi-alert-octagon-outline</v-icon></p>

          <label class="text-body-2 grey--text text--darken-1">Nation</label>
          <p class="text-body-1">{{client.nation || '-'}}</p>

          <label class="text-body-2 grey--text text--darken-1">Do you live on or off reserve?</label>
          <p class="text-body-1">{{client.reserve}}<v-icon color="red" v-if="!client.reserve">mdi-alert-octagon-outline</v-icon></p>

          <label class="text-body-2 grey--text text--darken-1">Do you speak an Indigenous language?</label>
          <p class="text-body-1">{{client.indigenous_language?'Yes':'No'}}</p>

          <div v-if="client.indigenous_language">
            <label class="text-body-2 grey--text text--darken-1">If yes, please specify which language(s)</label>
            <p class="text-body-1">{{client.indigenous_language || '-'}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var moment = require('moment')

export default {
  props: {
    client: {
      required: true
    },
    page: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      moment: moment
    }
  }
}
</script>

<style scoped lang="scss">
</style>
