<template>
  <v-navigation-drawer
    id="SideMenu"
    class="SideMenu"
    fixed
    color="blue-grey lighten-5"
    flat
    permanent
    disable-resize-watcher
    v-if="visible"
    :clipped="true"
  >

    <div class="content skeleton_loader" v-if="loading">
      <v-skeleton-loader height="14px" width="100px" type="image" style="margin-bottom:5px;"></v-skeleton-loader>
      <v-skeleton-loader height="52px" width="100%" type="image" style="margin-bottom:21px;"></v-skeleton-loader>

      <v-skeleton-loader height="14px" width="100px" type="image" style="margin-bottom:5px;"></v-skeleton-loader>
      <v-skeleton-loader height="52px" width="100%" type="image" style="margin-bottom:5px;"></v-skeleton-loader>
      <v-skeleton-loader height="52px" width="100%" type="image" style="margin-bottom:5px;"></v-skeleton-loader>
      <v-skeleton-loader height="28px" width="50%" type="image" style="margin-bottom:21px;display:inline-block;"></v-skeleton-loader>
      <v-skeleton-loader height="28px" width="50%" type="image" style="margin-bottom:21px;display:inline-block;"></v-skeleton-loader>

      <v-skeleton-loader height="14px" width="100px" type="image" style="margin-bottom:5px;"></v-skeleton-loader>
      <v-skeleton-loader height="52px" width="100%" type="image" style="margin-bottom:5px;"></v-skeleton-loader>
    </div>

    <div class="content" v-if="!loading">
      <div>
        <span class="text-overline">Profile</span>

        <v-btn
          block
          x-large
          class="white text--darken-3 font-weight-regular"
          active-class="primary"
          elevation="0"
          :to="{name:'ClientProfilePage',params:{clientId:client.client_id}}"
        >
          <v-icon>mdi-account</v-icon> Intake
        </v-btn>

      </div>

      <div class="pt-4">
        <span class="text-overline">Plans ({{plans.length}})</span>

        <v-btn
          block
          x-large
          class="text--darken-3 font-weight-regular"
          text
          disabled
          v-if="plans.length===0"
        >
          No Plans
        </v-btn>
        <div v-for="(plan, i) in plans" :key="plan.plan_id">
          <v-btn
            block
            x-large
            class="white text--darken-3 font-weight-regular"
            active-class="primary"
            elevation="0"
            :to="{name:'PlanProfilePage',params:{clientId:client.client_id,planId:plan.plan_id}}"
            v-if="visiblePlans>i"
          >
            <v-tooltip top v-if="plan.status==='Open'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  class="help-icon"
                  v-bind="attrs"
                  v-on="on"
                >mdi-checkbox-blank-circle-outline</v-icon>
              </template>
              <span>This plan has no outcome.</span>
            </v-tooltip>

            <v-tooltip top v-if="plan.status==='Closed'">
              <template v-slot:activator="{ on }">
                <v-icon 
                  class="help-icon"
                  v-on="on"
                >mdi-checkbox-marked-circle-outline</v-icon>
              </template>
              <span>This plan has an outcome.</span>
            </v-tooltip>
            
            <span>{{moment(plan.date).format('MMM YYYY')}}</span>
            
            <v-tooltip top v-if="plan._missing">
              <template v-slot:activator="{ on }">
                <v-icon 
                  class="help-icon"
                  color="red"
                  v-on="on"
                  style="padding-left:10px"
                >mdi-alert-octagon-outline</v-icon>
              </template>
              <span>This plan is missing required information.</span>
            </v-tooltip>
          </v-btn>
        </div>

        <div class="controls">
          <v-btn
            class="load"
            small
            text
            width="50%"
            elevation="0"
            :disabled="plans.length<=visiblePlans"
            @click="visiblePlans+=2"
          >
            Load More
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="hasOpenPlan?on:''" style="display:inline;" :class="hasOpenPlan?'help-icon':''">
                <v-btn
                  class="new"
                  small
                  text
                  width="50%"
                  elevation="0"
                  color="primary"
                  :disabled="hasOpenPlan"
                  :to="{name:'PlanProfileNewPage',params:{clientId:client.client_id}}"
                >
                  New Plan
                </v-btn>
              </div>
            </template>
            <span v-if="hasOpenPlan">There can only be one open plan at a time.</span>
          </v-tooltip>
        </div>
      </div>

      <div class="pt-4">
        <span class="text-overline">Stories ({{stories.length}})</span>

        <v-btn
          block
          x-large
          class="white text--darken-3 font-weight-regular"
          active-class="primary"
          elevation="0"
          :to="{name:'SuccessStories',params:{clientId:client.client_id}}"
        >
          <v-icon>mdi-star</v-icon> All Stories
        </v-btn>

      </div>

      <div class="pt-4">
        <span class="text-overline">Groups ({{client.groups.length}})</span>

        <v-btn
          block
          x-large
          class="text--darken-3 font-weight-regular"
          text
          disabled
          v-if="client.groups.length===0"
        >
          No Groups
        </v-btn>
        <div v-for="(group, i) in client.groups" :key="group.group_id">
          <v-btn
            block
            x-large
            class="white text--darken-3 font-weight-regular"
            active-class="primary"
            elevation="0"
            :to="{name:'GroupProfilePage',params:{groupId:group.group_id}}"
            v-if="visibleGroups>i"
          >            
            <span>{{group.name.length>15?group.name.substring(0,12)+'...':group.name}}</span>
          </v-btn>
        </div>

        <div class="controls">
          <v-btn
            class="load"
            small
            text
            width="50%"
            elevation="0"
            :disabled="client.groups.length<=visibleGroups"
            @click="visibleGroups+=2"
          >
            Load More
          </v-btn>
        </div>

      </div>

    </div>

  </v-navigation-drawer>
</template>

<script>
import { Api } from '@/gateways/endpoints'
var moment = require('moment')

export default {
  data () {
    return {
      loading: true,
      moment: moment,
      client: false,
      plans: false,
      stories: false,
      visiblePlans: 2,
      visibleGroups: 2,
    }
  },
  async created () {
    this.$store.watch((state) => {
      return this.$store.getters.getClient
    }, async (newValue, oldValue) => {
      this.client = await newValue
      if (this.client.client_id !== undefined)
        this.plans = await Api.Plans.getAllForClient(this.client.client_id)
        this.stories = await Api.Stories.getFromClient(this.client.client_id)
        this.client.groups = this.client.groups.sort((a, b) => (a.created_at < b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0)) // date newest -> oldest
        this.loading = false
    })
  },

  computed: {
    routeName () {
      return this.$route.name
    },
    visible () {
      const visiblePages = [
        'ClientProfilePage',
        'PlanProfilePage',
        'SuccessStories'
      ]
      if (
        visiblePages.includes(this.routeName) &&
        this.client
      ) {
        return true
      }
      return false
    },
    hasOpenPlan () {
      return this.plans.find((item) => item.status === 'Open') ? true : false
    }
  },

  watch: {
    '$route'(to, from) {
      if (parseInt(to.params.clientId) !== parseInt(from.params.clientId)) {
        this.loading = true
        this.visiblePlans = 2
      }
    }
  }
}
</script>

<style lang="scss">
.SideMenu {
  margin-top: 50px;

  .content {
    padding: 20px;
  }

  .v-btn {
    margin-bottom: 5px;

    &.v-btn--active {
      .v-icon {
        color: white!important;
      }
    }

    .v-icon {
      margin-right: 10px;
    }

    .v-btn__content {
      justify-content: start;
    }
  }

  .controls {
    .load {
      .v-btn__content {
        justify-content: start;
      }
    }
    .new {
      .v-btn__content {
        justify-content: end;
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .SideMenu {
    width: 220px!important;
  }
}
</style>
