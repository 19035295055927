var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedSpendings)?_c('v-container',{staticStyle:{"padding":"0"}},[_c('v-row',[(_vm.spendingEdit)?_c('v-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('p',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.selectedSpendings.spending_id===-1 ? 'New Line Item' : 'Edit Line Item'))]),_c('v-form',{ref:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.saveSpending.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"type":"date","label":"Date *","outlined":"","prepend-inner-icon":"mdi-calendar","rules":[
              _vm.rules.required,
              _vm.rules.noFutureDate
            ]},model:{value:(_vm.selectedSpendings.date),callback:function ($$v) {_vm.$set(_vm.selectedSpendings, "date", $$v)},expression:"selectedSpendings.date"}}),_c('v-text-field',{attrs:{"type":"text","label":"Item *","outlined":"","rules":[
              _vm.rules.required, 
              _vm.rules.maxLength(_vm.selectedSpendings.item,64)
            ]},model:{value:(_vm.selectedSpendings.item),callback:function ($$v) {_vm.$set(_vm.selectedSpendings, "item", $$v)},expression:"selectedSpendings.item"}}),_c('v-text-field',{attrs:{"type":"text","label":"Value","outlined":"","prefix":"$","rules":[
              _vm.rules.isMoney
            ]},model:{value:(_vm.selectedSpendings.value),callback:function ($$v) {_vm.$set(_vm.selectedSpendings, "value", $$v)},expression:"selectedSpendings.value"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"This is a work gear item"},model:{value:(_vm.selectedSpendings.work_gear),callback:function ($$v) {_vm.$set(_vm.selectedSpendings, "work_gear", $$v)},expression:"selectedSpendings.work_gear"}}),_c('v-textarea',{attrs:{"type":"text","label":"Description","outlined":"","rows":"5","counter":"1024","rules":[
              _vm.rules.maxLength(_vm.selectedSpendings.description,1024)
            ]},model:{value:(_vm.selectedSpendings.description),callback:function ($$v) {_vm.$set(_vm.selectedSpendings, "description", $$v)},expression:"selectedSpendings.description"}}),_c('FormActionButtons',{attrs:{"buttons":[
              'cancel',
              'save'
            ],"loading":_vm.loading,"noTopPadding":""},on:{"cancel":_vm.cancelSpendingEdit,"save":_vm.saveSpending}})],1)],1):_vm._e(),(!_vm.spendingEdit)?_c('v-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('FormActionButtons',{attrs:{"buttons":[],"customButton":{
            text: true,
            color: 'secondary',
            label: 'Back to spendings' ,
            icon: 'mdi-chevron-left',
            icon_start: true
          },"noPadding":""},on:{"custom":function($event){_vm.selectedSpendings=false}}}),_c('div',{staticClass:"pb-5"}),_c('label',{staticClass:"text-body-2 grey--text text--darken-1"},[_vm._v("Item")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.selectedSpendings.item))]),_c('label',{staticClass:"text-body-2 grey--text text--darken-1"},[_vm._v("Date")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.moment(_vm.selectedSpendings.date).format('MMMM D, YYYY')))]),_c('label',{staticClass:"text-body-2 grey--text text--darken-1"},[_vm._v("Value")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.formatMoney(_vm.selectedSpendings.value)))]),_c('label',{staticClass:"text-body-2 grey--text text--darken-1"},[_vm._v("This is a work gear item")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.selectedSpendings.work_gear?'Yes':'No'))]),_c('label',{staticClass:"text-body-2 grey--text text--darken-1"},[_vm._v("Description")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.selectedSpendings.description || '-'))]),_c('FormActionButtons',{attrs:{"buttons":[
            'edit',
            'delete'
          ],"loading":_vm.loading},on:{"edit":function($event){_vm.spendingEdit=true},"delete":_vm.deleteSpending}})],1):_vm._e()],1)],1):_vm._e(),(!_vm.spendings)?_c('v-container',{staticClass:"skeleton_loader",staticStyle:{"padding":"0"}},[_c('v-row',{staticStyle:{"padding":"0 16px"}},[_c('v-col',{attrs:{"sm":"4","md":"4","lg":"3"}},[_c('v-skeleton-loader',{staticStyle:{"margin-bottom":"2px"},attrs:{"height":"28px","width":"200px","type":"image"}}),_c('v-skeleton-loader',{attrs:{"height":"24px","width":"100px","type":"image"}})],1),_c('v-col',{attrs:{"sm":"4","md":"4","lg":"3"}},[_c('v-skeleton-loader',{staticStyle:{"margin-bottom":"2px"},attrs:{"height":"28px","width":"200px","type":"image"}}),_c('v-skeleton-loader',{attrs:{"height":"24px","width":"100px","type":"image"}})],1),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"sm":"4","md":"4","lg":"6"}},[_c('v-skeleton-loader',{staticStyle:{"float":"right"},attrs:{"height":"36px","width":"165px","type":"image"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody, table-tfoot"}})],1)],1)],1):_vm._e(),(_vm.spendings && !_vm.selectedSpendings)?_c('v-container',{staticStyle:{"padding":"0"}},[_c('v-row',{staticStyle:{"padding":"0 16px"}},[_c('v-col',{attrs:{"sm":"4","md":"4","lg":"3"}},[_c('p',{staticClass:"subtitle-1 font-weight-medium mb-0 pb-0"},[_vm._v("TOTAL CASE SPENDING")]),_c('p',{staticClass:"body-1 mb-0 pb-0"},[_vm._v(_vm._s(_vm.formatMoney(_vm.caseTotal)))])]),_c('v-col',{attrs:{"sm":"4","md":"4","lg":"3"}},[_c('p',{staticClass:"subtitle-1 font-weight-medium mb-0 pb-0"},[_vm._v("TOTAL FISCAL SPENDING")]),_c('p',{staticClass:"body-1 mb-0 pb-0"},[_vm._v(_vm._s(_vm.formatMoney(_vm.fiscalTotal)))])]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"sm":"4","md":"4","lg":"6"}},[_c('FormActionButtons',{attrs:{"buttons":[],"customButton":{
            text: false,
            color: 'primary',
            label: 'New Line Item' ,
            icon: 'mdi-plus'
          },"noPadding":""},on:{"custom":_vm.newSpending}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.spendings,"sort-by":"date","sort-desc":""},scopedSlots:_vm._u([{key:"item.item",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.selectedSpendings=Object.assign({},item)}}},[_vm._v(" "+_vm._s(item.item.length>32 ? item.item.substring(0,32)+"..." : item.item)+" ")])]}},{key:"item.description",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.description ? (item.description.length>64 ? item.description.substring(0,64)+"..." : item.description) : '-')+" ")]}},{key:"item.work_gear",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.work_gear ? 'mdi-check' : ''))])]}},{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date).format('MMM D, YYYY'))+" ")]}},{key:"item.value",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.value))+" ")]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.deleteModal)?_c('DeleteModal',{attrs:{"title":"Delete Line Item","text":"Are you sure you wish to delete the current line item?"},on:{"confirm":_vm.deleteSpendingModalConfirm,"cancel":_vm.deleteSpendingModalCancel}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }