<template>
  <div class="UserProfileEditForm" v-if="user && rules && dropdowns.centres && dropdowns.roles">

    <v-form 
      ref="UserProfileEditForm"
      @submit.prevent="saveUser" 
      novalidate
    >

      <div v-if="editType==='info'">
        <v-text-field
          type="text"
          v-model="user.first_name"
          label="First Name *"
          outlined
          :rules="[
            rules.required, 
            rules.maxLength(user.first_name,64)
          ]"
        ></v-text-field>

        <v-text-field
          type="text"
          v-model="user.last_name"
          label="Last Name *"
          outlined
          :rules="[
            rules.required, 
            rules.maxLength(user.first_name,64)
          ]"
        ></v-text-field>

        <v-text-field
          type="email"
          v-model="user.email"
          label="Email *"
          outlined
          :rules="[
            rules.required, 
            rules.maxLength(user.first_name,128),
            rules.isEmail
          ]"
        ></v-text-field>

        <div v-if="ownAccount.user_id!==user.user_id">
          <v-select
            :items="[{name:'None',centre_id:-1}].concat(dropdowns.centres)"
            item-text="name"
            item-value="centre_id"
            v-model="user.centre.centre_id"
            label="Centre *"
            outlined
            :rules="[
              rules.required
            ]"
          ></v-select>
          <v-select
            :items="dropdowns.roles"
            item-text="title"
            item-value="role_id"
            v-model="user.roles[0].role_id"
            label="Role *"
            outlined
            :rules="[
              rules.required
            ]"
          ></v-select>
        </div>
      </div>

      <div v-if="ownAccount.user_id===user.user_id && editType==='password'">

        <v-card
          class="mb-8"
          max-width="100%"
          v-if="user && ownAccount"
          elevation="0"
          style="border:1px solid #E0E0E0;padding:0px;"
        >
          <v-card-text>
            <span class="text-body-1 font-weight-bold">New Password Requirements</span>
            <div class="text-body-1">
              <div>
                <v-icon color="green" small v-if="passwordScore.checks.length">mdi-check</v-icon> 
                <v-icon color="red" small v-if="!passwordScore.checks.length">mdi-close</v-icon> 
                Must be at least 8 characters
              </div>
              <div>
                <v-icon color="green" small v-if="passwordScore.checks.uppercase">mdi-check</v-icon> 
                <v-icon color="red" small v-if="!passwordScore.checks.uppercase">mdi-close</v-icon> 
                Must contain at least one uppercase letter
              </div>
              <div>
                <v-icon color="green" small v-if="passwordScore.checks.lowercase">mdi-check</v-icon> 
                <v-icon color="red" small v-if="!passwordScore.checks.lowercase">mdi-close</v-icon> 
                Must contain at least one lowercase letter
              </div>
              <div>
                <v-icon color="green" small v-if="passwordScore.checks.number">mdi-check</v-icon> 
                <v-icon color="red" small v-if="!passwordScore.checks.number">mdi-close</v-icon> 
                Must contain at least one number
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-text-field
          type="password"
          v-model="newPassword.new"
          label="New Password"
          outlined
          loading
          :rules="[
            rules.checkPasswordStrength(newPassword.new).pass
          ]"
        >
          <template v-slot:progress>
            <v-progress-linear
              :value="passwordScore.score*20"
              :color="['red','orange','amber','light-green','green'][passwordScore.score-1]"
              absolute
              height="7"
            ></v-progress-linear>
          </template>
        </v-text-field>

        <v-text-field
          type="password"
          v-model="newPassword.confirm"
          label="Confirm Password"
          outlined
          :rules="[
            rules.mustMatch(newPassword.confirm, newPassword.new, 'Passwords must match')
          ]"
        ></v-text-field>

      </div>

      <FormActionButtons
        :buttons="[
          'cancel',
          'save'
        ]" 
        noTopPadding
        @cancel="$emit('cancel')"
        @save="saveUser"
      />
    </v-form>

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  name: 'UserProfileEditForm',
  components: {
    FormActionButtons
  },
  props: {
    user: {
      required: true
    },
    ownAccount: {
      required: true
    },
    editType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rules: false,
      dropdowns: {
        centres: false,
        roles: false
      },
      newPassword: {
        new: '',
        confirm: '',
      }
    }
  },

  beforeMount () {
    if ( this.user.centre === null ) {
      this.user.centre = {
        centre_id: -1,
        name: 'None'
      }
    }
  },
  
  async created() {
    if(this.ownAccount.roles[0].role_id===1) {
      this.dropdowns.centres = await Api.Centres.getAll()
    } else {
      this.dropdowns.centres = true
    }
    this.dropdowns.roles = await Api.Roles.getAll()
    this.rules = await validationRules
  },

  methods: {
    async saveUser() {
      if( !this.$refs.UserProfileEditForm.validate() ) return

      // check if a centre was selected
      if (this.user.centre.centre_id===-1) {
        this.user.centre = null
      }

      // save profile
      const response = await Api.Users.update(this.user.user_id, this.user)
      if (response) {
        
        // save password
        if (this.newPassword.new.length > 0) {
          const response = await Api.Users.changePassword(this.user.user_id, this.newPassword.new)
        }

        this.$toast.success('The user was successfully updated.');
        this.$emit('save')
        return
      }
      this.$toast.error('Something went wrong. Please try again.');
    }
  },

  computed: {
    passwordScore() { 
      return this.rules.checkPasswordStrength(this.newPassword.new)
    }
  }
}
</script>

<style scoped lang="scss">
.v-progress-linear {
  margin-top: -6px;
  z-index: -1;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
