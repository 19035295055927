<template>
  <v-container>
    <ClientInteractionElement
      :cancelButton="false"
    />
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import ClientInteractionElement from '@/components/elements/ClientInteractionElement.vue'

export default {
  components: {
    ClientInteractionElement
  },
  props: {
  },
  data () {
    return {
    }
  },
  
  async created() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>
