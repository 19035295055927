<template>
  <div>

    <DraggableColumns
      :settings="settings"
      :activeColumn="activeBarriers"
      :masterColumn="masterBarriers"

      :loading="loading"

      @save="saveBarriers"
    />

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import DraggableColumns from '@/components/elements/DraggableColumns'

export default {
  components: {
    DraggableColumns
  },
  props: {
    planId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      settings: {
        item: 'Barrier',
        arrayKey: 'barrier_id',
        arrayTitle: 'name',
        arrayCategory: 'category',
        arrayDescription: 'description',
        columnOneTitle: 'Plan Barriers',
        columnTwoTitle: 'Add Barriers',
        emptyStringView: 'There are no barriers on this plan.',
        emptyStringEdit: 'There are no barriers on this plan.<br/>You can add some from the right column.'
      },
      activeBarriers: false,
      masterBarriers: false,
      loading: false,
    }
  },
  created () { 
    this.refreshData()
  },
  methods: {
    async refreshData () {
      this.activeBarriers = await Api.Barriers.getFromPlan(this.planId)
      this.masterBarriers = await Api.Barriers.getAll()
    },

    async saveBarriers (arrayOne) {
      this.loading = true
      this.activeBarriers = arrayOne

      const response = await Api.Barriers.updateToPlan(this.planId, this.activeBarriers)
      if (response) {
        this.loading = false
        this.$toast.success('The plan was successfully updated.')
        return
      } else {
        this.loading = false
        this.$toast.error('Something went wrong. Please try again.')
        return
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">

</style>
