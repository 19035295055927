<template>

  <v-container class="pa-0">
    <v-row class="mb-15" justify-md="space-between" justify="center" style="max-height:600px;">
      <v-col xl="5" lg="5" md="6" sm="8" align-self="center">

        <div class="title">
          <h2 class="text-h2 font-weight-regular mb-4">Help Centre</h2>
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">
            How can we help you?
          </h5>
        </div>
        <v-form novalidate @submit.prevent="searchHelp" style="display:flex;">
          <v-text-field
            label="Search Help"
            type="text"
            outlined
            hide-details
            v-model="search"
            style=""
          />
          <v-btn 
            color="primary" 
            x-large 
            @click="searchHelp"
            elevation="0"
            style="height:56px;min-width:56px;max-width:56px;margin-left:10px;"
          ><v-icon>mdi-magnify</v-icon></v-btn>
        </v-form>

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex" align-self="center">
        <div id="bg-block" style="height:600px;"></div>
        <InlineSvg 
          src="/images/svgs/undraw_searching.svg" 
          width="100%"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col 
        cols="4"
        v-for="(category,i) in cleanedHelpFiles"
        :key="i"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            max-width="100%"
            :to="{name:'HelpPage.Category',params:{helpCategory:category.link}}"
            :color="'blue ' + (hover?'lighten-4':'lighten-5')"
            style="transition:all 0.3s ease;"
            elevation="0"
          >
            <InlineSvg 
              height="175px"
              width="100%"
              class="pa-3"
              :src="'/images/svgs/'+category.image+'.svg'" 
            />

            <div class="blue lighten-4">
              <v-card-title class="pb-0">{{category.title}}</v-card-title>
              <v-card-text class="text-subtitle-1">
                {{category.pages.length}} Article(s)
              </v-card-text>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
  },
  props: {
    helpFiles: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      search: ''
    }
  },

  created () {
    this.ownUser = this.$store.getters.getUser
  },

  methods: {
    searchHelp () {
      this.$router.push({name:'HelpPage.Search',query:{q:this.search}})
    }
  },

  computed: {
    cleanedHelpFiles () {
      let cleaned = []
      this.helpFiles.forEach((category,i) => {
        if (!category.access.includes(this.ownUser.roles[0].title)) return
        cleaned.push(category)
      })
      return cleaned
    }
  }
}
</script>

<style lang="scss">
</style>
