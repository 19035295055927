
const moment = require('moment')

// state variables
export const state = {
  trainings: null,
  supports: null,
  barriers: null
}

// fucntions that return state variables
export const getters = {
  getTrainings: (state) => {
    if (state.trainings === null) return null
    if (moment().diff(state.trainings.timestamp, 'hours') >= 168) {
      return null
    }
    return state.trainings
  },
  getSupports: (state) => {
    if (state.supports === null) return null
    if (moment().diff(state.supports.timestamp, 'hours') >= 168) {
      return null
    }
    return state.supports
  },
  getBarriers: (state) => {
    if (state.barriers === null) return null
    if (moment().diff(state.barriers.timestamp, 'hours') >= 168) {
      return null
    }
    return state.barriers
  }
}

// functions that directly change state
export const mutations = {
  setTrainings (state, trainings) {
    state.trainings = {
      timestamp: moment(),
      data: trainings
    }
  },
  setSupports (state, supports) {
    state.supports = {
      timestamp: moment(),
      data: supports
    }
  },
  setBarriers (state, barriers) {
    state.barriers = {
      timestamp: moment(),
      data: barriers
    }
  }
}

// functions to initiate changes to state
export const actions = {
  clearCache (context) {
    context.commit('setTrainings', null)
    context.commit('setSupports', null)
    context.commit('setBarriers', null)
  },

  setTrainings (context, trainings) {
    context.commit('setTrainings', trainings)
  },
  setSupports (context, supports) {
    context.commit('setSupports', supports)
  },
  setBarriers (context, barriers) {
    context.commit('setBarriers', barriers)
  }
}
