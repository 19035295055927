import { checkAuth } from '../functions'
import SuccessStories from '@/views/Stories/SuccessStories'

export default [
  {
    path: '/client/:clientId/stories',
    name: 'SuccessStories',
    component: SuccessStories,
    props: (route) => {
      return {
        clientId: parseInt(route.params.clientId)
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'Stories'
    }
  }
]
