<template>
  <div>
    <div class="skeleton_loader" v-if="!centre">
      <!-- <v-skeleton-loader height="200px" width="200px" type="image" style="margin-bottom:20px;"></v-skeleton-loader> -->
      <div style="margin-bottom:24px;">
        <v-skeleton-loader height="17px" width="400px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
        <v-skeleton-loader height="24px" width="200px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
        <v-skeleton-loader height="24px" width="200px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
        <v-skeleton-loader height="24px" width="200px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
      </div>
      <div style="margin-bottom:24px;">
        <v-skeleton-loader height="17px" width="400px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
        <v-skeleton-loader height="24px" width="200px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
      </div>
    </div>

    <div v-if="centre">

      <!-- <v-img 
        contain
        v-if="centre.logo"
        :src="centre.logo" 
        width="200"
        height="200"
        class="mb-5"
      ></v-img> -->

      <label class="text-body-2 grey--text text--darken-1">Address</label>
      <p class="text-body-1">
        {{centre.address}}
        <br/>
        {{centre.city}} {{centre.province}}
        <br/>
        {{centre.postal_code}}
      </p>

      <label class="text-body-2 grey--text text--darken-1">Phone Number</label>
      <p class="text-body-1">{{centre.phone}}</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'CentreProfileView',
  props: {
    centre: {
      required: true
    }
  },
}
</script>

<style scoped lang="scss">
</style>