<template>
  <v-container>
    <v-row justify-md="space-between" justify="center" align="center">
      <v-col xl="4" lg="5" md="6" sm="8">

        <LoginForm />

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex">

        <div id="bg-block"></div>
        <div id="bg-image"><InlineSvg src="/images/svgs/undraw_enter.svg" /></div>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm.vue'

export default {
  name: 'LoginPage',
  components: {
    LoginForm
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  margin-top: -75px;
  margin-bottom: -50px;

  .row {
    min-height: 100vh;
  }
}
</style>
