import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCompositionAPI from '@vue/composition-api'
import InlineSvg from 'vue-inline-svg'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

Vue.config.productionTip = false
Vue.component('InlineSvg', InlineSvg)

Vue.use(Toast, {
  position: 'top-center',
  timeout: 3000
})

console.info('Version: ' + require('../package.json').version)

new Vue({
  router,
  VueCompositionAPI,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
