<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <div class="title d-flex align-center justify-space-between">
          <h2 class="text-h2 font-weight-regular">Clients</h2>
          <v-btn
            color="primary"
            elevation="0"
            large
            rounded
            :to="{name:'ClientProfileNewPage'}"
            v-if="ownUser.roles[0].role_id!==1"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
            New Client
          </v-btn>
        </div>

        <ClientsTable :clients="clients" />

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import ClientsTable from '@/components/Clients/ClientsTable'

export default {
  name: 'ClientsPage',
  components: {
    ClientsTable
  },
  data () {
    return {
      clients: undefined,
      search: '',
      ownUser: null,
    }
  },

  created () {
    this.ownUser = this.$store.getters.getUser
  },

  async mounted() {
    this.updateClients()
  },
  methods: {
    async updateClients() {
      this.clients = undefined
      this.search = this.$route.query.s || ''
      // CONSOLE
      // console.time('get_all_clients')
      this.clients = await Api.Clients.getAll(this.search)
      // console.timeEnd('get_all_clients')
    }
  },

  watch: {
    $route (to, from){
      this.updateClients()
    }
  },
}
</script>

<style scoped lang="scss">
table {
  width: 100%;
}
</style>
