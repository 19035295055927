<template>
  <div>

    <TrainingColumns
      :activeColumn="activeTrainings"
      :masterColumn="masterTrainings"

      :loading="loading"

      @save="saveTrainings"
    />

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import TrainingColumns from '@/components/Plans/elements/TrainingColumns'

export default {
  components: {
    TrainingColumns
  },
  props: {
    planId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      activeTrainings: false,
      masterTrainings: false,
      loading: false,
    }
  },
  created () { 
    this.refreshData()
  },
  methods: {
    async refreshData () {
      this.activeTrainings = await Api.Trainings.getFromPlan(this.planId)
      this.masterTrainings = await Api.Trainings.getAll()
    },

    async saveTrainings (arrayOne) {
      this.loading = true
      this.activeTrainings = arrayOne

      const response = await Api.Trainings.updateToPlan(this.planId, this.activeTrainings)
      if (response) {
        this.loading = false
        this.$toast.success('The plan was successfully updated.')
        return
      } else {
        this.loading = false
        this.$toast.error('Something went wrong. Please try again.')
        return
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">

</style>
