<template>
  <div class="ForgotPasswordForm" v-if="rules">

    <div class="img-holder">
      <img src="/images/bcaafc.png" />
    </div>

    <div 
      class="text-center"
      v-if="requestSent">
      <div class="text-h3">Check your inbox</div>
      <br/>
      <p>An email has been sent to <b>{{requestUser}}</b> with a link to set a new password.</p>
      <p v-if="returnValues.token"><a :href="'http://localhost/login/reset-password?token='+returnValues.token+'&id='+returnValues.id">Reset Link (FOR DEMO ONLY)</a></p>
    </div>

    <v-form 
      ref="form"
      @submit.prevent="forgotPassword" 
      novalidate
      v-if="!requestSent"
    >
      <div class="title text-center">
        <span class="text-h3">Forgot Password</span>
      </div>

      <p class="text-center">Please enter you email address below and a reset link will be sent to you.</p>

      <v-text-field
        type="text"
        v-model="requestUser"
        label="Email *"
        outlined
        :disabled="requestSent"
        :rules="[
          rules.required(requestUser, 'Email is required'),
          rules.maxLength(requestUser, 128, 'Email must less than 128 characters'),
          rules.isEmail(requestUser, 'Must be a valid email')
        ]"
      ></v-text-field>

      <v-btn 
        type="submit"
        color="primary"
        block
        x-large
        :loading="loading"
        :disabled="loading || requestSent"
        elevation="0"
      >
        Request New Password <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-form>

    <br/>
    <div class="text-center">
      <router-link :to="{name:'LoginPage'}">Back to Login</router-link>
    </div>

  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'

export default {
  name: 'ForgotPasswordForm',
  created() {
    this.rules = validationRules
  },

  data() {
    return {
      rules: false,
      requestSent: false,
      requestUser: '',
      returnValues: {
        token: null,
        id: null
      },
      loading: false,
    }
  },
  validations() {
    return {
      requestUser: {
        required,
        email,
        maxLength: maxLength(128)
      }
    }
  },

  methods: {
    async forgotPassword(e){
      if( !this.$refs.form.validate() ) return
      this.loading = true

      const response = await Api.Auth.forgotPassword(this.requestUser)
      if (response) {
        this.loading = false
        this.requestSent = true
        this.returnValues.token = response.token
        this.returnValues.id = response.id
        // alert('Success. An email has been sent')
        return
      } else {
        this.loading = false
        // alert('Fail')
        return
      }

    }
  }
}
</script>

<style scoped lang="scss">
.img-holder {
  text-align: center;

  img {
    width: 80%;
    max-width: 200px;
    margin-bottom: 0px;
  }
}
</style>
