<template>
  <div>
    <div class="skeleton_loader" v-if="!plan">
      <div
        v-for="i in 5"
        :key="i"
        style="margin-bottom:24px;"
      >
        <v-skeleton-loader height="17px" width="400px" type="image" style="margin-bottom:2px;"></v-skeleton-loader>
        <v-skeleton-loader height="24px" width="200px" type="image"></v-skeleton-loader>
      </div>
    </div>
    <div v-if="plan">
      
      <div class="PlanInfoView" v-if="!edit" >
        <label class="text-body-2 grey--text text--darken-1">Plan Start Date</label>
        <p class="text-body-1">{{ moment(plan.date).format('MMMM D, YYYY') }}</p>

        <label class="text-body-2 grey--text text--darken-1">Current level of employment</label>
        <p class="text-body-1">{{plan.current_employment}}<v-icon color="red" v-if="!plan.current_employment">mdi-alert-octagon-outline</v-icon></p>

        <div v-if="plan.current_employment === ('Employed' || 'Self-employed')">
          <label class="text-body-2 grey--text text--darken-1">Employment type</label>
          <p class="text-body-1">{{plan.employment_type}}<v-icon color="red" v-if="!plan.employment_type">mdi-alert-octagon-outline</v-icon></p>
        </div>

        <label class="text-body-2 grey--text text--darken-1">Are you an apprentice?</label>
        <p class="text-body-1">{{plan.apprentice}}<v-icon color="red" v-if="!plan.apprentice">mdi-alert-octagon-outline</v-icon></p>

        <label class="text-body-2 grey--text text--darken-1">Are you accessing other supports in the community?</label>
        <p class="text-body-1">{{plan.accessing_supports || '-'}}</p>

        <label class="text-body-2 grey--text text--darken-1">Are you willing to relocate for training?</label>
        <p class="text-body-1">{{plan.relocate || '-'}}</p>

        <label class="text-body-2 grey--text text--darken-1">Do you have access to reliable transportation?</label>
        <p class="text-body-1">{{plan.transportation || '-'}}</p>

        <FormActionButtons
          v-if="!edit"
          :buttons="[
            'edit',
            'delete'
          ]" 
          :loading="loading"
          @edit="edit=true"
          @delete="deleteModal=true"
        />
      </div>
      
      <v-form 
        ref="form"
        @submit.prevent="savePlan" 
        novalidate
        v-if="edit"
      >

        <v-text-field
          type="date"
          v-model="planEditing.date"
          label="Plan start date *"
          outlined
          prepend-inner-icon="mdi-calendar"
          :rules="[
            rules.required,
            rules.noFutureDate
          ]"
        />

        <v-select
          :items="['Unemployed','Employed','Self-employed','Not in the labour force']"
          v-model="planEditing.current_employment"
          label="Current level of employment"
          outlined
        ></v-select>

        <v-select
          :items="['Casual','Seasonal','Temporary','Insufficient hours', 'Permanent', 'Prefer not to report']"
          v-model="planEditing.employment_type"
          label="Employment type"
          v-if="planEditing.current_employment === ('Employed' || 'Self-employed')"
          outlined
        ></v-select>

        <v-select
          :items="['Yes','No','Prefer not to say']"
          v-model="planEditing.apprentice"
          label="Are you an apprentice?"
          outlined
        ></v-select>

        <v-select
          :items="['Yes','No','Prefer not to say']"
          v-model="planEditing.accessing_supports"
          label="Are you accessing other supports in the community?"
          outlined
        ></v-select>

        <v-select
          :items="['Yes','No','Prefer not to say']"
          v-model="planEditing.relocate"
          label="Are you willing to relocate for training?"
          outlined
        ></v-select>

        <v-select
          :items="['Yes','No','Prefer not to say']"
          v-model="planEditing.transportation"
          label="Do you have access to reliable transportation?"
          outlined
        ></v-select>

        <FormActionButtons
          :buttons="[
            'cancel',
            'save'
          ]" 
          noTopPadding
          @cancel="edit=false;resetData()"
          @save="savePlan"
        />

      </v-form>   

      <DeleteModal
        v-if="deleteModal"
        title="Delete Plan"
        :text="`Are you sure you wish to delete the current plan?`"
        @confirm="deletePlanModalConfirm"
        @cancel="deletePlanModalCancel" 
      />
    </div>
  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'

import DeleteModal from '@/components/elements/DeleteModal.vue'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
var moment = require('moment')

export default {
  name: 'PlanPageInfo',
  components: {
    DeleteModal,
    FormActionButtons
  },
  props: {
    planId: {
      type: Number,
      required: true
    },
    clientId: {
      type: Number,
      required: true
    } 
  },
  data () {
    return {
      moment: moment,
      plan: false,
      planEditing: false,
      edit: false,
      deleteModal: false,
      loading: false
    }
  },
  
  async created() {
    this.rules = await validationRules
    await this.refreshData()
  },
  methods: {
    resetData () {
      this.planEditing = Object.assign({}, this.plan)
    },
    async refreshData () {
      this.plan = await Api.Plans.getSingle(this.planId)
      this.planEditing = Object.assign({}, this.plan)
    },

    async savePlan() {
      if( !this.$refs.form.validate() ) return

      const response = await Api.Plans.update(this.planId, this.planEditing)
      if (response) {
        this.edit = false
        this.refreshData()
        this.$toast.success('The plan was successfully updated.')
        return
      }
      this.$toast.error('Something went wrong. Please try again.')
    },

    deletePlan () {
      this.deleteModal = true
    },
    async deletePlanModalConfirm () {
      this.deleteModal = false
      this.loading = true
      const response = await Api.Plans.delete(this.planId)
      if (response) {
        this.loading = false
        this.$toast.success('The plan was successfully deleted.')
        this.$router.push({name:'ClientProfilePage',params:{clientId:this.clientId}})
        return
      }
      this.loading = false
      this.$toast.error('Something went wrong. Please try again.')
    },
    deletePlanModalCancel () {
      this.deleteModal = false
    }
  },

  watch: {
    planId(newValue, oldValue){
      this.plan = false
      this.refreshData()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
