import connection from '../connection'
// import store from '../../store'

async function sendCleanCentres (centre) {

  // convert all empty strings to nulls
  centre = Object.keys(centre).reduce((acc, key) => { acc[key] = centre[key] === '' ? null : centre[key]; return acc }, {})

  return centre
}

export default {

  async getAll () {
    try {
      const response = await connection.get('/centres')
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async getSingle (centreId) {
    try {
      const response = await connection.get('/centres/' + centreId)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (centreId, centreData) {
    try {
      centreData = await sendCleanCentres(centreData)
      const response = await connection.put('/centres/' + centreId, centreData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (centreData) {
    try {
      centreData = await sendCleanCentres(centreData)
      const response = await connection.post('/centres', centreData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (centreId) {
    try {
      const response = await connection.delete('/centres/' + centreId)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

