import store from '@/store'
import router from '@/router'
// import vuetify from '@/plugins/vuetify'

export default function homePage (vuetify) {
  const ownUser = store.getters.getUser
  if (ownUser.roles[0].role_id === 3) {
    // if screen is small or x-small
    if (vuetify.breakpoint.name === 'sm' || vuetify.breakpoint.name === 'xs') {
      router.push({ name: 'UserProfileViewPage', params: { userId: ownUser.user_id } })
    } else {
      router.push({ name: 'ReportPage' })
    }
  } else {
    if (vuetify.breakpoint.name === 'sm' || vuetify.breakpoint.name === 'xs') {
      router.push({ name: 'ClientInteractionPage' })
    } else {
      router.push({ name: 'ClientsPage' })
    }
  }
}
