const colorsAlpha = {
  a: 'red',
  b: 'pink',
  c: 'purple',
  d: 'deep-purple',
  e: 'indigo',
  f: 'blue',
  g: 'light-blue',
  h: 'cyan',
  i: 'green',
  j: 'orange',
  k: 'deep-orange',
  l: 'brown',
  m: 'red',
  n: 'pink',
  o: 'purple',
  p: 'deep-purple',
  q: 'indigo',
  r: 'blue',
  s: 'light-blue',
  t: 'cyan',
  u: 'green',
  v: 'orange',
  w: 'deep-orange',
  x: 'brown',
  y: 'red',
  z: 'pink'
}

export default colorsAlpha
