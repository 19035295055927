<template>
  <v-container>
    <v-row justify-md="space-between" justify="center">
      <v-col xl="5" lg="5" md="6" sm="8">

        <div class="title">
          <h2 class="text-h2 font-weight-regular">New Intake</h2>
        </div>

        <ClientProfileNewForm
          @pageNumber="updatePageNumber"
          @leave="checkBeforeLeaving=false" 
        />

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex">

        <div id="bg-block"></div>
        <div id="bg-image"><InlineSvg :src="`/images/svgs/undraw_intake_${pageNumber}.svg`" /></div>
        
      </v-col>
    </v-row>

    <DeleteModal
      v-if="showDialogue"
      title="Cancel New Intake?"
      :text="`Are you sure you wish to cancel the new intake? All unsaved data will be lost.`"
      type="leave"
      @confirm="confirmLeavePage"
      @cancel="cancelLeavePage" 
    />

  </v-container>
</template>

<script>
import ClientProfileNewForm from '@/components/Clients/ClientProfileNewForm'
import DeleteModal from '@/components/elements/DeleteModal'

export default {
  name: 'ClientProfileNewPage',
  components: {
    ClientProfileNewForm,
    DeleteModal
  },
  data () {
    return {
      pageNumber: 1,
      to: null,
      showDialogue: false,
      checkBeforeLeaving: true,
      pageScroll: 0
    }
  },

  created () {
    this.pageScroll = window.pageYOffset
  },

  methods: {
    updatePageNumber (num) {
      this.pageNumber = num
    },

    cancelLeavePage () {
      this.to = null
      this.showDialogue = false
    },
    confirmLeavePage () {
      this.$router.push(this.to)
    }
  },

  beforeRouteLeave (to, from, next) {
    if (this.to || to.name==='LoginPage' || !this.checkBeforeLeaving){
      next()
    }
    this.to = to
    this.showDialogue = true
  }
}
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 2.188rem;
}
</style>
