import connection from '../connection'
// import store from '../../store'

export default {

  async getFromClient (clientId) {
    try {
      const response = await connection.get(`/clients/${clientId}/stories`)
      if (!response) return false
      const sorted = response.data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date > a.date) ? -1 : 0)) // date newest -> oldest
      return sorted
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async update (storyId, storyData) {
    try {
      const response = await connection.put(`/stories/${storyId}`, storyData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async create (storyData) {
    try {
      delete storyData.story_id
      const response = await connection.post('/stories', storyData)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  },

  async delete (storyId) {
    try {
      const response = await connection.delete(`/stories/${storyId}`)
      if (!response) return false
      return response.data
    } catch (err) {
      console.error('Fail. ' + err.message)
      return false
    }
  }

}

