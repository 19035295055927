import { checkIfAlreadyLoggedIn } from '../functions'
import LoginPage from '@/views/Login/LoginPage'
import ForgotPasswordPage from '@/views/Login/ForgotPasswordPage'
import ResetPasswordPage from '@/views/Login/ResetPasswordPage'

export default [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    beforeEnter: checkIfAlreadyLoggedIn,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/login/forgot',
    component: ForgotPasswordPage,
    name: 'ForgotPasswordPage',
    beforeEnter: checkIfAlreadyLoggedIn,
    meta: {
      title: 'Forgot Password'
    }
  },
  {
    path: '/login/reset-password',
    component: ResetPasswordPage,
    name: 'ResetPasswordPage',
    beforeEnter: checkIfAlreadyLoggedIn,
    meta: {
      title: 'Reset Password'
    }
  }
]
