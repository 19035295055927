<template>
  <v-dialog
    v-model="dialog"
    width="500"
    @click:outside="cancel"
  >
    <v-card>
      <v-card-title class="text-h5 font-weight-medium">
        <v-icon color="red darken-2">mdi-close-circle</v-icon>
        <span v-html="title"></span>
      </v-card-title>

      <v-card-text class="text-h6 font-weight-light pb-0">
        <p><span v-html="text"></span></p>

        <p v-if="type==='delete'&&strongWarning===true"><b>This action cannot be undone.</b></p>

        <v-text-field
          id="confirmString"
          type="text"
          outlined
          v-model="confirmString"
          v-if="hardConfirm"
          :rules="[
            (v) => v.toUpperCase()==='DELETE' || '',
          ]"
        >
          <template v-slot:label>
            Type <b>DELETE</b> to confirm this action
          </template>
        </v-text-field>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions v-if="type==='delete'">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          color="red darken-2"
          text
          :disabled="!canDelete"
          @click="confirm"
        >
          {{deleteButtonText}}
        </v-btn>
      </v-card-actions>

      <v-card-actions v-if="type==='leave'">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="cancel"
        >
          No, Continue
        </v-btn>
        <v-btn
          color="red darken-2"
          text
          @click="confirm"
        >
          Yes, Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    deleteButtonText: {
      type: String,
      required: false,
      default: 'delete'
    },
    strongWarning: {
      type: Boolean,
      required: false,
      default: true,
    },
    hardConfirm: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'delete'
    }
  },
  data() {
    return {
      dialog: true,
      confirmString: ''
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      if (this.canDelete) {
        this.$emit('confirm')
      }
    }
  },
  computed: {
    canDelete() {
      if(this.hardConfirm) {
        if(this.confirmString.toUpperCase() === 'DELETE'){
          return true
        }
        else {
          return false
        }
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-input {
  margin-bottom: 0;
  margin-top: 1.2rem;
}
.v-card__title span {
  margin-left: 5px;
}
</style>
<style lang="scss">
#confirmString {
  text-transform: uppercase;
}
</style>
