var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.group || !_vm.allClients)?_c('div',{staticClass:"skeleton_loader"},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody, table-tfoot"}})],1):_vm._e(),(_vm.group && _vm.allClients)?_c('div',[_c('v-form',{staticStyle:{"display":"flex"},attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.addClient.apply(null, arguments)}}},[_c('v-autocomplete',{attrs:{"items":_vm.cleanedAllClients,"item-text":"title","outlined":"","label":"Client","return-object":""},model:{value:(_vm.selectedClient),callback:function ($$v) {_vm.selectedClient=$$v},expression:"selectedClient"}}),_c('v-btn',{staticStyle:{"height":"56px","min-width":"56px","margin-left":"10px"},attrs:{"color":"primary","x-large":"","elevation":"0"},on:{"click":_vm.addClient}},[_vm._v("Add Member")])],1),_c('v-data-table',{attrs:{"headers":[
        { text: 'Name', value: 'full_name' },
        { text: 'Date of Birth', value: 'date_of_birth' },
        { text: '', value: '_controls', sortable: false, width: '53' } ],"items":_vm.cleanedClients,"sort-by":"name","items-per-page":-1,"sort-desc":""},scopedSlots:_vm._u([{key:"item.date_of_birth",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date_of_birth).format('MMMM D, YYYY'))+" ")]}},{key:"item._controls",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red darken-2","icon":""},on:{"click":function($event){return _vm.removeClient(item.client_id)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)}),_c('FormActionButtons',{attrs:{"buttons":[
        'cancel',
        'save'
      ]},on:{"cancel":function($event){return _vm.$emit('cancel')},"save":_vm.saveGroup}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }