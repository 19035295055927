import { checkAuth } from '../functions'
import PlanProfilePage from '@/views/Plans/PlanProfilePage.vue'
import PlanProfileNewPage from '@/views/Plans/PlanProfileNewPage.vue'

export default [
  {
    path: '/client/:clientId/plan/create',
    name: 'PlanProfileNewPage',
    component: PlanProfileNewPage,
    props: (route) => {
      return {
        clientId: parseInt(route.params.clientId)
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'New Plan'
    }
  },
  {
    path: '/client/:clientId/plan/:planId',
    name: 'PlanProfilePage',
    component: PlanProfilePage,
    props: (route) => {
      return {
        clientId: parseInt(route.params.clientId),
        planId: parseInt(route.params.planId)
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'Plan'
    }
  }
]
