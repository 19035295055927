import store from '@/store'

export function checkAuth (to, from, next) {
  if (store.getters.checkUserAuth === true) {
    next()
    return
  }
  next('/login')
}

export function checkIfAlreadyLoggedIn (to, from, next) {
  // return
  // if (store.getters.checkUserAuth === true) {
  //   if (from.name === undefined || from.name === null) {
  //     next('/')
  //     return
  //   }
  //   return
  // }
  next()
}

export function paramsToPropsCaster (mapping) {
  return function (route) {
    const nameType = Object.entries(mapping)

    const nameRouteParam = nameType.map(([name, fn]) => {
      return [name, fn(route.params[name])]
    })

    const props = Object.fromEntries(nameRouteParam)
    return props
  }
}
