import { checkAuth } from '../functions'
import CentresPage from '@/views/Centres/CentresPage'
import CentreProfilePage from '@/views/Centres/CentreProfilePage'
import CentreProfileNewPage from '@/views/Centres/CentreProfileNewPage'

export default [
  {
    path: '/centres',
    name: 'CentresPage',
    component: CentresPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'Centres'
    }
  },
  {
    path: '/centre/create',
    name: 'CentreProfileNewPage',
    component: CentreProfileNewPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'New Centre'
    }
  },
  {
    path: '/centre/:centreId',
    name: 'CentreProfileViewPage',
    component: CentreProfilePage,
    props: (route) => {
      return {
        centreId: parseInt(route.params.centreId)
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'Centre'
    }
  }
]
