<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <div class="title d-flex align-center justify-space-between">
          <h2 class="text-h2 font-weight-regular">Users</h2>
          <router-link :to="{name:'UserProfileNewPage'}">
            <v-btn
              color="primary"
              elevation="0"
              large
              rounded
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
              New User
            </v-btn>
          </router-link>
        </div>

        <UsersTable :users="users" />

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import UsersTable from '@/components/Users/UsersTable'

export default {
  name: 'UsersPage',
  components: {
    UsersTable
  },
  data () {
    return {
      users: false
    }
  },

  async mounted() {
    this.users = await Api.Users.getAll()
  }
}
</script>

<style lang="scss">
.title {
  margin-bottom: 2.188rem;
}
</style>
