<template>
  <v-container>
    <v-row>
      <v-col sm="12" md="6" lg="5">

        <FormActionButtons
          :buttons="[]" 
          :customButton="{
            text: false,
            color: '',
            label: 'Back to all barriers' ,
            icon: 'mdi-chevron-left',
            icon_start: true
          }"
          noPadding
          @custom="returnToCategory"
        />
        <div class="pb-10"></div>

        <label class="text-body-2 grey--text text--darken-1">Name</label>
        <p class="text-body-1">{{item.name}}</p>

        <label class="text-body-2 grey--text text--darken-1">Category</label>
        <p class="text-body-1">{{item.category}}</p>

        <label class="text-body-2 grey--text text--darken-1">Description</label>
        <p class="text-body-1">{{item.description || '-'}}</p>

        <FormActionButtons
          v-if="!item.disabled"
          :buttons="[
            'edit'
          ]"
          :customButton="{
            text: true,
            color: 'red darken-2',
            label: 'Disable'
          }"
          @edit="$emit('edit')"
          @custom="deleteBarrier"
        />

        <FormActionButtons
          v-if="item.disabled"
          :buttons="[]" 
          :customButton="{
            text: false,
            color: 'warning',
            label: 'Restore',
            icon: 'mdi-pencil',
            icon_start: false
          }"
          @custom="restoreBarrier"
        />

      </v-col>
    </v-row>

    <DeleteModal
      v-if="deleteModal"
      title="Disable Barrier"
      :text="`Are you sure you wish to disable the current barrier <b>${item.name}</b>?`"
      deleteButtonText="disable"
      :strongWarning="false"
      @confirm="deleteBarrierConfirm"
      @cancel="deleteBarrierCancel" 
    />
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
import DeleteModal from '@/components/elements/DeleteModal.vue'

export default {
  components: {
    FormActionButtons,
    DeleteModal
  },
  props: {
    item: {
      required: true,
    }
  },
  data () {
    return {
      deleteModal: false,
    }
  },

  async created () {
  },

  methods: {
    returnToCategory() {
      this.$router.push({name:'MappingCategoryPage',params:{category:'barriers'}})
    },
    async restoreBarrier () {
      this.loading = true
      const response = await Api.Barriers.restore(this.item.barrier_id, this.item)
      if (response) {
        this.loading = false
        this.$toast.success('The support was successfully restored.')
        this.$router.push({name:'MappingCategoryPage',params:{category:'barriers'}})
        return
      }
      this.loading = false
      this.$toast.error('Something went wrong. Please try again.')
    },
    deleteBarrier () {
      this.deleteModal = true
    },
    async deleteBarrierConfirm(){
      if (this.item.reporting_type===null) {
        this.deleteModal = false
        this.$toast.error('The support is missing a mapping.')
        return
      }
      this.deleteModal = false
      this.loading = true
      const response = await Api.Barriers.delete(this.item.barrier_id, this.item)
      if (response) {
        this.loading = false
        this.$toast.success('The support was successfully disabled.')
        this.$router.push({name:'MappingCategoryPage',params:{category:'barriers'}})
        return
      }
      this.loading = false
      this.$toast.error('Something went wrong. Please try again.')
    },
    deleteBarrierCancel(){
      this.deleteModal = false
    }
  },

  computed: {
  }
}
</script>

<style lang="scss">
</style>
