<template>
  <div>
    <v-container class="skeleton_loader" v-if="!interactions || !clientInteractionSums" style="padding:0;">
      <v-row>
        <v-col sm="12" md="7" lg="5" order-sm="2" order-md="1">
          <v-skeleton-loader
            type="table-thead, table-tbody">
          </v-skeleton-loader>
        </v-col>
        <v-col sm="12" md="5" lg="7" order-sm="1" order-md="2">
          <v-skeleton-loader height="36px" width="189px" type="image"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="interactions && clientInteractionSums" style="padding:0;">
      <v-row>
        <v-col sm="12" md="7" lg="5" order-sm="2" order-md="1">
          <v-data-table
            :headers="[
              { text: 'Interaction Type', value: 'name' },
              { text: 'Amount', value: 'count' }
            ]"
            :items="cleanedList"
            v-if="cleanedList"
          >
          </v-data-table>
        </v-col>
        <v-col sm="12" md="5" lg="7" order-sm="1" order-md="2">
          <FormActionButtons
            :buttons="[]"
            :customButton="{
              text: false,
              color: 'primary',
              label: 'Add Interaction' ,
              icon: 'mdi-plus'
            }"
            @custom="modal=true"
          />
        </v-col>
      </v-row>
    </v-container>

    <ClientInteractionModal
      v-if="modal"
      :client="client.client_id"
      @close="modal=false;updateCounts();"
    />
  </div>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
import ClientInteractionModal from '@/components/elements/ClientInteractionModal.vue'
var moment = require('moment')

export default {
  components: {
    FormActionButtons,
    ClientInteractionModal
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      moment: moment,
      loading: false,
      modal: false,
      interactions: false,
      clientInteractionSums: false
    }
  },
  async created () {
    this.interactions = await Api.Interactions.getAll()
    this.updateCounts()
  },

  methods: {
    async updateCounts () {
      this.clientInteractionSums = false
      this.clientInteractionSums = await Api.Interactions.getSumFromClient(this.client.client_id)
    },
  },

  computed: {
    cleanedList () {
      if (!this.interactions || !this.clientInteractionSums) return false
      const cleaned = []
      this.interactions.some((item, i) => {
        cleaned.push({
          id: item.interaction_id,
          name: item.name,
          count: (this.clientInteractionSums.find(o => o.interaction_id === item.interaction_id) || {'total':0}).total
        })
      })
      return cleaned
    }
  }
}
</script>

<style scoped lang="scss">
</style>
