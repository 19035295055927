var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.client && _vm.rules && _vm.provinces)?_c('div',{staticClass:"ClientProfileEditForm"},[_c('v-form',{ref:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.saveClient.apply(null, arguments)}}},[(_vm.page==='client')?_c('div',[_c('v-text-field',{attrs:{"type":"text","label":"First name *","outlined":"","rules":[
          _vm.rules.required, 
          _vm.rules.maxLength(_vm.client.first_name,64)
        ]},model:{value:(_vm.client.first_name),callback:function ($$v) {_vm.$set(_vm.client, "first_name", $$v)},expression:"client.first_name"}}),_c('v-text-field',{attrs:{"type":"text","label":"Middle name","outlined":"","rules":[
          _vm.rules.maxLength(_vm.client.middle_name,64)
        ]},model:{value:(_vm.client.middle_name),callback:function ($$v) {_vm.$set(_vm.client, "middle_name", $$v)},expression:"client.middle_name"}}),_c('v-text-field',{attrs:{"type":"text","label":"Last name *","outlined":"","rules":[
          _vm.rules.required, 
          _vm.rules.maxLength(_vm.client.last_name,64)
        ]},model:{value:(_vm.client.last_name),callback:function ($$v) {_vm.$set(_vm.client, "last_name", $$v)},expression:"client.last_name"}}),_c('v-text-field',{attrs:{"type":"text","label":"Email","outlined":"","rules":[
          _vm.rules.isEmail,
          _vm.rules.maxLength(_vm.client.email,128)
        ]},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}}),_c('v-text-field',{attrs:{"type":"text","label":"Phone","outlined":"","rules":[
          _vm.rules.maxLength(_vm.client.phone,32)
        ]},model:{value:(_vm.client.phone),callback:function ($$v) {_vm.$set(_vm.client, "phone", $$v)},expression:"client.phone"}}),_c('v-text-field',{attrs:{"type":"text","label":'Social Insurance Number ' + ( !_vm.sinNotRequired ? '*': '' ),"outlined":"","rules":[
          ( !_vm.sinNotRequired ? _vm.rules.required: true ),
          _vm.rules.isSin
        ],"disabled":_vm.sinNotRequired},model:{value:(_vm.client.sin),callback:function ($$v) {_vm.$set(_vm.client, "sin", $$v)},expression:"client.sin"}}),_c('v-checkbox',{staticClass:"mb-3",attrs:{"label":"Missing Social Insurance Number"},model:{value:(_vm.sinNotRequired),callback:function ($$v) {_vm.sinNotRequired=$$v},expression:"sinNotRequired"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"date","label":"Date of birth *","prepend-inner-icon":"mdi-calendar","outlined":"","rules":[
              _vm.rules.required, 
              _vm.rules.noFutureDate
            ]},model:{value:(_vm.client.date_of_birth),callback:function ($$v) {_vm.$set(_vm.client, "date_of_birth", $$v)},expression:"client.date_of_birth"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":['Male','Female','Other','Prefer not to say'],"item-value":"id","item-text":"title","label":"Gender","outlined":""},model:{value:(_vm.client.gender),callback:function ($$v) {_vm.$set(_vm.client, "gender", $$v)},expression:"client.gender"}})],1)],1),_c('v-text-field',{attrs:{"type":"text","label":"Address","outlined":"","rules":[
          _vm.rules.maxLength(_vm.client.address,128)
        ]},model:{value:(_vm.client.address),callback:function ($$v) {_vm.$set(_vm.client, "address", $$v)},expression:"client.address"}}),_c('v-text-field',{attrs:{"type":"text","label":"City *","outlined":"","rules":[
          _vm.rules.required,
          _vm.rules.maxLength(_vm.client.city,64)
        ]},model:{value:(_vm.client.city),callback:function ($$v) {_vm.$set(_vm.client, "city", $$v)},expression:"client.city"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.provinces,"item-value":"abbr","item-text":"name","label":"Province","outlined":""},model:{value:(_vm.client.province),callback:function ($$v) {_vm.$set(_vm.client, "province", $$v)},expression:"client.province"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"text","label":"Postal Code","outlined":"","rules":[
              _vm.rules.noSpaces,
              _vm.rules.isPostalCode
            ]},model:{value:(_vm.client.postal_code),callback:function ($$v) {_vm.$set(_vm.client, "postal_code", $$v)},expression:"client.postal_code"}})],1)],1)],1):_vm._e(),(_vm.page==='demographic')?_c('div',[_c('v-select',{attrs:{"items":['Single', 'Married', 'Common Law', 'Divorced', 'Widowed', 'Other', 'Prefer not to say'],"label":"Marital status","outlined":""},model:{value:(_vm.client.marital_status),callback:function ($$v) {_vm.$set(_vm.client, "marital_status", $$v)},expression:"client.marital_status"}}),_c('v-text-field',{attrs:{"type":"text","label":"Number of dependents","outlined":"","rules":[
          _vm.rules.isWholeNumber,
          _vm.rules.minNumber(_vm.client.number_dependents, 0),
          _vm.rules.maxNumber(_vm.client.number_dependents, 99)
        ]},model:{value:(_vm.client.number_dependents),callback:function ($$v) {_vm.$set(_vm.client, "number_dependents", $$v)},expression:"client.number_dependents"}}),_c('v-select',{attrs:{"items":['Yes', 'No', 'Prefer not to say'],"label":"Do you have a disability?","outlined":""},model:{value:(_vm.client.disability),callback:function ($$v) {_vm.$set(_vm.client, "disability", $$v)},expression:"client.disability"}}),_c('v-select',{attrs:{"items":['Yes', 'No', 'Prefer not to say'],"label":"Have you ever been a Youth in Care?","outlined":""},model:{value:(_vm.client.youth_in_care),callback:function ($$v) {_vm.$set(_vm.client, "youth_in_care", $$v)},expression:"client.youth_in_care"}}),_c('v-select',{attrs:{"items":['English only', 'French only', 'French and English', 'Other'],"label":"Languages spoken","outlined":""},model:{value:(_vm.client.language_spoken),callback:function ($$v) {_vm.$set(_vm.client, "language_spoken", $$v)},expression:"client.language_spoken"}}),_c('v-select',{attrs:{"items":['Canadian citizen', 'Permanent resident', 'Protected person'],"label":"Citizenship *","outlined":"","rules":[
          _vm.rules.required
        ]},model:{value:(_vm.client.citizenship),callback:function ($$v) {_vm.$set(_vm.client, "citizenship", $$v)},expression:"client.citizenship"}}),_c('v-select',{attrs:{"items":['None', 'Some highschool', 'Highschool diploma', 'Some college/university', 'University/college diploma', 'Bachelor\'s degree', 'Post-grad degree', 'Prefer not to say'],"label":"Highest education received","outlined":""},model:{value:(_vm.client.highest_education),callback:function ($$v) {_vm.$set(_vm.client, "highest_education", $$v)},expression:"client.highest_education"}})],1):_vm._e(),(_vm.page==='ancestry')?_c('div',[_c('v-select',{attrs:{"items":['Yes', 'No'],"label":"Do you identify as Indigenous?","outlined":""},model:{value:(_vm.client.indigenous),callback:function ($$v) {_vm.$set(_vm.client, "indigenous", $$v)},expression:"client.indigenous"}}),(_vm.client.indigenous==='Yes')?_c('div',[_c('v-select',{attrs:{"items":['Status First Nation','Non-status First Nation','Métis','Inuit','Prefer not to say'],"label":"Please indicate your ancestry","outlined":""},model:{value:(_vm.client.status_type),callback:function ($$v) {_vm.$set(_vm.client, "status_type", $$v)},expression:"client.status_type"}}),_c('v-text-field',{attrs:{"type":"text","label":"Nation","outlined":"","rules":[
            _vm.rules.maxLength(_vm.client.nation, 64)
          ]},model:{value:(_vm.client.nation),callback:function ($$v) {_vm.$set(_vm.client, "nation", $$v)},expression:"client.nation"}}),_c('v-select',{attrs:{"items":['On reserve','Off reserve','Prefer not to say'],"label":"Do you live on or off reserve?","outlined":""},model:{value:(_vm.client.reserve),callback:function ($$v) {_vm.$set(_vm.client, "reserve", $$v)},expression:"client.reserve"}}),_c('v-select',{attrs:{"items":['Yes','No'],"label":"Do you speak an Indigenous language?","outlined":""},model:{value:(_vm.spokenLanguages),callback:function ($$v) {_vm.spokenLanguages=$$v},expression:"spokenLanguages"}}),(_vm.spokenLanguages==='Yes')?_c('div',[_c('v-text-field',{attrs:{"type":"text","label":"Please specify which language(s)","outlined":"","rules":[
              _vm.rules.maxLength(_vm.client.indigenous_language, 64)
            ]},model:{value:(_vm.client.indigenous_language),callback:function ($$v) {_vm.$set(_vm.client, "indigenous_language", $$v)},expression:"client.indigenous_language"}})],1):_vm._e()],1):_vm._e()],1):_vm._e(),_c('FormActionButtons',{attrs:{"buttons":[
        'cancel',
        'save'
      ],"noTopPadding":"","loading":_vm.loading},on:{"cancel":function($event){return _vm.$emit('cancel')},"save":_vm.saveClient}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }