var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6","lg":"5"}},[_c('FormActionButtons',{attrs:{"buttons":[],"customButton":{
          text: false,
          color: '',
          label: 'Back to all supports' ,
          icon: 'mdi-chevron-left',
          icon_start: true
        },"noPadding":""},on:{"custom":_vm.returnToCategory}}),_c('div',{staticClass:"pb-10"}),_c('label',{staticClass:"text-body-2 grey--text text--darken-1"},[_vm._v("Name")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.item.name))]),_c('label',{staticClass:"text-body-2 grey--text text--darken-1"},[_vm._v("Category")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.item.category))]),_c('label',{staticClass:"text-body-2 grey--text text--darken-1"},[_vm._v("Mapping")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.item.reporting_type_name||'-'))]),_c('label',{staticClass:"text-body-2 grey--text text--darken-1"},[_vm._v("Description")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.item.description || '-'))]),(!_vm.item.disabled)?_c('FormActionButtons',{attrs:{"buttons":[
          'edit'
        ],"customButton":{
          text: true,
          color: 'red darken-2',
          label: 'Disable'
        }},on:{"edit":function($event){return _vm.$emit('edit')},"custom":_vm.deleteSupport}}):_vm._e(),(_vm.item.disabled)?_c('FormActionButtons',{attrs:{"buttons":[],"customButton":{
          text: false,
          color: 'warning',
          label: 'Restore',
          icon: 'mdi-pencil',
          icon_start: false
        }},on:{"custom":_vm.restoreSupport}}):_vm._e()],1)],1),(_vm.deleteModal)?_c('DeleteModal',{attrs:{"title":"Disable Support","text":("Are you sure you wish to disable the current support <b>" + (_vm.item.name) + "</b>?"),"deleteButtonText":"disable","strongWarning":false},on:{"confirm":_vm.deleteSupportConfirm,"cancel":_vm.deleteSupportCancel}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }