<template>
  <v-container>
    <v-row justify-md="space-between" justify="center" align="center">
      <v-col xl="5" lg="6" md="6" sm="8">

        <h2 class="text-h2 font-weight-regular mt-10">Oops!</h2>
        <h4 class="text-h4 font-weight-regular mt-2">We had some technical problems during the last operation.</h4>
        <h5 class="text-h5 font-weight-light grey--text text--darken-1 mt-5">
          You can go back and try again. If the problem persists, you can email us at:
          <br/> 
          <a href="mailto:elst-support@ursa-creative.atlassian.net">elst-support@ursa-creative.atlassian.net</a>
        </h5>

        <div class="mt-10">          
          <v-btn
            color="primary"
            large
            :to="{name:'ClientsPage'}"
          >Back to home page</v-btn>
        </div>
        <div class="mt-2 mb-3">          
          <v-btn
            color="secondary"
            text
            small
            @click="showError=!showError"
          >{{showError?'Hide':'Show'}} Error Log</v-btn>
        </div>

        <v-card v-if="showError">
          <v-card-text>
            <pre style="white-space:pre-line;">{{errorReport}}</pre>
          </v-card-text>
        </v-card>
        

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex">

        <div id="bg-block"></div>
        <div id="bg-image"><InlineSvg src="/images/svgs/undraw_warning.svg" /></div>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  components: {
    FormActionButtons
  },
  props: {
    errorReport: {
      type: Object,
      required: false,
      default: {}
    }
  },
  data () {
    return {
      showError: false
    }
  },

  created () {
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.v-card {
  border: 1px solid #E0E0E0;
}
</style>
