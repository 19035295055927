export default {

  localhost: {
    VUE_APP_API_URL: 'http://localhost:3000',
    VUE_APP_NOC_KEY: '83cb5d1a4c317a60e069c092927b5793',
    VUE_APP_METABASE_URL: 'http://localhost:82',
    VUE_APP_METABASE_KEY: '37d5c5ee7798239e3992613126adcc31f301977fe7600fd27811102877b863e2',
    VUE_APP_METABASE_DASHBOARDS: {
      clients: 2,
      client_interactions: 8,
      plans: 3,
      outcomes: 6,
      supports_trainings: 4,
      stories: 5
    }
  },

  staging: {
    VUE_APP_API_URL: 'https://staging.bcaafcemployment.ca:9010',
    VUE_APP_NOC_KEY: '83cb5d1a4c317a60e069c092927b5793',
    VUE_APP_METABASE_URL: 'https://staging.bcaafcemployment.ca:9013',
    VUE_APP_METABASE_KEY: 'dc86be5ebff32d0849a90e2567edd4bfd2bbcf3aeb676ce3dab6dfe76feb0430',
    VUE_APP_METABASE_DASHBOARDS: {
      clients: 10,
      client_interactions: 14,
      plans: 11,
      outcomes: 15,
      supports_trainings: 12,
      stories: 13
    }
  },

  production: {
    VUE_APP_API_URL: 'https://bcaafcemployment.ca:9010',
    VUE_APP_NOC_KEY: '83cb5d1a4c317a60e069c092927b5793',
    VUE_APP_METABASE_URL: 'https://bcaafcemployment.ca:9013',
    VUE_APP_METABASE_KEY: '159b78403f8ec61b035b1ca16818d5c2e1145d6a4790a3be9b374e13ad6ccce1',
    VUE_APP_METABASE_DASHBOARDS: {
      clients: 1,
      client_interactions: 2,
      plans: 3,
      outcomes: 4,
      supports_trainings: 5,
      stories: 6
    }
  }

}