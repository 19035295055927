<template>
  <div>

    <Display
      :activeColumn="activeColumn"
      :masterColumn="masterColumn"
      v-if="!edit && !loading"
      @edit="edit=true"
    />

    <Editing
      :activeColumn="activeColumn"
      :masterColumn="masterColumn"
      :loading="loading"
      v-if="edit || loading"
      @cancel="edit=false"
      @save="save"
    />
  </div>
</template>

<script>
import Display from './Display.vue'
import Editing from './Editing.vue'

import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  name: 'DraggableColumns',
  components: {
    Display,
    Editing,
    FormActionButtons
  },
  props: {
    activeColumn: {
      required: true,
      default: false,
    },
    masterColumn: {
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      edit: false,
    }
  },
  created () { 
  },
  methods: {
    save (array) {
      this.edit = false
      this.$emit('save', array)
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
</style>
