<template>
  <v-container>
    <v-row justify-md="space-between" justify="center">
      <v-col xl="5" lg="5" md="6" sm="8">
        <div class="title">
          <h2 class="text-h2 font-weight-regular">New Group</h2>
        </div>

        <div v-if="page===0">
          <GroupDetailsEdit
            :group="group"
            type="new"
            @cancel="cancelGroup"
            @save="page++"
          />
        </div>

        <div v-if="page===1">
          <GroupMembersEdit
            :group="group"
            type="new"
            @cancel="page--"
            @save="saveGroup"
          />
        </div>

      </v-col>
      <v-col xl="6" lg="6" class="d-none d-md-flex">

        <div id="bg-block"></div>
        <div id="bg-image"><InlineSvg src="/images/svgs/undraw_group_selfie.svg" /></div>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GroupDetailsEdit from '@/components/Groups/GroupDetailsEdit'
import GroupMembersEdit from '@/components/Groups/GroupMembersEdit'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
import validationRules from '@/helpers/validationRules'
import { Api } from '@/gateways/endpoints'

export default {
  components: {
    FormActionButtons,
    GroupDetailsEdit,
    GroupMembersEdit
  },
  props: {
  },
  data () {
    return {
      rules: false,
      loading: false,
      page: 0,
      group: {clients: []},
      ownUser: null,
    }
  },

  created () {
    this.ownUser = this.$store.getters.getUser
    this.rules = validationRules
  },

  methods: {
    cancelGroup () {
      this.$router.push({name:'GroupsPage'})
    },
    async saveGroup () {
      this.loading = true
      this.group.centre_id = this.ownUser.centre.centre_id
      const response = await Api.Groups.create(this.group)
      if (response) {
        this.$toast.success('The group was successfully created.');
        this.$router.push({name:'GroupsPage'})
        this.$emit('save')
        return
      }
      this.$toast.error('Something went wrong. Please try again.');
    }
  }
}
</script>

<style lang="scss">
</style>
