<template>
  <v-container style="padding:0;">

    <v-row v-if="noteEdit">
      <v-col sm="12" md="7" lg="5">

        <p class="text-h5">{{ selectedNote.note_id===-1 ? 'New Plan Note' : 'Edit Plan Note' }}</p>
    
        <v-form 
          ref="form"
          @submit.prevent="saveNote" 
          novalidate
        >

          <v-text-field
            type="text"
            v-model="selectedNote.topic"
            label="Topic *"
            outlined
            :rules="[
              rules.required, 
              rules.maxLength(selectedNote.topic,64)
            ]"
          />

          <v-text-field
            type="date"
            v-model="selectedNote.date"
            label="Date *"
            outlined
            prepend-inner-icon="mdi-calendar"
            :rules="[
              rules.required
            ]"
          />

          <v-textarea
            type="text"
            v-model="selectedNote.notes"
            label="Note *"
            outlined
            rows="5"
            counter="2048"
            :rules="[
              rules.required,
              rules.maxLength(selectedNote.notes,2048)
            ]"
          />

          <v-checkbox
            v-model="selectedNote.flag"
            label="Flag Note"
          ></v-checkbox>

          <FormActionButtons
            :buttons="[
              'cancel',
              'save'
            ]" 
            noTopPadding
            :loading="loading"
            @cancel="noteEdit=false;selectedNote=false"
            @save="saveNote"
          />
        </v-form>

      </v-col>
    </v-row>

    <v-row v-if="!noteEdit">
      <v-col cols="5" class="skeleton_loader" v-if="!cleanedNotes">
          <v-skeleton-loader max-width="100%" max-height="56px" type="image" style="margin-bottom:30px"></v-skeleton-loader>
        <div
          v-for="i in 3"
          :key="i"
          style="margin-bottom:8px;"
        >
          <v-skeleton-loader
            max-width="100%"
            max-height="80px"
            type="image"
          ></v-skeleton-loader>
        </div>
      </v-col>
      <v-col cols="5" v-if="cleanedNotes">
        <div class="single-grid">
          <div class="grid-item" v-if="notes.length===0">
            <div class="empty-to" :move="false">
              <p class="placeholder text-body-1 mb-0 blue-grey--text text--darken-2">
                There are no notes.
                <br/>
                You can add a new note by click the buttton to the right.
              </p>
            </div>
          </div>
          <div class="grid-item" v-if="notes.length!==0">
            <v-text-field
              label="Search Notes"
              type="text"
              outlined
              v-model="search"
            />
            <p class="placeholder text-center text-body-1 mb-0 blue-grey--text text--darken-2" v-if="cleanedNotes.length===0">
              There are no search results.
            </p>
            <div 
              v-for="item in cleanedNotes" 
              :class="'note-item pa-3 mb-2 blue' + (item.flag?' lighten-3':' lighten-5') + (item.note_id===selectedNote.note_id?' active':'')"
              :key="item.note_id"
              @click="selectedNote=Object.assign({},item)"
            >
              <div class="more-info">
                <v-icon small v-if="item.flag">mdi-pin-outline</v-icon>
                <span>{{item.topic}}</span>
                <v-container style="padding:8px 0 0 0;">
                  <v-row>
                    <v-col cols="6">
                      <span class="text-caption">{{ moment(item.date).format('MMMM D, YYYY') }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="7" v-if="notes">

        <div class="text-right" style="margin-bottom:50px">
          <FormActionButtons
            :buttons="[]" 
            :customButton="{
              text: false,
              color: 'primary',
              label: 'New Note' ,
              icon: 'mdi-plus'
            }"
            noPadding
            @custom="newNote"
          />
        </div>

        <div class="single-note" v-if="notes.length!==0">

          <div class="empty" v-if="!selectedNote">
            <p class="text-body-1 mb-0">Select a case note from the left to view its details</p>
          </div>
          <div v-if="selectedNote">

            <div>
              <p class="text-h5">
                <v-icon color="black" v-if="selectedNote.flag">mdi-pin-outline</v-icon>
                {{selectedNote.topic}}
              </p>
              <p class="text-body-2 grey--text text--darken-1">
                {{ moment(selectedNote.date).format('MMMM D, YYYY') }}
              </p>
              <p class="text-body-1">{{selectedNote.notes}}</p>

              <FormActionButtons
                :buttons="[
                  'edit',
                  'delete'
                ]"
                :loading="loading"
                @edit="noteEdit=true"
                @delete="deleteNote"
              />
            </div>

          </div>
        </div>
      </v-col>
    </v-row>  

    <DeleteModal
      v-if="deleteModal"
      title="Delete Note"
      :text="`Are you sure you wish to delete the current note?`"
      @confirm="deleteNoteModalConfirm"
      @cancel="deleteNoteModalCancel" 
    />

  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import validationRules from '@/helpers/validationRules'

import DeleteModal from '@/components/elements/DeleteModal.vue'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'
var moment = require('moment')

export default {
  components: {
    DeleteModal,
    FormActionButtons
  },
  props: {
    planId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      loading: false,
      rules: false,
      notes: false,
      selectedNote: false,
      noteEdit: false,
      deleteModal: false,
      search: '',
    }
  },
  async created () { 
    this.rules = await validationRules
    this.refreshData()
  },
  methods: {
    async refreshData () {
      this.notes = false
      this.noteEdit = false
      this.selectedNote = false
      this.notes = await Api.Notes.getFromPlan(this.planId)
    },

    newNote () {
      this.noteEdit = true
      this.selectedNote = {
        note_id: -1,
        topic: '',
        notes: '',
        date: moment().format('YYYY-MM-DD')
      }
    },

    async saveNote () {
      if( !this.$refs.form.validate() ) return
      this.loading = true

      this.selectedNote.plan_id = this.planId

      if (this.selectedNote.note_id === -1){
        //create
        const response = await Api.Notes.create(this.selectedNote)
        if (response) {
          this.loading = false
          this.refreshData()
          this.$toast.success('The note was successfully created.')
          return
        }
      } else {
        //update
        const response = await Api.Notes.update(this.selectedNote.note_id, this.selectedNote)
        if (response) {
          this.loading = false
          this.refreshData()
          this.$toast.success('The note was successfully updated.')
          return
        }
      }

      this.$toast.error('Something went wrong. Please try again.')
    },

    deleteNote () {
      this.deleteModal = true
    },
    async deleteNoteModalConfirm () {
      this.deleteModal = false
      this.loading = true
      const response = await Api.Notes.delete(this.selectedNote.note_id, this.selectedNote)
      if (response) {
        this.loading = false
        this.refreshData()
        this.$toast.success('The note was successfully deleted.')
        return
      }
      this.loading = false
      this.$toast.error('Something went wrong. Please try again.')
    },
    deleteNoteModalCancel () {
      this.deleteModal = false
    }
  },
  computed: {
    cleanedNotes () {
      if (!this.notes) return false
      if (this.search==='') return this.notes
      const cleaned = this.notes.filter((item,i) => {
        return item.topic.toLowerCase().includes(this.search) || item.notes.toLowerCase().includes(this.search)
      })
      return cleaned
    }
  }
}
</script>

<style scoped lang="scss">
.note-item {
  transition: all 0.2s ease;
  border-radius: 0.25rem;
  cursor: pointer;
  .v-icon {
    color: black;
  }

  &.active, &:hover {
    background-color: #2196F3!important;
    color: white;
    .v-icon {
      color: white!important;
    }
  }
}

.single-note {
  border: 1px solid #E0E0E0;
  padding: 28px 30px;
  border-radius: 0.25rem;

  .v-icon {
    color: black;
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    text-align: center;
    color: #455A64;
  }
}
</style>
