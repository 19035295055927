import { checkAuth } from '../functions'
import ErrorPage from '@/views/Errors/404'

export default [
  {
    path: '/404',
    name: 'ErrorPage',
    component: ErrorPage,
    props: true,
    beforeEnter: checkAuth,
    meta: {
      title: '404'
    }
  }
]
