<template>
  <v-container>
    <v-row justify-md="space-between" justify="center">
      <v-col sm="12">
        <div class="title">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">Client Profile</h5>
          <h2 class="text-h2 font-weight-regular">
            <v-skeleton-loader class="skeleton_loader" height="60px" width="400px" type="image" v-if="!client"></v-skeleton-loader>
            {{client.first_name}} {{client.last_name}}
          </h2>
        </div>

        <v-tabs class="mb-0" v-model="page" show-arrows>
          <v-tab @click="edit=false;resetData()">Client</v-tab>
          <v-tab @click="edit=false;resetData()">Demographic</v-tab>
          <v-tab @click="edit=false;resetData()">Ancestry</v-tab>
          <v-tab @click="edit=false;resetData()">Client Interactions</v-tab>
          <!-- <v-tab @click="edit=false;resetData()">Attachments</v-tab> -->
        </v-tabs>
      </v-col>

      <v-col sm="12" md="7" lg="5" v-if="page==0">
        <ClientProfileView 
          :client="client"
          page="client"
          @edit="edit=true"
          v-if="!edit" 
        />
        <ClientProfileEditForm
          v-if="edit && client"
          page="client"
          :client="clientEditing"
          @save="edit=false;refreshData()"
          @cancel="edit=false;resetData()" 
        />
        <FormActionButtons
          v-if="!edit && client"
          :buttons="[
            'edit',
            'delete'
          ]" 
          :loading="loading"
          @edit="edit=true"
          @delete="deleteModal=true"
        />
      </v-col>

      <v-col sm="12" md="7" lg="5" v-if="page==1">
        <ClientProfileView 
          :client="client"
          page="demographic"
          @edit="edit=true"
          v-if="!edit" 
        />
        <ClientProfileEditForm
          v-if="edit"
          page="demographic"
          :client="clientEditing"
          @save="edit=false;refreshData()"
          @cancel="edit=false;resetData()" 
        />
        <FormActionButtons
          v-if="!edit"
          :buttons="[
            'edit'
          ]" 
          @edit="edit=true"
        />
      </v-col>

      <v-col sm="12" md="7" lg="5" v-if="page==2">
        <ClientProfileView 
          :client="client"
          page="ancestry"
          @edit="edit=true"
          v-if="!edit" 
        />
        <ClientProfileEditForm
          v-if="edit"
          page="ancestry"
          :client="clientEditing"
          @save="edit=false;refreshData()"
          @cancel="edit=false;resetData()" 
        />
        <FormActionButtons
          v-if="!edit"
          :buttons="[
            'edit'
          ]" 
          @edit="edit=true"
        />
      </v-col>

      <v-col sm="12" v-if="page==3">
        <ClientInteractionsPage
          :client="client"
        />
      </v-col>

      <v-col sm="12" v-if="page==4">
        ATTACHMENTS WILL GO HERE
      </v-col>

      <DeleteModal
        v-if="deleteModal"
        title="Delete Client"
        :text="`Are you sure you wish to delete the current client <b>${client.first_name} ${client.last_name}</b>?`"
        @confirm="deleteClientModalConfirm"
        @cancel="deleteClientModalCancel" 
      />

    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import ClientProfileView from '@/components/Clients/ClientProfileView.vue'
import ClientProfileEditForm from '@/components/Clients/ClientProfileEditForm.vue'
import ClientInteractionsPage from '@/components/Clients/ClientInteractionsPage.vue'
import DeleteModal from '@/components/elements/DeleteModal.vue'
import FormActionButtons from '@/components/elements/FormActionButtons.vue'

export default {
  name: 'ClientProfileViewPage',
  components: {
    ClientProfileView,
    ClientProfileEditForm,
    ClientInteractionsPage,
    DeleteModal,
    FormActionButtons
  },
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      page: 0,
      client: false,
      clientEditing: false,
      selectedTab: null,
      edit: false,
      deleteModal: false,
      loading: false
    }
  },
  
  async created() {
    await this.refreshData()
  },
  methods: {
    resetData () {
      this.clientEditing = Object.assign({}, this.client)
    },
    async refreshData () {
      this.client = await Api.Clients.getSingle(this.clientId)
      this.clientEditing = Object.assign({}, this.client)
      this.$store.dispatch('setClient', this.client)
    },

    deleteClient () {
      this.deleteModal = true
    },
    async deleteClientModalConfirm () {
      this.deleteModal = false
      this.loading = true
      const response = await Api.Clients.delete(this.client.client_id)
      if (response) {
        this.loading = false
        this.$toast.success('The client was successfully deleted.');
        this.$router.push({name:'ClientsPage'})
        return
      }
      this.loading = false
      this.$toast.error('Something went wrong. Please try again.');
    },
    deleteClientModalCancel () {
      this.deleteModal = false
    }
  }
}
</script>

<style scoped lang="scss">
// * {
//   border: 1px solid red;
// }
.v-tabs {
  margin-bottom: 1rem;
}
</style>
