var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify-md":"space-between","justify":"center"}},[_c('v-col',{attrs:{"xl":"5","lg":"5","md":"6","sm":"8"}},[(!_vm.user)?_c('div',{staticClass:"title skeleton_loader"},[_c('v-skeleton-loader',{staticStyle:{"margin-bottom":"2px"},attrs:{"height":"30px","width":"250px","type":"image"}}),_c('v-skeleton-loader',{attrs:{"height":"60px","width":"400px","type":"image"}})],1):_vm._e(),(_vm.user)?_c('div',{staticClass:"title"},[_c('h5',{staticClass:"text-h5 font-weight-light grey--text text--darken-1"},[_vm._v(_vm._s(_vm.user.roles[0].title)+" Profile")]),_c('h2',{staticClass:"text-h2 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name)+" ")])]):_vm._e(),(_vm.page=='profile')?_c('div',[_c('UserProfileView',{attrs:{"user":_vm.user,"ownAccount":_vm.ownAccount}}),((_vm.user && _vm.ownAccount) && _vm.ownAccount.user_id===_vm.user.user_id)?_c('FormActionButtons',{attrs:{"buttons":[],"customButton":{
            text: false,
            color: 'primary',
            label: 'Change Password',
            icon: 'mdi-lock-reset'
          }},on:{"custom":function($event){_vm.editType='password';_vm.page='edit';}}}):_vm._e(),(_vm.user && _vm.ownAccount)?_c('FormActionButtons',{attrs:{"buttons":[],"customButton":{
            text: false,
            color: 'primary',
            label: 'Edit Info',
            icon: 'mdi-pencil'
          }},on:{"custom":function($event){_vm.editType='info';_vm.page='edit';}}}):_vm._e(),_c('FormActionButtons',{attrs:{"buttons":[
            (_vm.ownAccount.user_id!==_vm.user.user_id?'delete':'')
          ]},on:{"delete":function($event){_vm.deleteModal=true}}})],1):_vm._e(),(_vm.page=='edit')?_c('UserProfileEditForm',{attrs:{"user":_vm.userEditing,"ownAccount":_vm.ownAccount,"editType":_vm.editType},on:{"save":function($event){_vm.page='profile';_vm.refreshData()},"cancel":function($event){_vm.page='profile';_vm.resetData()}}}):_vm._e(),(_vm.deleteModal)?_c('DeleteModal',{attrs:{"title":"Delete User","text":("Are you sure you wish to delete the current user <b>" + (_vm.user.first_name) + " " + (_vm.user.last_name) + "</b>?")},on:{"confirm":_vm.deleteUserModalConfirm,"cancel":_vm.deleteUserModalCancel}}):_vm._e()],1),_c('v-col',{staticClass:"d-none d-md-flex",attrs:{"xl":"6","lg":"6"}},[_c('div',{attrs:{"id":"bg-block"}}),_c('div',{attrs:{"id":"bg-image"}},[_c('InlineSvg',{attrs:{"src":"/images/svgs/undraw_mobile_user.svg"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }