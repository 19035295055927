<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <div class="title">
          <h5 class="text-h5 font-weight-light grey--text text--darken-1">Stories</h5>
          <h2 class="text-h2 font-weight-regular">
            <v-skeleton-loader class="skeleton_loader" height="60px" width="400px" type="image" v-if="!client"></v-skeleton-loader>
            {{client.first_name}} {{client.last_name}}
          </h2>
        </div>
      </v-col>
      <v-col cols="12">
        <StoriesPage 
          :clientId="clientId"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import StoriesPage from '@/components/Stories/StoriesPage.vue'

export default {
  components: {
    StoriesPage
  },
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      client: false
    }
  },

  created () {
    this.refreshData()
  },

  methods: {
    async refreshData () {
      this.client = await Api.Clients.getSingle(this.clientId)
      this.$store.dispatch('setClient', this.client)
    }
  }
}
</script>

<style lang="scss">
</style>
