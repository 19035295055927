<template>
  <v-app-bar
    app
    color="primary"
    dark
    elevation="0"
    v-if="ownUser"
    height="50"
    :clipped-left="true"
  >
    <v-container :class="smallScreen||phoneScreen?'max-width':''">
      <v-row>
        <v-col cols="12" class="d-flex justify-space-between align-center">
          <div>

            <div v-if="!phoneScreen">
              <v-btn 
                text
                elevation="0"
                :to="{name:'ClientsPage'}"
                v-if="visibleToUser(['Admin','Coordinator'])"
              >
                <v-icon small>mdi-account</v-icon> {{!smallScreen?'Clients':''}}
              </v-btn>
              <v-btn 
                text
                elevation="0"
                :to="{name:'GroupsPage'}"
                v-if="visibleToUser(['Coordinator'])"
              >
                <v-icon small>mdi-account-group</v-icon> {{!smallScreen?'Groups':''}}
              </v-btn>
              <v-btn 
                text
                elevation="0"
                :to="{name:'ReportPage'}"
              >
                <v-icon small>mdi-chart-line</v-icon> {{!smallScreen?'Reports':''}}
              </v-btn>
              <v-btn 
                text
                elevation="0"
                :to="{name:'DocumentsPage'}"
                v-if="visibleToUser(['Admin','Coordinator'])"
              >
                <v-icon small>mdi-file-document-outline</v-icon> {{!smallScreen?'Documents':''}}
              </v-btn>
            </div>
            <div v-if="phoneScreen">
              <v-btn 
                text
                elevation="0"
                :to="{name:'ClientInteractionPage'}"
                v-if="visibleToUser(['Admin','Coordinator'])"
              >
                <v-icon small>mdi-plus</v-icon> Client Interactions
              </v-btn>
            </div>

          </div>

          <div>

            <v-form 
              @submit.prevent="$router.push({name:'ClientsPage',query:{s:search}})" 
              novalidate
              style="width:200px;display:inline-block;margin-right:20px;"
              v-if="visibleToUser(['Admin','Coordinator']) && !phoneScreen"
            >
              <v-text-field
                v-model="search"
                placeholder="Search Clients"
                dense
                hide-details
                append-icon="mdi-magnify"
                @click:append="$router.push({name:'ClientsPage',query:{s:search}})"
              ></v-text-field>
            </v-form>

            <v-btn 
              small 
              color="orange darken-1"
              elevation="0"
              style="margin-right:12px;"
              @click="modal=true"
              v-if="visibleToUser(['Admin','Coordinator']) && !phoneScreen"
            >
              <v-icon>mdi-plus</v-icon> Client Interaction
            </v-btn>

            <v-btn
              dark
              icon
              :to="{name:'HelpPage'}"
              v-if="!phoneScreen"
            >
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>

            <v-menu
              offset-y
              bottom
              left
              v-if="visibleToUser(['Admin']) && !phoneScreen"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item :to="{name:'UsersPage'}">
                  <v-list-item-title>Users</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name:'CentresPage'}">
                  <v-list-item-title>Centres</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name:'MappingListPage'}">
                  <v-list-item-title>Mappings</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            
            <v-menu
              offset-y
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-avatar
                    :color="iconColor" 
                    size="30"
                  >
                    <span class="white--text">{{iconName}}</span>
                  </v-avatar>
                </v-btn>
              </template>

              <v-list>
                <v-list-item :to="{name:'UserProfileViewPage',params:{userId:ownUser.user_id}}"><v-list-item-title>Account Settings</v-list-item-title></v-list-item>
                <!-- <v-list-item :to="{name:'HelpPage'}" ><v-list-item-title>Help Centre</v-list-item-title></v-list-item> -->
                <!-- <v-list-item :to="{name:'LegalPage'}" disabled><v-list-item-title>Legal</v-list-item-title></v-list-item> -->

                <v-divider></v-divider>

                <v-list-item @click="logout">
                  <v-list-item-title>
                    <v-icon>mdi-logout</v-icon>
                    Logout
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <ClientInteractionModal
      v-if="modal"
      @close="modal=false"
    />
  </v-app-bar>
</template>

<script>
import { Api } from '@/gateways/endpoints'
import ClientInteractionModal from '@/components/elements/ClientInteractionModal.vue'
import userColors from '@/helpers/userColors'

export default {
  components: {
    ClientInteractionModal
  },
  data () {
    return {
      modal: false,
      ownUser: null,
      search: '',
    }
  },
  created () {
    this.search = this.$route.query.s
    this.ownUser = this.$store.getters.getUser
  },
  methods: {
    async logout() {
      const response = await Api.Auth.logout()
      if(response){
        this.$toast.success('You have been successfully logged out.')
        this.$router.push({name:'LoginPage'})
        return
      }
    },
    visibleToUser (requiredType) {
      return requiredType.includes(this.ownUser.roles[0].title)
    }
  },
  watch:{
    $route (to, from){
      if (to.name !== 'ClientsPage')
        this.search = ''
      this.ownUser = this.$store.getters.getUser
    }
  },
  computed: {
    iconName() {
      return (this.ownUser.first_name.substring(0,1) + this.ownUser.last_name.substring(0,1)).toUpperCase()
    },
    iconColor() {
      return userColors[this.ownUser.first_name.substring(0,1).toLowerCase()] + ' darken-1'
    },
    smallScreen() {
      return this.$vuetify.breakpoint.name==='md'
    },
    phoneScreen() {
      return this.$vuetify.breakpoint.name==='sm' || this.$vuetify.breakpoint.name==='xs'
    }
  }
}
</script>

<style scoped lang="scss">
.v-app-bar {
  .container {
    padding-top: 0;
    padding-bottom: 0;

    &.max-width {
      max-width: 100%;
    }
  }
}
</style>
