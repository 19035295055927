import { checkAuth } from '../functions'
import HelpPage from '@/views/Help/HelpPage'
import DocumentsPage from '@/views/Help/DocumentsPage'
// import OpenTicket from '@/views/Help/OpenTicket'

export default [
  {
    path: '/help',
    name: 'HelpPage',
    component: HelpPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'Help'
    }
  },
  {
    path: '/help/search',
    name: 'HelpPage.Search',
    component: HelpPage,
    props: (route) => {
      return {
        helpSearch: true
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'Help Search'
    }
  },
  {
    path: '/help/:helpCategory',
    name: 'HelpPage.Category',
    component: HelpPage,
    props: (route) => {
      return {
        helpCategory: route.params.helpCategory
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'Help Category'
    }
  },
  {
    path: '/help/:helpCategory/:helpPage',
    name: 'HelpPage.Page',
    component: HelpPage,
    props: (route) => {
      return {
        helpCategory: route.params.helpCategory,
        helpPage: route.params.helpPage
      }
    },
    beforeEnter: checkAuth,
    meta: {
      title: 'Help Page'
    }
  },
  {
    path: '/documents',
    name: 'DocumentsPage',
    component: DocumentsPage,
    beforeEnter: checkAuth,
    meta: {
      title: 'Documents'
    }
  }
  // {
  //   path: '/openticket',
  //   name: 'OpenTicket',
  //   component: OpenTicket,
  //   beforeEnter: checkAuth,
  //   meta: {
  //     title: 'Open a Ticket'
  //   }
  // }
]
